import useGetBanners from "@api/public/get/hooks/useGetBanners";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import { bannerTypeNames } from "@lib/constants/generic";
import { discernBannerType } from "@lib/utils/generic";
import React, { useState } from "react";
import DeleteBannerModal from "./banner/DeleteBannerModal";
import useBannersOrderConfigurator from "./hooks/useBannersOrderConfigurator";

export default function Banners() {
  const { send: getBanners, data: banners, isLoading, error } = useGetBanners(true);

  const [selectedBannerId, setSelectedBannerId] = useState<string>();

  const processedBanners = [...banners].map(banner => ({
    ...banner,
    type: bannerTypeNames[discernBannerType(banner)],
  }));

  const { sortedData: sortedBanners, ...tableSort } = useTableSort(processedBanners);

  const {
    isLoading: orderIsLoading,
    error: orderError,
    orderedBanners,
    ordering,
    updateOrder,
    setOrdering,
    moveUp,
    moveDown,
    moveTop,
    moveBottom,
  } = useBannersOrderConfigurator(sortedBanners);

  const bannersToUse = ordering ? orderedBanners : sortedBanners;

  return (
    <Page
      title="Banners"
      error={orderError}
      buttons={
        <>
          {ordering ? (
            <>
              <Button
                bgColor="bg-gray-100"
                bgHoverColor="hover:bg-gray-200"
                textColor="black"
                onClick={() => setOrdering(false)}>
                Cancel
              </Button>
              <Button onClick={updateOrder} isLoading={orderIsLoading}>
                Save
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setOrdering(true)}>Start Ordering</Button>
              <Button to="/banners/create">Create</Button>
            </>
          )}
        </>
      }>
      <Table
        headers={[
          { key: "title", name: "Title", sortable: !ordering },
          { key: "type", name: "Type", sortable: !ordering },
          { key: "cig.id", name: "CIG", sortable: !ordering },
          { name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        {...tableSort}>
        {bannersToUse.map(({ id, title, cig, type }, i) => (
          <TableRow key={id}>
            <TableCell>{title}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>{cig?.displayName ?? "N/A"}</TableCell>
            <TableCell>
              <ButtonRow className="justify-end">
                {ordering ? (
                  <>
                    <div className="flex flex-col gap-1">
                      <Button size="xs" onClick={() => moveUp(i)} disabled={i === 0}>
                        <ChevronUpIcon width={16} height={16} />
                      </Button>
                      <Button
                        size="xs"
                        onClick={() => moveDown(i)}
                        disabled={i === bannersToUse.length - 1}>
                        <ChevronDownIcon width={16} height={16} />
                      </Button>
                    </div>
                    <div className="flex flex-col gap-1">
                      <Button
                        bgColor="bg-violet-600"
                        bgHoverColor="hover:bg-violet-700"
                        size="xs"
                        onClick={() => moveTop(i)}
                        disabled={i === 0}>
                        <ChevronDoubleUpIcon width={16} height={16} />
                      </Button>
                      <Button
                        bgColor="bg-violet-600"
                        bgHoverColor="hover:bg-violet-700"
                        size="xs"
                        onClick={() => moveBottom(i)}
                        disabled={i === bannersToUse.length - 1}>
                        <ChevronDoubleDownIcon width={16} height={16} />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      bgColor="bg-violet-600"
                      bgHoverColor="hover:bg-violet-700"
                      to={`/banners/${id}`}>
                      View
                    </Button>
                    <Button
                      bgColor="bg-red-600"
                      bgHoverColor="hover:bg-red-700"
                      onClick={() => setSelectedBannerId(id)}>
                      Delete
                    </Button>
                  </>
                )}
              </ButtonRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <DeleteBannerModal
        bannerId={selectedBannerId}
        onDelete={getBanners}
        onClose={() => setSelectedBannerId(undefined)}
      />
    </Page>
  );
}
