import { getMsgFromApiErr } from "@api/api";
import { Game } from "@api/public/get/getGames";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updateGame, { UpdateGame } from "../updateGame";

const useUpdateGame = (game?: UpdateGame) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Game | null>(null);

  const send = useCallback(
    async (gameOverride?: UpdateGame) => {
      const _game = gameOverride ?? game;

      if (_game == null) return reqState;
      setLoading();
      try {
        const newGame = await updateGame(_game);
        return setSuccess(newGame);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [game, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateGame;
