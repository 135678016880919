import { GameCategory } from "@lib/enums/generic";
import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const createGameValidators = {
  category: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").enum.within(GameCategory);
  },
  description: (validation: FormValidation, value: any) => {
    new Validator(validation, value)
      .exists()
      .is("string")
      .length.greaterThan(10)
      .length.lessThanOrEqualTo(500);
  },
  gameId: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  primaryImageURL: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  secondaryImageURL: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  tertiaryImageURL: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  shortName: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(100);
  },
  longName: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(250);
  },
  platformCompatibility: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("array").length.greaterThan(0);
  },
  statisticName: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  statisticValue: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  estimatedMaxLengthMins: (validation: FormValidation, value: any) => {
    new Validator(validation, value).skipIf(value === 0).is("number");
  },
};

export default createGameValidators;
