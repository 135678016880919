import { getMsgFromApiErr } from "@api/api";
import getCigProfile, { CIGProfile } from "@api/public/get/getCigProfile";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";

const useGetCigProfile = (cigID?: string, userID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CIGProfile | null>(null);

  const send = useCallback(
    async (cigIDOverride?: string) => {
      const _cigID = cigIDOverride ?? cigID;

      if (_cigID == null) return reqState;
      setLoading();
      try {
        const cigProfile = await getCigProfile(_cigID, userID);
        return setSuccess(cigProfile);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [cigID, userID, reqState, setError, setLoading, setSuccess],
  );

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [cigID, userID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetCigProfile;
