import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {
  borderColor?: string;
  bgColor?: string;
  textcolor?: string;
}

export default function Divider(props: PropsWithChildren<Props>) {
  const {
    borderColor = "border-gray-300",
    bgColor = "bg-white",
    textcolor = "text-gray-500",
    className = "",
    id,
    style,
    children,
  } = props;

  return (
    <div className={classNames("relative", className)} style={style} id={id}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className={classNames("w-full border-t", borderColor)} />
      </div>
      {children && (
        <div className="relative flex justify-center">
          <span className={classNames("px-2 text-sm", bgColor, textcolor)}>Continue</span>
        </div>
      )}
    </div>
  );
}
