import useSetCigFeatured from "@api/private/update/hooks/useSetCigFeatured";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import Modal from "@components/common/modals/Modal";
import { XIcon } from "@heroicons/react/outline";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React from "react";

interface Props {
  cigId?: string;
  onClose?: () => void;
  onDemote?: () => void;
}

export default function DemoteFeaturedModal(props: Props) {
  const { cigId, onClose = () => {}, onDemote = () => {} } = props;

  const { userExtension, pushSnackbar } = useGlobalContext();

  const {
    data: cigProfile,
    isLoading: cigProfileIsLoading,
    error: cigProfileError,
  } = useGetCigProfile(cigId, userExtension?.id, true);

  const { displayName } = cigProfile ?? {};

  const {
    send: setCigFeatured,
    isLoading: demoteIsLoading,
    error: demoteError,
  } = useSetCigFeatured(cigId, false);

  const handleDemote = async () => {
    const { error } = await setCigFeatured();
    if (error) return;
    onDemote();
    onClose();
    pushSnackbar(
      createSuccessSnackbar(`${cigProfile?.displayName} Demoted from Featured`, undefined, 3000),
    );
  };

  return (
    <Modal
      active={!!cigId}
      isLoading={demoteIsLoading}
      error={demoteError}
      title={`Demote ${displayName} from Featured?`}
      subTitle="This will cause their profile to cease displaying within the featured section on home page."
      confirmButtonText="Demote"
      confirmButtonProps={{
        bgColor: "bg-red-600",
        bgHoverColor: "hover:bg-red-700",
      }}
      icon={<XIcon />}
      iconBgColor="bg-red-100"
      iconColor="text-red-600"
      dataCheckProps={{
        isLoading: cigProfileIsLoading,
        error: cigProfileError,
      }}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleDemote}
    />
  );
}
