import { api } from "@api/api";

export interface CreatePlatformRes {
  id: string;
}

const createPlatform = async (name: string, shortName: string, icon: string) => {
  const { data } = await api.post<CreatePlatformRes>("/createPlatform", {
    name,
    shortName,
    icon,
  });
  return data;
};

export default createPlatform;
