import { CSSProps } from "@lib/types/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {}

export default function FormRow(props: PropsWithChildren<Props>) {
  const { children, className = "", style, id } = props;

  return (
    <div className={`flex flex-row gap-4 ${className}`} id={id} style={style}>
      {children}
    </div>
  );
}
