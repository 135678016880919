import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";
import GPT3Tokenizer from "gpt3-tokenizer";

const tokenizer = new GPT3Tokenizer({ type: "gpt3" });

const editSeoStreamerValidators = {
  bulkText: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").custom(value => {
      const tokens = tokenizer.encode(value).text.length;

      const tokenLimit = 2950;
      if (tokens > tokenLimit) return `Token limit exceeded (${tokens}/${tokenLimit})`;
    });
  },
};

export default editSeoStreamerValidators;
