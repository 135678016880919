import { Auth } from "aws-amplify";

const login = async (email: string, password: string, staySignedIn: boolean = false) => {
  Auth.configure({ storage: staySignedIn ? localStorage : sessionStorage });
  const { user } = await Auth.signIn({
    username: email,
    password: password,
  });

  return user;
};

export default login;
