import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useUpdateGame from "@api/private/update/hooks/useUpdateGame";
import useUpdateGameCompatibility from "@api/private/update/hooks/useUpdateGameCompatibility";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { EditGameValues } from "../Game";

const useEditGameHandle: UseFormHandle<EditGameValues> = (
  { validateFields, validateCustom, setValues, values },
  primaryImageFile: File,
  secondaryImageFile: File,
  tertiaryImageFile: File,
) => {
  const { send: updateGame, isLoading: updateIsLoading } = useUpdateGame();
  const { send: updateGameCompatibility, isLoading: compatibilityIsLoading } =
    useUpdateGameCompatibility(values.platformCompatibility, values.id);
  const { send: uploadToS3, isLoading: uploadToS3IsLoading } = useUploadToS3();

  const handle = async () => {
    let failed = validateFields([
      "longName",
      "category",
      "description",
      "id",
      "primaryImageURL",
      "secondaryImageURL",
      "tertiaryImageURL",
      "shortName",
      "platformCompatibility",
      "statisticName",
      "statisticValue",
      "estimatedMaxLengthMins",
    ]);

    const newValues = { ...values };

    if (!failed) {
      const { data: primaryUrl, error: primaryError } = await uploadToS3(primaryImageFile);
      const { data: secondaryUrl, error: secondaryError } = await uploadToS3(secondaryImageFile);
      const { data: tertiaryUrl, error: tertiaryError } = await uploadToS3(tertiaryImageFile);

      const error = primaryError ?? secondaryError ?? tertiaryError;

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (primaryUrl) newValues.primaryImageURL = primaryUrl;
      if (secondaryUrl) newValues.secondaryImageURL = secondaryUrl;
      if (tertiaryUrl) newValues.tertiaryImageURL = tertiaryUrl;

      setValues(newValues);
    }

    if (!failed) {
      const { error } = await updateGame({
        ...newValues,
        // ensures estimatedMaxLengthMins becomes null if it's set to 0
        estimatedMaxLengthMins: newValues.estimatedMaxLengthMins || null,
        supportedPlatforms: values.platformCompatibility.map(
          ({ hostPlatformID }) => hostPlatformID,
        ),
      });

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (!error) {
        const { error } = await updateGameCompatibility();

        if (error) {
          failed = validateCustom("platformCompatibility", validation => {
            validation.list.push({
              type: "error",
              text: error,
            });
          });
        }
      }
    }

    return failed;
  };

  return [handle, updateIsLoading || uploadToS3IsLoading || compatibilityIsLoading];
};

export default useEditGameHandle;
