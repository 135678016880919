import { api } from "@api/api";
import { UpdateClaimState } from "@lib/enums/generic";

export interface UpdateClaimRes {}

const updateClaimState = async (claimID: string, state: UpdateClaimState) => {
  const { data } = await api.post<UpdateClaimRes>("/adminMutatesClaimState", {
    claimID,
    state,
  });
  return data;
};

export default updateClaimState;
