import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Location } from "history";

const getQuery = (paramKey: string, location: Location) => {
  const params = new URLSearchParams(location.search).entries();
  for (const [key, param] of params) {
    if (key === paramKey) return decodeURIComponent(param);
  }
  return null;
};

const useQuery = <Q = string>(paramKey: string) => {
  const location = useLocation();
  const [param, setParam] = useState<string | null>(getQuery(paramKey, location));

  useEffect(() => {
    setParam(getQuery(paramKey, location));
  }, [location, paramKey]);

  return param as Q | null;
};

export default useQuery;
