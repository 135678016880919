import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getSanityStreamers, { GetSanityStreamersRes } from "../getSanityStreamers";

const useGetSanityStreamers = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<GetSanityStreamersRes>([]);

  const send = useCallback(async () => {
    setLoading();
    const { data, error } = await getSanityStreamers();

    if (data) setSuccess(data);
    if (error) setError(error);
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetSanityStreamers;
