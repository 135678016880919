import useAcceptCig from "@api/private/update/hooks/useAcceptCig";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import Modal from "@components/common/modals/Modal";
import { XIcon } from "@heroicons/react/outline";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React from "react";

interface Props {
  cigId?: string;
  onClose?: () => void;
  onReject?: () => void;
}

export default function RejectCIGModal(props: Props) {
  const { cigId, onClose = () => {}, onReject = () => {} } = props;

  const { userExtension, pushSnackbar } = useGlobalContext();

  const {
    data: cigProfile,
    isLoading: cigProfileIsLoading,
    error: cigProfileError,
  } = useGetCigProfile(cigId, userExtension?.id, true);

  const { displayName } = cigProfile ?? {};

  const {
    send: rejectCig,
    isLoading: rejectIsLoading,
    error: rejectError,
  } = useAcceptCig(cigId, false);

  const handleReject = async () => {
    const { error } = await rejectCig();
    if (error) return;
    onReject();
    onClose();
    pushSnackbar(createSuccessSnackbar(`${cigProfile?.displayName} Rejected`, undefined, 3000));
  };

  return (
    <Modal
      active={!!cigId}
      isLoading={rejectIsLoading}
      error={rejectError}
      title={`Reject ${displayName}?`}
      subTitle="This will reject them from the platform and prevent them from creating sessions."
      confirmButtonText="Reject"
      confirmButtonProps={{
        bgColor: "bg-red-600",
        bgHoverColor: "hover:bg-red-700",
      }}
      icon={<XIcon />}
      iconBgColor="bg-red-100"
      iconColor="text-red-600"
      dataCheckProps={{
        isLoading: cigProfileIsLoading,
        error: cigProfileError,
      }}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleReject}
    />
  );
}
