import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const createPlatformValidators = {
  name: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThan(50);
  },
  shortName: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThan(20);
  },
  icon: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
};

export default createPlatformValidators;
