import useSetCigFeatured from "@api/private/update/hooks/useSetCigFeatured";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import Modal from "@components/common/modals/Modal";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React from "react";

interface Props {
  cigId?: string;
  onClose?: () => void;
  onPromote?: () => void;
}

export default function PromoteFeaturedModal(props: Props) {
  const { cigId, onClose = () => {}, onPromote = () => {} } = props;

  const { userExtension, pushSnackbar } = useGlobalContext();

  const {
    data: cigProfile,
    isLoading: cigProfileIsLoading,
    error: cigProfileError,
  } = useGetCigProfile(cigId, userExtension?.id, true);

  const { displayName } = cigProfile ?? {};

  const {
    send: setCigFeatured,
    isLoading: promoteIsLoading,
    error: promoteError,
  } = useSetCigFeatured(cigId, true);

  const handlePromote = async () => {
    const { error } = await setCigFeatured();
    if (error) return;
    onPromote();
    onClose();
    pushSnackbar(
      createSuccessSnackbar(`${cigProfile?.displayName} Promoted to Featured`, undefined, 3000),
    );
  };

  return (
    <Modal
      active={!!cigId}
      isLoading={promoteIsLoading}
      error={promoteError}
      title={`Promote ${displayName} to Featured?`}
      subTitle="This will cause their profile to display within the featured section on home page."
      confirmButtonText="Promote"
      confirmButtonProps={{
        bgColor: "bg-green-600",
        bgHoverColor: "hover:bg-green-700",
      }}
      dataCheckProps={{
        isLoading: cigProfileIsLoading,
        error: cigProfileError,
      }}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handlePromote}
    />
  );
}
