import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createGame, { CreateGameRes } from "../createGame";

const useCreateGame = (
  shortName?: string,
  longName?: string,
  description?: string,
  category?: string,
  supportedPlatforms?: string[],
  statisticName?: string,
  statisticValue?: string,
  estimatedMaxLengthMins?: number,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CreateGameRes | null>(
    null,
  );

  const send = useCallback(
    async (primaryImageURL?: string, secondaryImageURL?: string, tertiaryImageURL?: string) => {
      if (
        shortName == null ||
        longName == null ||
        primaryImageURL == null ||
        secondaryImageURL == null ||
        tertiaryImageURL == null ||
        description == null ||
        category == null ||
        supportedPlatforms == null ||
        statisticName == null ||
        statisticValue == null ||
        estimatedMaxLengthMins == null
      )
        return reqState;
      setLoading();
      try {
        const createRes = await createGame(
          shortName,
          longName,
          primaryImageURL,
          secondaryImageURL,
          tertiaryImageURL,
          description,
          category,
          supportedPlatforms,
          statisticName,
          statisticValue,
          estimatedMaxLengthMins,
        );
        return setSuccess(createRes);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [
      shortName,
      longName,
      description,
      category,
      supportedPlatforms,
      statisticName,
      statisticValue,
      estimatedMaxLengthMins,
      reqState,
      setError,
      setLoading,
      setSuccess,
    ],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useCreateGame;
