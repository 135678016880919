import Card from "@components/common/containers/Card";
import React from "react";
import DashboardHeading from "./DashboardHeading";
import { Cell, Pie, PieChart } from "recharts";
import DataCheck from "@components/common/DataCheck";
import useGlobalContext from "@hooks/useGlobalContext";
import { classNames } from "@lib/utils/generic";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`} ({value})
    </text>
  );
};

export interface CIGPieChartData {
  name: string;
  value: number;
  color: string;
}

interface Props {
  data: CIGPieChartData[];
  isLoading?: boolean;
  error?: string | null;
}

export default function DashboardData(props: Props) {
  const { data, isLoading, error } = props;

  const { breakpoints } = useGlobalContext();

  let size = breakpoints.sm ? 250 : breakpoints.md ? 300 : breakpoints.lg ? 190 : 300;

  return (
    <div className="basis-1/2">
      <DashboardHeading>CIG Progress</DashboardHeading>
      <Card
        className="flex flex-col justify-between items-stretch sm:flex-row"
        noOverflow
        noPadding>
        <div className="relative flex-grow flex items-center justify-center min-h-[128px]">
          <DataCheck isLoading={isLoading} error={error} loadingIndicator="spinner">
            <PieChart width={size} height={size} className="mx-4 my-5 sm:m-6">
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={size / 2}
                fill="#8884d8"
                dataKey="value">
                {data.map(({ color }, index) => (
                  <Cell key={`cell-${index}`} fill={color} />
                ))}
              </Pie>
            </PieChart>
          </DataCheck>
        </div>
        <div
          className={classNames(
            "bg-gray-50 flex items-center justify-center px-4 py-5 sm:p-6",
            isLoading ? "ml-auto" : "",
          )}>
          <div className="flex flex-col gap-1 sm:gap-2">
            {data.map(({ name, color }, i) => (
              <div key={i} className="flex items-center gap-2">
                <div className="w-3 h-3 rounded-full" style={{ backgroundColor: color }} />
                <p className="font-medium text-sm">{name}</p>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}
