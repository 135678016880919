import { BannerType } from "@api/public/get/getBanners";
import { isDev } from "@config/config";
import {
  CIG,
  ClaimReason,
  ClaimState,
  CountryCode,
  GameCategory,
  Platform,
  SnackbarType,
  SortDirection,
} from "@lib/enums/generic";
import { Snackbar } from "@lib/types/generic";
import { ReactElement, ReactNode } from "react";

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function enumContains<T>(enumType: any, value: T): value is T {
  return Object.values(enumType).includes(value);
}

type NewType = ReactElement;

export const createSnackbar = (
  type: SnackbarType,
  title: ReactNode,
  content: ReactNode,
  timeout?: number,
  icon?: NewType,
): Snackbar => ({
  type,
  title,
  content,
  timeout,
  icon,
});

export const createErrorSnackbar = (
  title: ReactNode,
  content: ReactNode,
  timeout?: number,
  icon?: ReactElement,
) => createSnackbar(SnackbarType.Error, title, content, timeout, icon);

export const createSuccessSnackbar = (
  title: ReactNode,
  content: ReactNode,
  timeout?: number,
  icon?: ReactElement,
) => createSnackbar(SnackbarType.Success, title, content, timeout, icon);

export const createInfoSnackbar = (
  title: ReactNode,
  content: ReactNode,
  timeout?: number,
  icon?: ReactElement,
) => createSnackbar(SnackbarType.Info, title, content, timeout, icon);

export const createWarnSnackbar = (
  title: ReactNode,
  content: ReactNode,
  timeout?: number,
  icon?: ReactElement,
) => createSnackbar(SnackbarType.Warn, title, content, timeout, icon);

export const sortDirectionInvert = {
  [SortDirection.Ascending]: SortDirection.Descending,
  [SortDirection.Descending]: SortDirection.Ascending,
};

export const cigNames: Record<CIG, string> = {
  [CIG.Gamer]: "Gamer",
  [CIG.Celebrity]: "Celebrity",
  [CIG.Influencer]: "Influencer",
};

export const claimStateNames = {
  [ClaimState.Resolved]: "Resolved",
  [ClaimState.Pending]: "Pending",
  [ClaimState.Rejected]: "Rejected",
  [ClaimState.Created]: "Created",
};

export const claimStateColors = {
  [ClaimState.Resolved]: "bg-green-100",
  [ClaimState.Pending]: "bg-gray-100",
  [ClaimState.Rejected]: "bg-red-100",
  [ClaimState.Created]: "bg-purple-100",
};

export const claimReasonNames = {
  [ClaimReason.TechnicalDifficulties]: "Technical difficulties",
  [ClaimReason.CigNoShow]: "They didn't turn up",
  [ClaimReason.Other]: "Other",
};

export const countryNames = {
  [CountryCode.Australia]: "Australia",
  [CountryCode.Austria]: "Austria",
  [CountryCode.Belgium]: "Belgium",
  [CountryCode.Bulgaria]: "Bulgaria",
  [CountryCode.Canada]: "Canada",
  [CountryCode.Croatia]: "Croatia",
  [CountryCode.Cyprus]: "Cyprus",
  [CountryCode.CzechRepublic]: "Czech Republic",
  [CountryCode.Denmark]: "Denmark",
  [CountryCode.Estonia]: "Estonia",
  [CountryCode.Finland]: "Finland",
  [CountryCode.France]: "France",
  [CountryCode.Germany]: "Germany",
  [CountryCode.Gibraltar]: "Gibraltar",
  [CountryCode.Greece]: "Greece",
  [CountryCode.HongKongSARChina]: "Hong Kong SAR China",
  [CountryCode.Hungary]: "Hungary",
  [CountryCode.Ireland]: "Ireland",
  [CountryCode.Italy]: "Italy",
  [CountryCode.Japan]: "Japan",
  [CountryCode.Latvia]: "Latvia",
  [CountryCode.Liechtenstein]: "Liechtenstein",
  [CountryCode.Lithuania]: "Lithuania",
  [CountryCode.Luxembourg]: "Luxembourg",
  [CountryCode.Malta]: "Malta",
  [CountryCode.Mexico]: "Mexico ",
  [CountryCode.Netherlands]: "Netherlands",
  [CountryCode.NewZealand]: "New Zealand",
  [CountryCode.Norway]: "Norway",
  [CountryCode.Poland]: "Poland",
  [CountryCode.Portugal]: "Portugal",
  [CountryCode.Romania]: "Romania",
  [CountryCode.Singapore]: "Singapore",
  [CountryCode.Slovakia]: "Slovakia",
  [CountryCode.Slovenia]: "Slovenia",
  [CountryCode.Spain]: "Spain",
  [CountryCode.Sweden]: "Sweden",
  [CountryCode.Switzerland]: "Switzerland",
  [CountryCode.UnitedArabEmirates]: "United Arab Emirates",
  [CountryCode.UnitedKingdom]: "United Kingdom",
  [CountryCode.UnitedStates]: "United States",
};

export const platformNames = {
  [Platform.Facebook]: "Facebook",
  [Platform.Instagram]: "Instagram",
  [Platform.TikTok]: "TikTok",
  [Platform.Twitch]: "Twitch",
  [Platform.Twitter]: "Twitter",
  [Platform.YouTube]: "YouTube",
};

export const snackbarColors = {
  [SnackbarType.Error]: "text-red-400",
  [SnackbarType.Info]: "text-violet-500",
  [SnackbarType.Success]: "text-green-400",
  [SnackbarType.Warn]: "text-orange-400",
};

export const bannerTypeNames = {
  [BannerType.CIGLink]: "CIG Link",
  [BannerType.Standard]: "Standard",
};

export const gameCategoryNames = {
  [GameCategory.ACTION]: "ACTION",
  [GameCategory.ADVENTURE]: "ADVENTURE",
  [GameCategory.ARCADE]: "ARCADE",
  [GameCategory.BOARD]: "BOARD",
  [GameCategory.CARD]: "CARD",
  [GameCategory.CASUAL]: "CASUAL",
  [GameCategory.EDUCATIONAL]: "EDUCATIONAL",
  [GameCategory.FAMILY]: "FAMILY",
  [GameCategory.FPS]: "FPS",
  [GameCategory.FIGHTING]: "FIGHTING",
  [GameCategory.MUSIC]: "MUSIC",
  [GameCategory.PUZZLE]: "PUZZLE",
  [GameCategory.RACING]: "RACING",
  [GameCategory.REAL_TIME_SIMULATION]: "REAL_TIME_SIMULATION",
  [GameCategory.ROLE_PLAYING]: "ROLE_PLAYING",
  [GameCategory.SHOOTER]: "SHOOTER",
  [GameCategory.SIMULATION]: "SIMULATION",
  [GameCategory.SPORTS]: "SPORTS",
  [GameCategory.STRATEGY]: "STRATEGY",
  [GameCategory.TURN_BASED]: "TURN_BASED",
  [GameCategory.WALKING]: "WALKING",
};

export const mainSiteUrl = isDev ? "https://staging.yakkr.co.uk/" : "https://yakkr.co.uk/";

export const talentSiteUrl = "https://talent.yakkr.co.uk/";
