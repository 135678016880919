import useGetCigs from "@api/public/get/hooks/useGetCigs";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import React, { useState } from "react";
import RejectCIGModal from "./game/RejectCIGModal";

export default function CIGs() {
  const [selectedRejectCigId, setSelectedRejectCigId] = useState<string>();

  const { send: getCigs, data: cigs, isLoading, error } = useGetCigs(true);

  const { sortedData: sortedCigs, ...tableSort } = useTableSort(cigs);

  return (
    <Page
      title="CIGs"
      buttons={
        <Button bgColor="bg-violet-600" bgHoverColor="hover:bg-violet-700" to="/cigs/pending">
          View Pending
        </Button>
      }>
      <Table
        headers={[
          { key: "displayName", name: "Display Name", sortable: true },
          { key: "category", name: "Type", sortable: true },
          { name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        {...tableSort}>
        {sortedCigs.map(({ id, displayName, category }) => (
          <TableRow key={id}>
            <TableCell>{displayName}</TableCell>
            <TableCell>{category}</TableCell>
            <TableCell>
              <ButtonRow className="justify-end">
                <Button
                  bgColor="bg-red-600"
                  bgHoverColor="hover:bg-red-700"
                  onClick={() => setSelectedRejectCigId(id)}>
                  Reject
                </Button>
                <Button
                  bgColor="bg-violet-600"
                  bgHoverColor="hover:bg-violet-700"
                  to={`/cigs/${id}`}>
                  View
                </Button>
              </ButtonRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      <RejectCIGModal
        cigId={selectedRejectCigId}
        onClose={() => setSelectedRejectCigId(undefined)}
        onReject={getCigs}
      />
    </Page>
  );
}
