import { UserExtension } from "@api/private/get/getUserExtension";
import useAuth from "@hooks/useAuth";
import { Snackbar } from "@lib/types/generic";
import { log } from "@lib/utils/generic";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import useSnackbarManager from "@hooks/useSnackbarManager";

export const defaultBreakpoints = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  sl: false,
  ul: false,
};

export type Breakpoints = typeof defaultBreakpoints;

interface GlobalContextType {
  maintenanceMode: boolean;
  setMaintenanceMode: Dispatch<SetStateAction<boolean>>;

  loading: boolean;
  setLoading: (loading: boolean) => void;
  breakpoints: Breakpoints;
  setBreakpoints: (breakpoints: Breakpoints) => void;
  snackbars: Snackbar[];
  setSnackbars: (snackbars: Snackbar[]) => void;
  pushSnackbar: (snackbar: Snackbar) => void;
  spliceSnackbar: (snackbar: Snackbar) => void;
  imagesLoading: string[];
  setImagesLoading: (imagesLoading: string[]) => void;
  pushImageLoading: (image: string) => void;
  spliceImageLoading: (image: string) => void;

  user: any;
  setUser: (user: any) => void;
  getUser: (disableLoading?: boolean) => void;
  userExtension: UserExtension | null;
  setUserExtension: (userExtension: UserExtension) => void;
  initialLoading: boolean;
  initialError: string | null;

  loggingIn: boolean;
  setLoggingIn: (loggingIn: boolean) => void;

  signIn: (provider: { provider: CognitoHostedUIIdentityProvider }) => void;
  signOut: () => void;
}

export const GlobalContext = createContext<GlobalContextType>({} as GlobalContextType);

export default function GlobalContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [breakpoints, setBreakpoints] = useState(defaultBreakpoints);
  const [imagesLoading, setImagesLoading] = useState<string[]>([]);
  const [loggingIn, setLoggingIn] = useState(false);

  const pushImageLoading = (image: string) => {
    setImagesLoading(images => [...images, image]);
  };

  const spliceImageLoading = (image: string) => {
    setImagesLoading(images => {
      const index = images.indexOf(image);
      if (index < 0) return images;
      const imagesCopy = [...images];
      imagesCopy.splice(index, 1);
      return imagesCopy;
    });
  };

  const onResize = () => {
    const viewWidth = document.body.clientWidth;
    const breakpoints = {
      xs: viewWidth <= 400,
      sm: viewWidth <= 600,
      md: viewWidth <= 960,
      lg: viewWidth <= 1280,
      xl: viewWidth <= 1780,
      sl: viewWidth <= 2560,
      ul: viewWidth <= 3840,
    };
    log(breakpoints);
    setBreakpoints(breakpoints);
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return window.addEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const snackbarManager = useSnackbarManager();
  const auth = useAuth(snackbarManager.pushSnackbar);

  return (
    <GlobalContext.Provider
      value={{
        maintenanceMode,
        setMaintenanceMode,

        loading,
        setLoading,
        breakpoints,
        setBreakpoints,

        loggingIn,
        setLoggingIn,

        imagesLoading,
        setImagesLoading,
        pushImageLoading,
        spliceImageLoading,
        ...auth,
        ...snackbarManager,
      }}>
      {children}
    </GlobalContext.Provider>
  );
}
