import { getMsgFromApiErr } from "@api/api";
import { BannerDetails } from "@api/public/get/getBanners";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import deleteBanner from "../deleteBanner";

const useDeleteBanner = (bannerId?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<BannerDetails | null>(
    null,
  );

  const send = useCallback(async () => {
    if (bannerId == null) return reqState;
    setLoading();
    try {
      const deleteRes = await deleteBanner(bannerId);
      return setSuccess(deleteRes);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [bannerId, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useDeleteBanner;
