import { api } from "@api/api";

export interface FullCompatibility {
  id: string;
  game: GamePartial;
  hostPlatform: PlatformPartial;
  compatiblePlatforms: CompatiblePlatforms;
}

export interface CompatiblePlatforms {
  items: {
    id: string;
    platform: PlatformPartial;
  }[];
}

export interface PlatformPartial {
  name: string;
  id: string;
  shortName: string;
  icon: string;
}

export interface GamePartial {
  category: string;
  description: string;
  id: string;
  longName: string;
  primaryImageURL: string;
  secondaryImageURL: string;
  shortName: string;
}

const getFullCompatibility = async (gameID: string) => {
  const { data } = await api.get<FullCompatibility[]>("/fullGameCompatibility", {
    params: {
      gameID,
    },
  });
  return data;
};

export default getFullCompatibility;
