import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {
  noMargin?: boolean;
}

export default function FormText(props: PropsWithChildren<Props>) {
  const { noMargin, children, className = "", style, id } = props;

  return (
    <p className={classNames("text-md", noMargin ? "" : "mt-1", className)} id={id} style={style}>
      {children}
    </p>
  );
}
