import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {}

export default function FormTitle(props: PropsWithChildren<Props>) {
  const { children, className = "", style, id } = props;
  return (
    <p className={classNames("mb-6 font-medium text-xl", className)} style={style} id={id}>
      {children}
    </p>
  );
}
