import { Platform } from "@api/public/get/getPlatforms";
import useGetPlatforms from "@api/public/get/hooks/useGetPlatforms";
import { OnFormChange } from "@components/common/form/hooks/useFormManager";
import { addToArray, removeFromArray, sortMap } from "@lib/utils/generic";
import { useCallback } from "react";
import distinctColors from "distinct-colors";
import { CompatibilityConfig } from "@api/private/update/updateGameCompatibility";
import { SortDirection } from "@lib/enums/generic";

const usePlatformMatrix = (
  name: string,
  platformCompatibility: CompatibilityConfig[],
  onChange: OnFormChange,
) => {
  const { data: platforms, error, isLoading } = useGetPlatforms(true);

  const colors = distinctColors({
    count: platforms.length,
    chromaMin: 25,
  }).map(chroma => chroma.css());

  const platformOptions = platforms
    .sort(sortMap("name", SortDirection.Ascending))
    .map(({ id, name }, i) => ({
      value: id,
      name: <span style={{ color: colors[i] }}>{name}</span>,
    }));

  const platformsAsObject = platforms.reduce<Record<string, Platform>>(
    (acc, platform) => ({ ...acc, [platform.id]: platform }),
    {},
  );

  const addedPlatformOptions = platformOptions.filter(({ value }) =>
    platformCompatibility.find(({ hostPlatformID }) => value === hostPlatformID),
  );

  const unaddedPlatformOptions = platformOptions.filter(
    ({ value }) => !platformCompatibility.find(({ hostPlatformID }) => value === hostPlatformID),
  );

  const addPlatform = useCallback(
    (hostPlatformID: string) => {
      const newPlatformCompatibility = [...platformCompatibility];
      newPlatformCompatibility.push({ hostPlatformID, compatiblePlatforms: [hostPlatformID] });

      onChange({ name, value: newPlatformCompatibility });
    },
    [name, platformCompatibility, onChange],
  );

  const removePlatform = useCallback(
    (hostPlatformID: string) => {
      let newPlatformCompatibility = [...platformCompatibility];
      newPlatformCompatibility = newPlatformCompatibility
        .filter(compat => compat.hostPlatformID !== hostPlatformID)
        .map(compat => ({
          hostPlatformID: compat.hostPlatformID,
          compatiblePlatforms: compat.compatiblePlatforms.filter(
            currId => currId !== hostPlatformID,
          ),
        }));

      onChange({ name, value: newPlatformCompatibility });
    },
    [name, platformCompatibility, onChange],
  );

  const addSubPlatform = useCallback(
    (hostPlatformID: string, otherHostPlatformID: string) => {
      const newPlatformCompatibility = [...platformCompatibility];

      const index = newPlatformCompatibility.findIndex(
        compat => compat.hostPlatformID === hostPlatformID,
      );

      if (index >= 0) {
        const platforms = newPlatformCompatibility[index].compatiblePlatforms;
        addToArray(platforms, otherHostPlatformID);
      }

      const otherIndex = newPlatformCompatibility.findIndex(
        compat => compat.hostPlatformID === otherHostPlatformID,
      );

      if (otherIndex >= 0) {
        const otherPlatforms = newPlatformCompatibility[otherIndex].compatiblePlatforms;
        addToArray(otherPlatforms, hostPlatformID);
      }

      onChange({ name, value: platformCompatibility });
    },
    [name, platformCompatibility, onChange],
  );

  const removeSubPlatform = useCallback(
    (hostPlatformID: string, otherGostPlatformID: string) => {
      const newPlatformCompatibility = [...platformCompatibility];

      const index = newPlatformCompatibility.findIndex(
        compat => compat.hostPlatformID === hostPlatformID,
      );

      if (index >= 0) {
        const platforms = newPlatformCompatibility[index].compatiblePlatforms;
        removeFromArray(platforms, otherGostPlatformID);
      }

      const otherIndex = newPlatformCompatibility.findIndex(
        compat => compat.hostPlatformID === otherGostPlatformID,
      );

      if (otherIndex >= 0) {
        const otherPlatforms = newPlatformCompatibility[otherIndex].compatiblePlatforms;
        removeFromArray(otherPlatforms, hostPlatformID);
      }

      onChange({ name, value: platformCompatibility });
    },
    [name, platformCompatibility, onChange],
  );

  const addAllPlatforms = useCallback(() => {
    onChange({
      name,
      value: platforms.map(({ id }) => {
        const currPlatform = platformCompatibility.find(compat => compat.hostPlatformID === id);
        if (currPlatform) return currPlatform;
        return { hostPlatformID: id, compatiblePlatforms: [id] };
      }),
    });
  }, [name, platformCompatibility, platforms, onChange]);

  const removeAllPlatforms = useCallback(() => {
    onChange({ name, value: [] });
  }, [name, onChange]);

  const addAllSubPlatforms = useCallback(
    (hostPlatformID: string) => {
      for (const platform of addedPlatformOptions) {
        if (platform.value === hostPlatformID) continue;
        addSubPlatform(hostPlatformID, platform.value);
      }
    },
    [addedPlatformOptions, addSubPlatform],
  );

  const removeAllSubPlatforms = useCallback(
    (hostPlatformID: string) => {
      for (const platform of addedPlatformOptions) {
        if (platform.value === hostPlatformID) continue;
        removeSubPlatform(hostPlatformID, platform.value);
      }
    },
    [addedPlatformOptions, removeSubPlatform],
  );

  return {
    isLoading,
    error,
    platformsAsObject,
    platformOptions,
    addedPlatformOptions,
    unaddedPlatformOptions,
    colors,
    addPlatform,
    removePlatform,
    addSubPlatform,
    removeSubPlatform,
    addAllPlatforms,
    removeAllPlatforms,
    addAllSubPlatforms,
    removeAllSubPlatforms,
  };
};

export default usePlatformMatrix;
