import Shell from "@components/layout/Shell";
import Games from "@pages/games/Games";
import Dashboard from "@pages/dashboard/Dashboard";
import { Route, Routes } from "react-router-dom";
import Game from "@pages/games/game/Game";
import Login from "@pages/login/Login";
import Logout from "@pages/logout/Logout";
import CreateGame from "@pages/games/game/CreateGame";
import CIGs from "@pages/cigs/CIGs";
import CIG from "@pages/cigs/game/CIG";
import PendingCIGs from "@pages/cigs/PendingCIGs";
import Claims from "@pages/claims/Claims";
import Claim from "@pages/claims/claim/Claim";
import Platforms from "@pages/platforms/Platforms";
import CreatePlatform from "@pages/platforms/platform/CreatePlatform";
import Platform from "@pages/platforms/platform/Platform";
import Snackbars from "@pages/logout/Snackbars";
import Banners from "@pages/banners/Banners";
import CreateBanner from "@pages/banners/banner/CreateBanner";
import Banner from "@pages/banners/banner/Banner";
import useGlobalContext from "@hooks/useGlobalContext";
import YakkrBanner from "@components/common/Banner";
import Homepage from "@pages/homepage/Homepage";
import SeoStreamers from "@pages/seo/streamers/SeoStreamers";
import SeoStreamer from "@pages/seo/streamers/streamer/SeoStreamer";

export default function RootRoutes() {
  const { maintenanceMode, breakpoints } = useGlobalContext();

  return (
    <>
      {maintenanceMode && (
        <div className="absolute flex flex-col items-center justify-center text-center inset-0 h-full bg-white z-50">
          <YakkrBanner svgProps={{ className: "fill-black" }} />

          <div className="p-8">
            <h2 className="flex items-center justify-center gap-12 text-3xl">Maintenance Mode</h2>
            <br />
            <p>
              We're currently making some changes to the site. {!breakpoints.sm && <br />}We should
              be back shortly.
            </p>
          </div>
        </div>
      )}

      <div id="portal-root" />

      <Snackbars horizontal="right" vertical="top" />

      <Shell>
        <Routes>
          <Route path="/" element={<Dashboard />} />

          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          <Route path="/games" element={<Games />} />
          <Route path="/games/create" element={<CreateGame />} />
          <Route path="/games/:gameId" element={<Game />} />

          <Route path="/platforms" element={<Platforms />} />
          <Route path="/platforms/create" element={<CreatePlatform />} />
          <Route path="/platforms/:platformId" element={<Platform />} />

          <Route path="/banners" element={<Banners />} />
          <Route path="/banners/create" element={<CreateBanner />} />
          <Route path="/banners/:bannerId" element={<Banner />} />

          <Route path="/homepage" element={<Homepage />} />

          <Route path="/cigs" element={<CIGs />} />
          <Route path="/cigs/pending" element={<PendingCIGs />} />
          <Route path="/cigs/:cigId" element={<CIG />} />

          <Route path="/claims" element={<Claims />} />
          <Route path="/claims/:claimId" element={<Claim />} />

          <Route path="/seo/streamers" element={<SeoStreamers />} />
          <Route path="/seo/streamers/:slug" element={<SeoStreamer />} />
        </Routes>
      </Shell>
    </>
  );
}
