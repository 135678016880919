import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import setCigFeatured, { SetCIGFeaturedRes } from "../setCigFeatured";

const useSetCigFeatured = (cigId?: string, accept?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<SetCIGFeaturedRes | null>(
    null,
  );

  const send = useCallback(async () => {
    if (cigId == null || accept == null) return reqState;
    setLoading();
    try {
      const acceptRes = await setCigFeatured(cigId, accept);
      return setSuccess(acceptRes);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigId, accept, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useSetCigFeatured;
