export enum SnackbarType {
  Info = "INFO",
  Error = "ERROR",
  Success = "SUCCESS",
  Warn = "WARN",
}

export enum SortDirection {
  Ascending = "ASC",
  Descending = "DESC",
}

export enum CIG {
  Gamer = "GAMER",
  Celebrity = "CELEBRITY",
  Influencer = "INFLUENCER",
}

export enum CountryCode {
  Australia = "AU",
  Austria = "AT",
  Belgium = "BE",
  Bulgaria = "BG",
  Canada = "CA",
  Croatia = "HR",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Germany = "DE",
  Gibraltar = "GI",
  Greece = "GR",
  HongKongSARChina = "HK",
  Hungary = "HU",
  Ireland = "IE",
  Italy = "IT",
  Japan = "JP",
  Latvia = "LV",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malta = "MT",
  Mexico = "MX",
  Netherlands = "NL",
  NewZealand = "NZ",
  Norway = "NO",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Singapore = "SG",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
  Switzerland = "CH",
  UnitedArabEmirates = "AE",
  UnitedKingdom = "GB",
  UnitedStates = "US",
}

export enum Currency {
  USD = "USD",
  AED = "AED",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BMD = "BMD",
  BND = "BND",
  BSD = "BSD",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHF = "CHF",
  CNY = "CNY",
  CZK = "CZK",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  GBP = "GBP",
  GEL = "GEL",
  GIP = "GIP",
  GMD = "GMD",
  GYD = "GYD",
  HKD = "HKD",
  HRK = "HRK",
  HTG = "HTG",
  IDR = "IDR",
  ILS = "ILS",
  ISK = "ISK",
  JMD = "JMD",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KRW = "KRW",
  KYD = "KYD",
  KZT = "KZT",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  MAD = "MAD",
  MDL = "MDL",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRO = "MRO",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SEK = "SEK",
  SGD = "SGD",
  SLL = "SLL",
  SOS = "SOS",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UZS = "UZS",
  WST = "WST",
  XCD = "XCD",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  NotSet = "NOT_SET",
}

export enum Platform {
  Twitter = "TWITTER",
  YouTube = "YOUTUBE",
  Twitch = "TWITCH",
  TikTok = "TIKTOK",
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
}

export enum VerificationPlatform {
  Twitter = "TWITTER",
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
}

export enum ClaimState {
  Resolved = "RESOLVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Created = "CREATED",
}

export enum ClaimReason {
  TechnicalDifficulties = "TECHNICAL_ISSUE",
  CigNoShow = "MISSED_APPOINTMENT",
  Other = "OTHER",
}

export enum UpdateClaimState {
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

export enum GameCategory {
  ACTION = "ACTION",
  ADVENTURE = "ADVENTURE",
  ARCADE = "ARCADE",
  BOARD = "BOARD",
  CARD = "CARD",
  CASUAL = "CASUAL",
  EDUCATIONAL = "EDUCATIONAL",
  FAMILY = "FAMILY",
  FPS = "FPS",
  FIGHTING = "FIGHTING",
  MUSIC = "MUSIC",
  PUZZLE = "PUZZLE",
  RACING = "RACING",
  REAL_TIME_SIMULATION = "REAL_TIME_SIMULATION",
  ROLE_PLAYING = "ROLE_PLAYING",
  SHOOTER = "SHOOTER",
  SIMULATION = "SIMULATION",
  SPORTS = "SPORTS",
  STRATEGY = "STRATEGY",
  TURN_BASED = "TURN_BASED",
  WALKING = "WALKING",
}
