import { Snackbar } from "@lib/types/generic";
import { generateUuid, log } from "@lib/utils/generic";
import { useState } from "react";

const useSnackbarManager = () => {
  const [snackbars, setSnackbars] = useState<Snackbar[]>([]);

  const spliceSnackbar = (snackbar: Snackbar) => {
    setSnackbars(snackbars => {
      const index = snackbars.indexOf(snackbar);
      if (index < 0) return snackbars;
      const snackbarsCopy = [...snackbars];
      snackbarsCopy.splice(index, 1);
      return snackbarsCopy;
    });
  };

  const pushSnackbar = (snackbar: Snackbar) => {
    log("Pushing snackbar: ", snackbar);
    setSnackbars(snackbars => [...snackbars, { ...snackbar, key: generateUuid() }]);
  };

  return { snackbars, setSnackbars, spliceSnackbar, pushSnackbar };
};

export default useSnackbarManager;
