import {
  ChangeEvent,
  cloneElement,
  DragEvent,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import mime from "mime-types";
import { bytesToSize, classNames } from "@lib/utils/generic";
import { CSSProps } from "@lib/types/generic";
import { FormElement } from "./Form";
import { ExclamationCircleIcon, PhotographIcon } from "@heroicons/react/outline";
import useCombinedRefs from "@hooks/useCombinedRefs";

interface Props extends CSSProps {
  name?: string;
  whitelistedFileTypes?: string[];
  maxBytes?: number;
  icon?: ReactElement;
  initialUrl?: string;
  value?: File;
  error?: boolean;
  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
  onFocus?: (target: FormElement) => void;
  onFileChange?: (file: File) => void;
  disabled?: boolean;
}

export type FilePickerProps = Props;

const FilePicker = forwardRef<HTMLInputElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    whitelistedFileTypes = [],
    maxBytes,
    name = "",
    icon = <PhotographIcon className="w-8 h-8" />,
    initialUrl,
    value,
    error,
    onChange = () => {},
    onBlur = () => {},
    onFileChange = () => {},
    disabled,
    children,

    style,
    className = "",
    id,
  } = props;

  const mimeTypes = whitelistedFileTypes.reduce((acc, fileType) => {
    const mimeType = mime.lookup(fileType);
    return mimeType ? [...acc, mimeType] : acc;
  }, [] as string[]);
  const fileTypes = mimeTypes.map(mimeType => mime.extension(mimeType));

  const inputRef = useRef<HTMLInputElement>(null);
  const refs = useCombinedRefs(inputRef, ref);

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [isOver, setIsOver] = useState(false);

  const handleChange = useCallback(
    (onFunc: (target: FormElement) => void) => (file: File) => {
      setFileError(null);
      setFile(null);
      if (!mimeTypes.includes(file.type)) return setFileError("File type not allowed");
      if (maxBytes && file.size > maxBytes) return setFileError("File size too large");

      setFile(file);
      onFunc({ type: "file", value: URL.createObjectURL(file), name });
      onFileChange(file);
    },
    [name, mimeTypes, maxBytes, onFileChange],
  );

  const handleBoth = useCallback(
    (onFunc: (target: FormElement) => void) => (event: ChangeEvent<HTMLInputElement>) => {
      const [file] = event.target.files ?? [];
      if (file == null) return;
      handleChange(onFunc)(file);
    },
    [handleChange],
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLLabelElement>) => {
      setIsOver(false);

      const { dataTransfer } = event;
      const current = inputRef.current;
      if (!current || !dataTransfer) return;

      current.files = dataTransfer.files;
      const file = dataTransfer.files[0];

      if (file) handleChange(onChange)(file);

      event.preventDefault();
    },
    [setIsOver, onChange, handleChange],
  );

  const handleDrag = useCallback(
    (isOver?: boolean) => (event: DragEvent) => {
      event.preventDefault();
      if (isOver != null) setIsOver(isOver);
    },
    [setIsOver],
  );

  useEffect(() => {
    if (value != null) setFile(value);
  }, [value, setFile]);

  const previewSrc = useMemo(
    () => (file != null ? URL.createObjectURL(file) : initialUrl),
    [file, initialUrl],
  );

  const fileTypesString = fileTypes.join(", ");
  const mimeTypesString = mimeTypes.join(", ");
  const accept = [fileTypesString, mimeTypesString].join(", ");

  if (children != null && disabled) {
    return <>{children}</>;
  }

  const useWhiteDetail = previewSrc && !isOver;
  const hasErrored = error || fileError;

  return (
    <label
      htmlFor={`file-upload-${name}`}
      className={classNames(
        "h-40 mt-1 flex justify-center px-6 pt-5 pb-6 bg-origin-border border-2 border-dashed rounded-md bg-contain bg-center bg-no-repeat",
        useWhiteDetail ? "border-white" : "border-gray-300",
        hasErrored ? "border-red-300" : "",
        className,
        disabled ? "" : "cursor-pointer",
        previewSrc ? "bg-gray-200" : "",
      )}
      id={id}
      style={{ backgroundImage: !isOver ? `url(${previewSrc})` : "", ...style }}
      onDrop={handleDrop}
      onDragEnter={handleDrag(true)}
      onDragOver={handleDrag()}
      onDragLeave={handleDrag(false)}>
      <div
        className={classNames(
          "space-y-1 p-2 text-center rounded-md pointer-events-none",
          useWhiteDetail ? "bg-black/30" : "",
          disabled ? "hidden" : "",
        )}>
        {cloneElement(hasErrored ? <ExclamationCircleIcon /> : icon, {
          className: classNames(
            "mx-auto h-12 w-12",
            useWhiteDetail ? "text-white" : "text-gray-300",
            hasErrored ? "text-red-300" : "",
          ),
        })}
        <div
          className={classNames(
            "flex text-sm",
            useWhiteDetail ? "text-white" : "text-gray-600",
            hasErrored ? "text-red-500" : "",
          )}>
          <input
            id={`file-upload-${name}`}
            name="file-upload"
            type="file"
            className="sr-only"
            accept={accept}
            ref={refs}
            disabled={disabled}
            onChange={handleBoth(onChange)}
            onBlur={handleBoth(onBlur)}
          />
          <span className="pl-1">
            {fileError ?? (
              <>
                <span className="font-medium">Upload a file</span> or drag and drop
              </>
            )}
          </span>
        </div>
        <p className={classNames("text-xs", useWhiteDetail ? "text-white" : "text-gray-600")}>
          {fileTypesString.toUpperCase()}
          {maxBytes && ` up to ${bytesToSize(maxBytes)}`}
        </p>
      </div>
    </label>
  );
});

export default FilePicker;
