import useGetBanners from "@api/public/get/hooks/useGetBanners";
import useGetCigs from "@api/public/get/hooks/useGetCigs";
import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FilePicker from "@components/common/form/FilePicker";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import FormText from "@components/common/form/FormText";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import Page from "@components/layout/Page";
import useGlobalContext from "@hooks/useGlobalContext";
import { bannerTypeNames, createSuccessSnackbar } from "@lib/constants/generic";
import { discernBannerType } from "@lib/utils/generic";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteBannerModal from "./DeleteBannerModal";
import useEditBannerHandle from "./hooks/useEditBannerHandle";

const initialValues = {
  bannerID: "",
  title: "",
  content: "",
  imageURL: "",
  imageURLXS: "",
};

export type EditBannerValues = typeof initialValues;

export default function Banner() {
  const { bannerId } = useParams();

  const { pushSnackbar } = useGlobalContext();

  const navigate = useNavigate();

  const [imageFile, setImageFile] = useState<File>();
  const [imageXSFile, setImageXSFile] = useState<File>();
  const [selectedBannerId, setSelectedBannerId] = useState<string>();

  const { data: cigs, isLoading: cigsIsLoading, error: cigsError } = useGetCigs(true);

  const { data: banners, isLoading: bannersIsLoading, error: bannersError } = useGetBanners(true);

  const banner = banners.find(({ id }) => bannerId === id);

  const cig = cigs.find(({ id }) => id === banner?.cig?.id);

  const formManager = useFormManager(initialValues, "editBanner");

  const { values, validations, resetValidations, onBlur, onChange, setValues } = formManager;

  const [handle, editIsLoading] = useEditBannerHandle(formManager, imageFile, imageXSFile);

  useEffect(() => {
    if (banner == null) return;
    const { id, title, content, imageURL, imageURLXS } = banner;

    setValues({
      ...initialValues,
      bannerID: id,
      title,
      content,
      imageURL,
      imageURLXS,
    });
  }, [banner, setValues]);

  return (
    <Page
      title={banner?.title ?? "Banner"}
      isLoading={bannersIsLoading || cigsIsLoading}
      error={bannersError || cigsError}
      breadcrumbs={[
        {
          title: "Banners",
          to: "/banners",
        },
      ]}
      buttons={
        <>
          <Button type="submit" form="edit-banner-form" isLoading={editIsLoading}>
            Save
          </Button>
          <Button
            type="submit"
            bgColor="bg-red-600"
            bgHoverColor="hover:bg-red-700"
            onClick={() => bannerId && setSelectedBannerId(bannerId)}>
            Delete
          </Button>
        </>
      }>
      <Form
        handle={handle}
        validation={validations.generic}
        id="edit-banner-form"
        resetValidations={resetValidations}
        onCompletion={() => pushSnackbar(createSuccessSnackbar("Banner Updated", undefined, 3000))}>
        <Containers>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Desktop Image" validation={validations.imageURL}>
                  <FilePicker
                    name="imageURL"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.imageURL}
                    value={imageFile}
                    onChange={onChange}
                    onFileChange={setImageFile}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Mobile Image" validation={validations.imageURLXS}>
                  <FilePicker
                    name="imageURLXS"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.imageURLXS}
                    value={imageXSFile}
                    onChange={onChange}
                    onFileChange={setImageXSFile}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Banner Type">
                  <FormText>{banner && bannerTypeNames[discernBannerType(banner)]}</FormText>
                </FormEntry>
              </FormRow>

              <FormRow>
                <FormEntry label="Title" validation={validations.title}>
                  <Input
                    name="title"
                    value={values.title}
                    placeholder="Title"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>

              <FormRow>
                {cig ? (
                  <FormEntry label="CIG">
                    <div className="flex items-center gap-3">
                      <FormText noMargin className="mt-0">
                        {cig?.displayName}
                      </FormText>
                      <Button
                        size="xs"
                        bgColor="bg-gray-100"
                        bgHoverColor="hover:bg-gray-200"
                        textColor="black"
                        to={`/cigs/${cig?.id}`}>
                        View
                      </Button>
                    </div>
                  </FormEntry>
                ) : (
                  <FormEntry label="Content" validation={validations.content}>
                    <Input
                      name="content"
                      value={values.content}
                      placeholder="Content"
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </FormEntry>
                )}
              </FormRow>
            </FormRows>
          </Card>
        </Containers>
      </Form>

      <DeleteBannerModal
        bannerId={selectedBannerId}
        onDelete={() => navigate("/banners")}
        onClose={() => setSelectedBannerId(undefined)}
      />
    </Page>
  );
}
