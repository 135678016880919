import { getMsgFromApiErr } from "@api/api";
import { BannerDetails } from "@api/public/get/getBanners";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updateBanner, { UpdateBanner } from "../updateBanner";

const useUpdateBanner = (banner?: UpdateBanner) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<BannerDetails | null>(
    null,
  );

  const send = useCallback(
    async (bannerOverride?: UpdateBanner) => {
      const _banner = bannerOverride ?? banner;
      if (_banner == null) return reqState;
      setLoading();
      try {
        const banner = await updateBanner(_banner);
        return setSuccess(banner);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [banner, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateBanner;
