import DataCheck from "@components/common/DataCheck";
import { classNames } from "@lib/utils/generic";
import React, { cloneElement, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import DashboardHeading from "./DashboardHeading";

interface Card {
  name: ReactNode;
  amount: ReactNode;
  to: string;
  icon: ReactElement;
  isLoading?: boolean;
  error?: string | null;
}

interface Props {
  cards: Card[];
}

export default function DashboardOverview(props: Props) {
  const { cards } = props;

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <DashboardHeading>Overview</DashboardHeading>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {/* Card */}
        {cards.map(({ name, amount, icon, to, isLoading, error }, i) => (
          <div key={i} className="relative bg-white overflow-hidden shadow rounded-lg h-[132px]">
            <DataCheck isLoading={isLoading} error={error} loadingIndicator="spinner">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {cloneElement(icon, {
                      className: classNames("h-6 w-6 text-gray-400", icon.props.className),
                      "aria-hidden": true,
                    })}
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">{name}</dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">{amount}</div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <Link to={to} className="font-medium text-cyan-700 hover:text-cyan-900">
                    View all
                  </Link>
                </div>
              </div>
            </DataCheck>
          </div>
        ))}
      </div>
    </div>
  );
}
