import useGetPlatforms from "@api/public/get/hooks/useGetPlatforms";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import React, { useState } from "react";
import DeletePlatformModal from "./platform/DeletePlatformModal";

export default function Platforms() {
  const { send: getPlatforms, data: platforms, isLoading, error } = useGetPlatforms(true);

  const [selectedPlatformId, setSelectedPlatformId] = useState<string>();

  const { sortedData: sortedPlatforms, ...tableSort } = useTableSort(platforms);

  return (
    <Page title="Platforms" buttons={<Button to="/platforms/create">Create</Button>}>
      <Table
        headers={[
          { key: "name", name: "Name", sortable: true },
          { key: "shortName", name: "Short Name", sortable: true },
          { name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        {...tableSort}>
        {sortedPlatforms.map(({ id, name, shortName }) => (
          <TableRow key={id}>
            <TableCell>{name}</TableCell>
            <TableCell>{shortName}</TableCell>
            <TableCell>
              <ButtonRow className="justify-end">
                <Button
                  bgColor="bg-violet-600"
                  bgHoverColor="hover:bg-violet-700"
                  to={`/platforms/${id}`}>
                  View
                </Button>
                <Button
                  bgColor="bg-red-600"
                  bgHoverColor="hover:bg-red-700"
                  onClick={() => setSelectedPlatformId(id)}>
                  Delete
                </Button>
              </ButtonRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <DeletePlatformModal
        platformId={selectedPlatformId}
        onDelete={getPlatforms}
        onClose={() => setSelectedPlatformId(undefined)}
      />
    </Page>
  );
}
