import { CSSProps } from "@lib/types/generic";
import { forwardRef, useCallback, useEffect, useState } from "react";

import { FormElement } from "./Form";
import Input, { InputProps } from "./Input";

interface Props extends CSSProps, Omit<InputProps, "type" | "value"> {
  value?: number;
  max?: number;
  min?: number;
  padStart?: number;
  numberType?: "integer" | "float";
  onChange?: (target: FormElement<number>) => void;
  onBlur?: (target: FormElement<number>) => void;
}

export type NumberInputProps = Props;

const NumberInput = forwardRef<HTMLInputElement & HTMLTextAreaElement, Props>((props, ref) => {
  const {
    max,
    min,
    value = min ?? 0,
    padStart = 0,
    numberType = "float",
    onChange = () => {},
    onBlur = () => {},
    ...inputProps
  } = props;

  const [valueOverride, setValueOverride] = useState<string | null>(null);
  const [editing, setEditing] = useState(false);

  const handleBoth = useCallback(
    (onFunc?: (target: FormElement<number>) => void, isChange: boolean = false) =>
      ({ value }: FormElement<string>) => {
        if (value.split(".")[1]?.length > 2) return;
        if (value.split(".").length - 1 > 1) return;

        setEditing(isChange);

        value = value.replace(/(^0)(0*)(\.\d*)/g, "$1$3");
        if (isChange && value.length === 0) setValueOverride("");
        else if (
          (value.endsWith(".") && numberType === "float") ||
          value.endsWith("0") ||
          value === "-"
        )
          setValueOverride(value);
        else setValueOverride(null);

        let numValue = Number(value);

        if (!isChange && value.length === 0) numValue = min ?? 0;
        if (isNaN(numValue)) return;
        if (!isChange && min != null && numValue < min) numValue = min ?? 0;
        if (!isChange && max != null && numValue > max) numValue = max ?? 0;

        if (onFunc) onFunc({ name: inputProps.name ?? "", value: numValue, type: "number" });
      },
    [max, min, numberType, inputProps.name],
  );

  const handleBlur = useCallback(
    ({ value, ...target }: FormElement<string>) => {
      if (numberType === "float") {
        value = value
          .replace(/(^-?[1-9]\d*$)/g, "$1.00")
          .replace(/(^-?[1-9]\d*\.$)/g, "$100")
          .replace(/(^-?[1-9]\d*\.0$)/g, "$10");
      }

      return { ...target, value };
    },
    [numberType],
  );

  useEffect(() => {
    if (editing) return;
    const target = { name: inputProps.name ?? "", value: value.toString() };
    setValueOverride(null);
    handleBoth()(handleBlur(target));
  }, [value, handleBoth, handleBlur, inputProps.name, editing]);

  return (
    <Input
      ref={ref}
      placeholder={(min != null ? min?.toString() : "0") + (numberType === "float" ? ".00" : "")}
      {...inputProps}
      value={value === 0 ? "" : (valueOverride ?? value.toString()).padStart(padStart, "0")}
      type="number"
      onChange={handleBoth(onChange, true)}
      onBlur={target => handleBoth(onBlur)(handleBlur(target))}
    />
  );
});

export default NumberInput;
