import { api } from "@api/api";
import { CIGProfile } from "@api/public/get/getCigProfile";

export type UpdateCIGProfile = {
  cigID: string;

  bannerPictureURL?: string;
  profilePictureURL?: string;
  subCategory?: string;
  tagLine?: string | null;

  instagramHandle?: string | null;
  tiktokHandle?: string | null;
  twitchHandle?: string | null;
  twitterHandle?: string | null;
  youtubeHandle?: string | null;
};

const updateCIGProfile = async (cigProfile: UpdateCIGProfile) => {
  const { data } = await api.post<CIGProfile>("/updateCIGProfile", cigProfile);
  return data;
};

export default updateCIGProfile;
