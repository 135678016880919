import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

export default function ButtonRow(props: PropsWithChildren<CSSProps>) {
  const { className = "", style, id, children } = props;

  return (
    <div
      className={classNames("flex flex-row items-center gap-2", className)}
      style={style}
      id={id}>
      {children}
    </div>
  );
}
