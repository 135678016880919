import { api } from "@api/api";

export interface SetCIGFeaturedRes {}

const setCigFeatured = async (cigID: string, featured: boolean) => {
  const { data } = await api.post<SetCIGFeaturedRes>("/adminSetCIGFeatured", {
    cigID,
    featured,
  });
  return data;
};

export default setCigFeatured;
