import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import Divider from "@components/common/Divider";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import FormText from "@components/common/form/FormText";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import FormModal from "@components/common/modals/FormModal";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar, platformNames } from "@lib/constants/generic";
import React, { useEffect } from "react";
import useVerifyCigHandle from "./hooks/useVerifyCigHandle";

interface Props {
  cigId?: string;
  onClose?: () => void;
  onVerify?: () => void;
}

const initialValues = {
  cigId: "",
  verificationCode: "",
};

export type VerifiyCIGValues = typeof initialValues;

export default function VerifyCIGModal(props: Props) {
  const { cigId, onClose = () => {}, onVerify = () => {} } = props;

  const { userExtension, pushSnackbar } = useGlobalContext();

  const {
    data: cigProfile,
    isLoading: cigProfileIsLoading,
    error: cigProfileError,
  } = useGetCigProfile(cigId, userExtension?.id, true);

  const { displayName, verifiedHandle, verifiedPlatformName } = cigProfile ?? {};

  const formManager = useFormManager(initialValues, "verifyCig");

  const { values, validations, onChange, onBlur, resetValidations, setValues } = formManager;

  const [handle, verifyIsLoading] = useVerifyCigHandle(formManager);

  useEffect(() => {
    if (cigId) setValues(values => ({ ...values, cigId }));
  }, [cigId, setValues]);

  return (
    <FormModal
      active={!!cigId}
      isLoading={verifyIsLoading}
      form="verify-form"
      title={`Verify ${displayName}`}
      subTitle="This will verify their account, making them eligible for acceptance onto Yakkr."
      dataCheckProps={{
        isLoading: cigProfileIsLoading,
        error: cigProfileError,
      }}
      onCancel={onClose}
      onClose={onClose}>
      <Form
        id="verify-form"
        handle={handle}
        validation={validations.generic}
        resetValidations={resetValidations}
        resetOnCompletion
        onCompletion={() => {
          onVerify();
          onClose();
          pushSnackbar(
            createSuccessSnackbar(`${cigProfile?.displayName} Verified`, undefined, 3000),
          );
        }}>
        <FormRows>
          {verifiedPlatformName && verifiedHandle && (
            <>
              <FormText className="text-center text-sm">
                <span className="font-medium">{displayName}</span> should have sent their
                verification code via{" "}
                <span className="font-medium">{platformNames[verifiedPlatformName]}</span> from
                their account with the following handle.
              </FormText>
              <Input value={verifiedHandle} />
            </>
          )}
          <Divider />
          <FormRow>
            <FormEntry label="Verification Code" validation={validations.verificationCode}>
              <Input
                name="verificationCode"
                value={values.verificationCode}
                placeholder="XXXXXXXX"
                onChange={onChange}
                onBlur={onBlur}
              />
            </FormEntry>
          </FormRow>
        </FormRows>
      </Form>
    </FormModal>
  );
}
