import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import login from "../login";

export enum LoginError {
  EmailNotConfirmed = "EMAIL_NOT_CONFIRMED",
}

const useLogin = (email?: string, password?: string, staySignedIn?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<any>(null);

  const send = useCallback(async () => {
    if (email == null || password == null) {
      return setError("Email and password are required");
    }

    setLoading();
    try {
      const user = await login(email, password, staySignedIn);

      return setSuccess(user);
    } catch (err: any) {
      switch (err.code) {
        case "UserNotConfirmedException":
          return setError(LoginError.EmailNotConfirmed.toString());
        default:
          return setError(err.message);
      }
    }
  }, [email, password, staySignedIn, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useLogin;
