import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { UpdateClaimState } from "@lib/enums/generic";
import { useCallback } from "react";
import updateClaimState, { UpdateClaimRes } from "../updateClaimState";

const useUpdateClaimState = (claimID?: string, state?: UpdateClaimState) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<UpdateClaimRes | null>(
    null,
  );

  const send = useCallback(async () => {
    if (claimID == null || state == null) return reqState;
    setLoading();
    try {
      const updateRes = await updateClaimState(claimID, state);
      return setSuccess(updateRes);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [claimID, state, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateClaimState;
