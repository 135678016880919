import {
  cloneElement,
  Fragment,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { SnackbarType } from "@lib/enums/generic";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { snackbarColors } from "@lib/constants/generic";
import { classNames } from "@lib/utils/generic";

interface Props {
  title?: ReactNode;
  type?: SnackbarType;
  timeout?: number;
  icon?: ReactElement;
  onClose?: () => void;
}

const icons = {
  [SnackbarType.Info]: <InformationCircleIcon />,
  [SnackbarType.Success]: <CheckCircleIcon />,
  [SnackbarType.Warn]: <ExclamationCircleIcon />,
  [SnackbarType.Error]: <XCircleIcon />,
};

export interface SnackbarChildProps {
  onClose?: () => void;
}

export default function Snackbar(props: PropsWithChildren<Props>) {
  const {
    type = SnackbarType.Info,
    icon = icons[type],
    title,
    children,
    onClose = () => {},
    timeout,
  } = props;

  const color = snackbarColors[type];

  const [state, setState] = useState(true);

  const triggerClose = () => {
    setState(false);
    setTimeout(onClose, 300);
  };

  useEffect(() => {
    if (timeout != null) setTimeout(triggerClose, timeout - 300);

    // Needs to only run once
    // eslint-disable-next-line
  }, []);

  return (
    <Transition
      show={state}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0">
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {cloneElement(icon, {
                className: classNames("h-6 w-6", color, icon?.props.className),
              })}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {children && <p className="mt-1 text-sm text-gray-500">{children}</p>}
            </div>
            <div className="ml-4 mt-0.5 flex-shrink-0 flex">
              <button
                type="button"
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  setState(false);
                }}>
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
