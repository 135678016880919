import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getStreamerCorpuses, { GetStreamerCorpusesRes } from "../getStreamerCorpuses";

const useGetStreamerCorpuses = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<GetStreamerCorpusesRes>(
    [],
  );

  const send = useCallback(async () => {
    setLoading();

    const { data, error } = await getStreamerCorpuses();
    if (data) return setSuccess(data);
    if (error) setError(error);
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetStreamerCorpuses;
