import { BannerDetails, BannerType } from "@api/public/get/getBanners";
import { FormElement } from "@components/common/form/Form";
import { isDev } from "@config/config";
import { Currency, SortDirection } from "@lib/enums/generic";
import { MergedStreamer, SanityStreamer, StreamerCorpus } from "@lib/types/generic";
import dayjs from "dayjs";
import { ComponentProps, JSXElementConstructor, ReactElement } from "react";

export const log = (...text: any[]) => {
  if (isDev) console.log("[Development]", ...text);
};

export function generateUuid() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}

export function pathMatch(path: string, pathPattern: string) {
  const pathSegments = path.split("/");
  const pathPatternSegments = pathPattern.replace(/(:[\d\w-]*)/, "*").split("/");

  const pathSegmentsLength = pathSegments.length;
  const pathPatternSegmentsLength = pathPatternSegments.length;
  const length = Math.max(pathSegmentsLength, pathPatternSegmentsLength);

  for (let i = 0; i < length; i++) {
    const pathSegment = pathSegments[i];
    const pathPatternSegment = pathPatternSegments[i];

    if (pathPatternSegment === "*") continue;
    if (pathSegment !== pathPatternSegment) return false;
  }

  return true;
}

export function nodeIsElement(node: any): node is ReactElement {
  if (node == null) return false;
  if (typeof node !== "object") return false;
  return node.type != null;
}

export function elementInstanceOf<E extends JSXElementConstructor<any>>(
  element: ReactElement,
  ofElement: E,
): element is ReactElement<ComponentProps<E>> {
  return element.type === ofElement;
}

export function elementIsTag<E extends HTMLElement>(
  element: HTMLElement,
  tag: string,
): element is E {
  if (element == null) return false;
  return element.tagName === tag.toUpperCase();
}

export function getRootFromPath(pathname: string) {
  return `/${pathname.split("?")[0].split("/")[1]}`;
}

export const classNames = (...classNames: any[]) =>
  classNames.filter(className => !!className).join(" ");

export const getNestedValue = (object: Record<any, any>, key: string): any => {
  return key.split(".").reduce((acc, k) => (acc && acc[k]) ?? null, object);
};

export const isNumber = (value: any): value is number => typeof value === "number";
export const isString = (value: any): value is string => typeof value === "string";
export const isBoolean = (value: any): value is boolean => typeof value === "boolean";

export const sortMap = (key: string | number, direction: SortDirection) => (a: any, b: any) => {
  const keyISNum = typeof key === "number";

  let aVal = keyISNum ? a[key] : getNestedValue(a, key);
  let bVal = keyISNum ? b[key] : getNestedValue(b, key);

  if (aVal == null) return 1;
  if (bVal == null) return -1;

  const validFormats = [
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    "YYYY-MM-DDTHH:mm:ss.SSSZZ",
    "YYYY-MM-DDTHH:mm:ss[Z]",
    "YYYY-MM-DDTHH:mm:ssZ",
    "YYYY-MM-DDTHH:mm:ssZZ",
  ];

  const isDate = validFormats.some(format => dayjs(aVal, format, true).isValid());

  if (isDate) {
    const aDate = dayjs(aVal);
    const bDate = dayjs(bVal);

    return direction === SortDirection.Ascending ? aDate.diff(bDate) : bDate.diff(aDate);
  }

  if (isNumber(aVal) && isNumber(bVal)) {
    return direction === SortDirection.Ascending ? aVal - bVal : bVal - aVal;
  }

  if (isString(aVal) && isString(bVal)) {
    return direction === SortDirection.Ascending
      ? aVal.localeCompare(bVal)
      : bVal.localeCompare(aVal);
  }

  if (isBoolean(aVal) && isBoolean(bVal)) {
    return direction === SortDirection.Ascending
      ? Number(aVal) - Number(bVal)
      : Number(bVal) - Number(aVal);
  }

  return 0;
};

export const arraysEqual = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) return false;
  for (const i in arr1) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const bytesToSize = (bytes: number) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  return Math.round((bytes / Math.pow(1024, i)) * 10) / 10 + " " + sizes[i];
};

export const isCheckboxElement = (element: any): element is HTMLInputElement => {
  return element.type === "checkbox";
};

export const getValueFromElement = (target: FormElement) =>
  isCheckboxElement(target) ? target.checked : target.value;

export const numberToPrice = (number: number) => {
  const formatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    currencyDisplay: "symbol",
  });

  return formatter.format(number);
};

export const numberToCurrency = (number: number, currency?: Currency | null) => {
  if (currency == null || currency === Currency.NotSet) return "N/A";
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    currencyDisplay: "symbol",
  });

  return formatter.format(number);
};

export const getCurrencySymbol = (currency?: string | null) => {
  if (currency == null || currency === Currency.NotSet) return "N/A";
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency.toUpperCase(),
    currencyDisplay: "symbol",
  });

  return formatter.format(0).replace(/[0-9.,]+/, "");
};

export const discernBannerType = (banner: BannerDetails) => {
  if (banner.cig) return BannerType.CIGLink;
  return BannerType.Standard;
};

export const addToArray = (array: any[], item: any) => {
  if (!array.includes(item)) {
    array.push(item);
    return array;
  }
  return array;
};

export const removeFromArray = (array: any[], item: any) => {
  const index = array.findIndex(currItem => currItem === item);
  if (index < 0) return array;
  array.splice(index, 1);
  return array;
};

export const compareVersions = (version: string, requiredVersion: string) => {
  const majorMatch = version[0] === requiredVersion[0];
  const minorMatch = version[2] === requiredVersion[2];
  const patchMatch = version[4] === requiredVersion[4];

  return {
    major: majorMatch,
    minor: majorMatch && minorMatch,
    patch: majorMatch && minorMatch && patchMatch,
  };
};

export const mergeStreamer = (
  sanityStreamer: SanityStreamer,
  streamerCorpus?: Pick<StreamerCorpus, "bulkText"> | null,
): MergedStreamer => ({
  ...sanityStreamer,
  ...streamerCorpus,
});

export const mergeStreamers = (
  sanityStreamers: SanityStreamer[],
  streamerCorpuses: StreamerCorpus[],
) =>
  sanityStreamers.map(sanityStreamer => {
    const sanityCorpus = streamerCorpuses.find(
      ({ talentDisplayName }) => talentDisplayName === sanityStreamer.slug,
    );

    return mergeStreamer(sanityStreamer, sanityCorpus);
  });
