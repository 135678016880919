import useUpdateStreamerCorpus from "@api/private/update/hooks/useUpdateStreamerCorpus";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { EditSeoStreamerValues } from "../SeoStreamer";

const useEditSeoStreamerHandle: UseFormHandle<EditSeoStreamerValues> = ({
  validateFields,
  validateCustom,
  values,
}) => {
  const { send: updateStreamerCorpus, isLoading: updateStreamerCorpusIsLoading } =
    useUpdateStreamerCorpus(values);

  const handle = async () => {
    let failed = validateFields(["bulkText"]);

    if (!failed) {
      const { error } = await updateStreamerCorpus();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, updateStreamerCorpusIsLoading];
};

export default useEditSeoStreamerHandle;
