import { api } from "@api/api";

export interface CreateBannerRes {
  id: string;
}

const createBanner = async (
  title: string,
  content: string,
  imageURL: string,
  imageURLXS: string,
  cigID?: string,
  priority?: number,
) => {
  const { data } = await api.post<CreateBannerRes>("/createHomeBanner", {
    title,
    content,
    imageURL,
    imageURLXS,
    cigID,
    priority,
  });
  return data;
};

export default createBanner;
