import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Progress from "./Progress";

const classes = {
  xs: "inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
  sm: "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
  md: "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
  lg: "inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
  xl: "inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
};

interface Props extends CSSProps {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  textColor?: string;
  bgColor?: string;
  bgHoverColor?: string;
  borderColor?: string;
  to?: string;
  href?: string;
  disabled?: boolean;
  form?: string;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  as?: "link" | "a" | "button";
  openInNewTab?: boolean;

  onClick?: () => void;
}

export type ButtonProps = Props;

export default function Button(props: PropsWithChildren<Props>) {
  const {
    size = "md",
    textColor = "text-white",
    bgColor = "bg-indigo-600",
    bgHoverColor = "hover:bg-indigo-700",
    borderColor = "border-transparent",
    to,
    href,
    disabled,
    form,
    type = "button",
    isLoading,
    as,
    openInNewTab,

    children,
    className = "",
    id,
    style,

    onClick,
  } = props;

  if (as === "button" || (to == null && href == null) || disabled) {
    return (
      <button
        disabled={disabled}
        form={form}
        type={type}
        className={classNames(
          "justify-center border whitespace-nowrap",
          classes[size],
          textColor,
          bgColor,
          !disabled ? bgHoverColor : "opacity-80",
          borderColor,
          className,
        )}
        id={id}
        style={style}
        onClick={onClick}>
        {children}
        {isLoading && <Progress size="w-4 h-4" className="ml-2" />}
      </button>
    );
  } else if (as === "link" || to != null) {
    return (
      <Link
        type="button"
        className={classNames(
          "justify-center border whitespace-nowrap",
          classes[size],
          textColor,
          bgColor,
          !disabled ? bgHoverColor : "",
          borderColor,
          disabled ? "pointer-events-none" : "",
          className,
        )}
        id={id}
        style={style}
        to={to ?? ""}>
        {children}
      </Link>
    );
  } else {
    return (
      <a
        className={classNames(
          "justify-center border whitespace-nowrap",
          classes[size],
          textColor,
          bgColor,
          !disabled ? bgHoverColor : "",
          borderColor,
          disabled ? "pointer-events-none" : "",
          className,
        )}
        id={id}
        style={style}
        href={href}
        {...(openInNewTab
          ? {
              target: "_blank",
              rel: "noopener noreferrer",
            }
          : {})}>
        {children}
      </a>
    );
  }
}
