import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {
  gap?: string;
}

export default function FormRows(props: PropsWithChildren<Props>) {
  const { gap = "gap-4", children, className = "", style, id } = props;

  return (
    <div className={classNames("flex flex-col", gap, className)} id={id} style={style}>
      {children}
    </div>
  );
}
