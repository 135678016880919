import useGetCigs from "@api/public/get/hooks/useGetCigs";
import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FilePicker from "@components/common/form/FilePicker";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import Select from "@components/common/form/Select";
import Page from "@components/layout/Page";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React, { useState } from "react";
import useCreateBannerHandle from "./hooks/useCreateBannerHandle";

const initialValues = {
  title: "",
  content: "",
  imageURL: "",
  imageURLXS: "",
  cigID: undefined as string | undefined,
  priority: 0,
};

export type CreateBannerValues = typeof initialValues;

export default function CreateBanner() {
  const { pushSnackbar } = useGlobalContext();

  const [imageFile, setImageFile] = useState<File>();
  const [imageXSFile, setImageXSFile] = useState<File>();

  const { data: cigs, isLoading: cigsIsLoading, error: cigsError } = useGetCigs(true);

  const cigOptions = cigs.map(({ displayName, id }) => ({ value: id, name: displayName }));

  const formManager = useFormManager(initialValues, "createBanner");

  const { values, validations, resetValidations, onBlur, onChange } = formManager;

  const [handle, createIsLoading] = useCreateBannerHandle(formManager, imageFile, imageXSFile);

  return (
    <Page
      title="Create"
      error={cigsError}
      breadcrumbs={[
        {
          title: "Banners",
          to: "/banners",
        },
      ]}
      buttons={
        <>
          <Button type="submit" form="create-banner-form" isLoading={createIsLoading}>
            Save
          </Button>
        </>
      }>
      <Form
        handle={handle}
        validation={validations.generic}
        id="create-banner-form"
        resetValidations={resetValidations}
        onCompletion={() => pushSnackbar(createSuccessSnackbar("Banner Created", undefined, 3000))}>
        <Containers>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Desktop Image" validation={validations.imageURL}>
                  <FilePicker
                    name="imageURL"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.imageURL}
                    value={imageFile}
                    onChange={onChange}
                    onFileChange={setImageFile}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Mobile Image" validation={validations.imageURLXS}>
                  <FilePicker
                    name="imageURLXS"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.imageURLXS}
                    value={imageXSFile}
                    onChange={onChange}
                    onFileChange={setImageXSFile}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Title" validation={validations.title}>
                  <Input
                    name="title"
                    value={values.title}
                    placeholder="Title"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Content" validation={validations.content}>
                  <Input
                    name="content"
                    value={values.content}
                    placeholder="Content"
                    disabled={!!values.cigID}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="CIG" validation={validations.cigID}>
                  <Select
                    name="cigID"
                    value={values.cigID}
                    options={cigOptions}
                    isLoading={cigsIsLoading}
                    disabled={!!values.content}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
        </Containers>
      </Form>
    </Page>
  );
}
