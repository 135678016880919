import { ExclamationIcon } from "@heroicons/react/outline";
import React from "react";
import Modal, { ModalProps } from "./Modal";

export default function DeleteModal(props: ModalProps) {
  return (
    <Modal
      icon={<ExclamationIcon />}
      iconBgColor="bg-red-100"
      iconColor="text-red-600"
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      confirmButtonProps={{
        bgColor: "bg-red-600",
        bgHoverColor: "hover:bg-red-700",
        borderColor: "border-transparent",
        textColor: "text-white",
      }}
      subTitle="Once deleted, it cannot be undone!"
      {...props}
    />
  );
}
