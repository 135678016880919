import useVerifyCig from "@api/private/update/hooks/useVerifyCig";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { VerifiyCIGValues } from "../VerifyCIGModal";

const useVerifyCigHandle: UseFormHandle<VerifiyCIGValues> = ({
  validateFields,
  validateCustom,
  values,
}) => {
  const { send: verifyCig, isLoading } = useVerifyCig(values.cigId, values.verificationCode);

  const handle = async () => {
    let failed = validateFields(["cigId", "verificationCode"]);

    if (!failed) {
      const { error } = await verifyCig();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useVerifyCigHandle;
