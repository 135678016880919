import { CompatibilityConfig } from "@api/private/update/updateGameCompatibility";
import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FilePicker from "@components/common/form/FilePicker";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import NumberInput from "@components/common/form/NumberInput";
import Select from "@components/common/form/Select";
import Page from "@components/layout/Page";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar, gameCategoryNames } from "@lib/constants/generic";
import PlatformCompatibilityConfigurator from "@pages/games/game/PlatformCompatibilityConfigurator";
import React, { useState } from "react";
import useCreateGameHandle from "./hooks/useCreateGameHandle";

const initialValues = {
  longName: "",
  shortName: "",
  primaryImageURL: "",
  secondaryImageURL: "",
  tertiaryImageURL: "",
  description: "",
  category: "",
  platformCompatibility: [] as CompatibilityConfig[],
  statisticName: "",
  statisticValue: "",
  estimatedMaxLengthMins: 0,
};

export type CreateGameValues = typeof initialValues;

export default function CreateGame() {
  const { pushSnackbar } = useGlobalContext();

  const [primaryImageFile, setPrimaryImageFile] = useState<File>();
  const [secondaryImageFile, setSecondaryImageFile] = useState<File>();
  const [tertiaryImageFile, setTertiaryImageFile] = useState<File>();

  const formManager = useFormManager(initialValues, "createGame");

  const { values, validations, resetValidations, onBlur, onChange } = formManager;

  const [handle, createIsLoading] = useCreateGameHandle(
    formManager,
    primaryImageFile,
    secondaryImageFile,
    tertiaryImageFile,
  );

  const categoryOptions = Object.entries(gameCategoryNames).map(([key, value]) => ({
    name: value,
    value: key,
  }));

  return (
    <Page
      title="Create"
      breadcrumbs={[
        {
          title: "Games",
          to: "/games",
        },
      ]}
      buttons={
        <>
          <Button type="submit" form="create-game-form" isLoading={createIsLoading}>
            Save
          </Button>
        </>
      }>
      <Form
        handle={handle}
        validation={validations.generic}
        id="create-game-form"
        resetValidations={resetValidations}
        onCompletion={() => {
          pushSnackbar(createSuccessSnackbar("Game Created", undefined, 3000));
        }}>
        <Containers>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Upcoming Image" validation={validations.primaryImageURL}>
                  <FilePicker
                    name="primaryImageURL"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.primaryImageURL}
                    value={primaryImageFile}
                    onChange={onChange}
                    onFileChange={setPrimaryImageFile}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Bookings Image" validation={validations.secondaryImageURL}>
                  <FilePicker
                    name="secondaryImageURL"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.secondaryImageURL}
                    value={secondaryImageFile}
                    onChange={onChange}
                    onFileChange={setSecondaryImageFile}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Covert Art" validation={validations.tertiaryImageURL}>
                  <FilePicker
                    name="tertiaryImageURL"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.tertiaryImageURL}
                    value={tertiaryImageFile}
                    onChange={onChange}
                    onFileChange={setTertiaryImageFile}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Game Name" validation={validations.longName}>
                  <Input
                    name="longName"
                    value={values.longName}
                    placeholder="Long Name"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry label="Short Name" validation={validations.shortName}>
                  <Input
                    name="shortName"
                    value={values.shortName}
                    placeholder="Short Name"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Description" validation={validations.description}>
                  <Input
                    name="description"
                    value={values.description}
                    placeholder="Description"
                    multiline
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Genre" validation={validations.category} className="basis-1/2">
                  <Select
                    name="category"
                    options={categoryOptions}
                    value={values.category}
                    placeholder="Genre"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry
                  label="Maximum Game Length (Minutes)"
                  validation={validations.estimatedMaxLengthMins}
                  className="basis-1/2">
                  <NumberInput
                    name="estimatedMaxLengthMins"
                    value={values.estimatedMaxLengthMins}
                    onChange={onChange}
                    onBlur={onBlur}
                    numberType="integer"
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Statistic Name" validation={validations.statisticName}>
                  <Input
                    name="statisticName"
                    value={values.statisticName}
                    placeholder="Monthly Viewers"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry label="Statistic Value" validation={validations.statisticValue}>
                  <Input
                    name="statisticValue"
                    value={values.statisticValue}
                    placeholder="30K"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
        </Containers>

        <Containers className="mt-6">
          <Card>
            <PlatformCompatibilityConfigurator
              name="platformCompatibility"
              value={values.platformCompatibility}
              validation={validations.platformCompatibility}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Card>
        </Containers>
      </Form>
    </Page>
  );
}
