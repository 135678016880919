import { api } from "@api/api";
import { BannerDetails } from "@api/public/get/getBanners";

export type UpdateBanner = {
  bannerID: string;
  title?: string;
  content?: string;
  imageURL?: string;
  imageURLXS?: string;
  priority?: number;
};

const updateBanner = async (banner: UpdateBanner) => {
  const { data } = await api.post<BannerDetails>("/updateBanner", banner);
  return data;
};

export default updateBanner;
