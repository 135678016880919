import useGetPendingCigs from "@api/public/get/hooks/useGetPendingCigs";
import useGetUnverifiedCigs from "@api/public/get/hooks/useGetUnverifiedCigs";
import Badge from "@components/common/Badge";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import useSortFromQuery from "@hooks/useSortFromQuery";
import React, { useState } from "react";
import AcceptCIGModal from "./game/AcceptCIGModal";
import VerifyCIGModal from "./game/VerifyCIGModal";

export default function PendingCIGs() {
  const { sortKey, sortDirection } = useSortFromQuery();

  const {
    send: getUnverfiiedCigs,
    data: unverifiedCigs,
    isLoading: unverifiedCigsIsLoading,
    error: unverifiedCigsError,
  } = useGetUnverifiedCigs(true);
  const {
    send: getPendingCigs,
    data: pendingCigs,
    isLoading: pendingCigsIsLoading,
    error: pendingCigsError,
  } = useGetPendingCigs(true);

  const [selectedVerifyCigId, setSelectedVerifyCigId] = useState<string>();
  const [selectedAcceptCigId, setSelectedAcceptCigId] = useState<string>();

  const cigs = [...unverifiedCigs, ...pendingCigs];

  const { sortedData: sortedCigs, ...tableSort } = useTableSort(cigs, sortKey, sortDirection);

  const isLoading = unverifiedCigsIsLoading || pendingCigsIsLoading;
  const error = unverifiedCigsError || pendingCigsError;

  const getCigs = () => {
    getUnverfiiedCigs();
    getPendingCigs();
  };

  return (
    <Page
      title="Pending CIGs"
      buttons={
        <Button bgColor="bg-violet-600" bgHoverColor="hover:bg-violet-700" to="/cigs">
          Go Back
        </Button>
      }>
      <Table
        headers={[
          { key: "displayName", name: "Display Name", sortable: true },
          { key: "category", name: "Type", sortable: true },
          { key: "verified", name: "Verified", sortable: true },
          { key: "accepted", name: "Accepted", sortable: true },
          { name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        {...tableSort}>
        {sortedCigs.map(({ id, displayName, category, verified, accepted }, i) => (
          <TableRow key={i}>
            <TableCell>{displayName}</TableCell>
            <TableCell>{category}</TableCell>
            <TableCell>
              <Badge className="w-min" bgColor={verified ? "bg-green-100" : "bg-red-100"}>
                {verified ? "Verified" : "Unverified"}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge className="w-min" bgColor={accepted ? "bg-green-100" : "bg-red-100"}>
                {accepted ? "Accepted" : "Not Accepted"}
              </Badge>
            </TableCell>
            <TableCell>
              <ButtonRow className="justify-end">
                {!verified && <Button onClick={() => setSelectedVerifyCigId(id)}>Verifiy</Button>}
                {verified && (
                  <Button
                    bgColor="bg-green-600"
                    bgHoverColor="hover:bg-green-700"
                    onClick={() => setSelectedAcceptCigId(id)}>
                    Accept
                  </Button>
                )}

                <Button
                  bgColor="bg-violet-600"
                  bgHoverColor="hover:bg-violet-700"
                  to={`/cigs/${id}`}>
                  View
                </Button>
              </ButtonRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      <VerifyCIGModal
        cigId={selectedVerifyCigId}
        onClose={() => setSelectedVerifyCigId(undefined)}
        onVerify={getCigs}
      />

      <AcceptCIGModal
        cigId={selectedAcceptCigId}
        onClose={() => setSelectedAcceptCigId(undefined)}
        onAccept={getCigs}
      />
    </Page>
  );
}
