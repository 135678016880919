import { Fragment, PropsWithChildren } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { classNames, getRootFromPath } from "@lib/utils/generic";
import Banner from "@components/common/Banner";
import useGlobalContext from "@hooks/useGlobalContext";
import Divider from "@components/common/Divider";

const navigation = [
  { name: "Dashboard", path: "/" },
  { name: "Games", path: "/games" },
  { name: "Platforms", path: "/platforms" },
  { name: "Banners", path: "/banners" },
  { name: "Homepage", path: "/homepage" },
  { name: "CIGs", path: "/cigs" },
  { name: "Claims", path: "/claims" },
  { name: "SEO Streamers", path: "/seo/streamers" },
];

const userNavigation = [{ name: "Sign out", path: "/logout" }];

export default function Shell(props: PropsWithChildren<{}>) {
  const { children } = props;

  const { user, userExtension } = useGlobalContext();

  const isLoggedIn = !!user;

  const { displayName, lastName, firstName } = userExtension ?? {};

  return (
    <>
      <div className="min-h-full flex flex-col">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <Banner svgProps={{ className: "fill-white", style: { height: 28 } }} />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map(item => (
                          <NavItem {...item} key={item.path} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        {isLoggedIn && (
                          <div>
                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <div className="flex flex-row items-center justify-center h-8 w-8 rounded-full bg-gray-600">
                                <p className="text-white text-base">
                                  {displayName?.charAt(0)?.toUpperCase()}
                                </p>
                              </div>
                            </Menu.Button>
                          </div>
                        )}
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
                            <span className="block px-4 pt-2 pb-3 text-sm text-gray-800">
                              <span className="font-medium ">
                                {firstName} {lastName}
                              </span>
                              <br />({displayName})
                            </span>
                            <Divider className="mb-1" />
                            {userNavigation.map(item => (
                              <UserNavItem {...item} key={item.path} />
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map(item => (
                    <NavItemMobile {...item} key={item.path} />
                  ))}
                </div>
                {isLoggedIn && (
                  <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <div className="flex flex-row items-center justify-center h-10 w-10 rounded-full bg-gray-600">
                          <p className="text-white text-base">
                            {displayName?.charAt(0)?.toUpperCase()}
                          </p>
                        </div>
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white mb-1">
                          {firstName} {lastName}
                        </div>
                        <div className="text-sm font-medium leading-none text-gray-400">
                          {displayName}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                      {userNavigation.map(item => (
                        <UserNavItemMobile {...item} key={item.path} />
                      ))}
                    </div>
                  </div>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {children}
      </div>
    </>
  );
}

const NavItem = ({ path, name }: { path: string; name: string }) => {
  const location = useLocation();

  const pathSelected =
    path === "/" ? location.pathname === "/" : location.pathname.startsWith(path);

  return (
    <Link
      to={path}
      className={classNames(
        pathSelected
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "px-3 py-2 rounded-md text-sm font-medium",
      )}
      aria-current={pathSelected ? "page" : undefined}>
      {name}
    </Link>
  );
};

const NavItemMobile = ({ path, name }: { path: string; name: string }) => {
  const location = useLocation();
  const pathRoot = getRootFromPath(location.pathname);

  const pathSelected = pathRoot === path;

  return (
    <Disclosure.Button
      key={name}
      className={classNames(
        pathSelected
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "block px-3 py-2 rounded-md text-base font-medium",
      )}
      aria-current={pathSelected ? "page" : undefined}>
      <Link to={path}>{name}</Link>
    </Disclosure.Button>
  );
};

const UserNavItem = ({ path, name }: { path: string; name: string }) => (
  <Menu.Item key={name}>
    {({ active }) => (
      <Link
        to={path}
        className={classNames(
          active ? "bg-gray-100" : "",
          "block px-4 py-2 text-sm text-gray-700",
        )}>
        {name}
      </Link>
    )}
  </Menu.Item>
);

const UserNavItemMobile = ({ path, name }: { path: string; name: string }) => (
  <Disclosure.Button
    key={name}
    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">
    <Link to={path}>{name}</Link>
  </Disclosure.Button>
);
