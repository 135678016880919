import DataCheck from "@components/common/DataCheck";
import React, { PropsWithChildren } from "react";
import Header, { HeaderProps } from "./Header";

interface Props extends HeaderProps {
  isLoading?: boolean;
  error?: string | null;
}

export default function Page(props: PropsWithChildren<Props>) {
  const { children, isLoading, error, ...headerProps } = props;

  return (
    <main className="relative flex-grow min-h-0">
      <Header isLoading={isLoading} error={error} {...headerProps} />
      <DataCheck isLoading={isLoading} error={error} loadingIndicator="spinner">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
      </DataCheck>
    </main>
  );
}
