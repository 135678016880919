import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getHome, { HomeSectionData, HomeSectionType } from "../getCigListings";

const sections: HomeSectionData[] = [
  {
    type: HomeSectionType.Cigs,
  },
  {
    type: HomeSectionType.BuildAndStartingSoon,
  },
  {
    type: HomeSectionType.Games,
  },
  {
    type: HomeSectionType.Cigs,
  },
  {
    type: HomeSectionType.Cigs,
  },
  { type: HomeSectionType.FeaturedSession },
  {
    type: HomeSectionType.FeaturedCig,
  },
  {
    type: HomeSectionType.Cta,
  },
];

const useGetHome = (userID?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<HomeSectionData[]>(sections);

  const send = useCallback(async () => {
    setLoading();
    try {
      const listings = await getHome(userID);
      setSuccess(listings);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [userID, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [userID, autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetHome;
