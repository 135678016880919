import useGlobalContext from "@hooks/useGlobalContext";
import { classNames } from "@lib/utils/generic";
import { createPortal } from "react-dom";
import Snackbar from "./Snackbar";

interface Props {
  horizontal?: "left" | "right";
  vertical?: "top" | "bottom";
}

export default function Snackbars(props: Props) {
  const { horizontal = "right", vertical = "bottom" } = props;

  const { snackbars, spliceSnackbar } = useGlobalContext();

  const portalRoot = document.getElementById("portal-root");
  if (portalRoot == null) return null;

  return createPortal(
    <div
      aria-live="assertive"
      className={classNames(
        "fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 z-50",
        vertical === "bottom" ? "sm:items-end" : "sm:items-start",
      )}>
      <div
        className={classNames(
          "w-full flex flex-col items-center space-y-4",
          horizontal === "right" ? "sm:items-end" : "sm:items-start",
        )}>
        {snackbars.map(snackbar => {
          const { key, type, timeout, title, content, icon } = snackbar;
          return (
            <Snackbar
              key={key}
              type={type}
              timeout={timeout}
              icon={icon}
              title={title}
              onClose={() => spliceSnackbar(snackbar)}>
              {content}
            </Snackbar>
          );
        })}
      </div>
    </div>,
    portalRoot,
  );
}
