import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { StreamerCorpus } from "@lib/types/generic";
import { useCallback } from "react";
import updateStreamerCorpus, { UpdateStreanerCorpus } from "../updateStreamerCorpus";

const useUpdateStreamerCorpus = (streamerCorpus?: UpdateStreanerCorpus) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<StreamerCorpus | null>(
    null,
  );

  const send = useCallback(async () => {
    if (streamerCorpus == null) return reqState;
    setLoading();
    try {
      const newPlatform = await updateStreamerCorpus(streamerCorpus);
      return setSuccess(newPlatform);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [streamerCorpus, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateStreamerCorpus;
