import DataCheck from "@components/common/DataCheck";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { HomeIcon } from "@heroicons/react/solid";
import React, { Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";

interface BreadCrumb {
  title: string;
  to?: string;
}

interface Props {
  title?: string;
  buttons?: ReactNode;
  breadcrumbs?: BreadCrumb[];
  isLoading?: boolean;
  error?: string | null;
}

export type HeaderProps = Props;

export default function Header(props: Props) {
  const { title, buttons, breadcrumbs = [], isLoading, error } = props;

  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

  if (!title) return null;

  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div>
          <nav className="sm:hidden" aria-label="Back">
            <Link
              to={lastBreadcrumb?.to ?? "/"}
              className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon
                className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {lastBreadcrumb ? "Back" : "Home"}
            </Link>
          </nav>
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <Link to="/">
                    <HomeIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-500 hover:text-gray-700"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
              </li>
              {[...breadcrumbs, { title }].map(({ title, to }, i) => (
                <Fragment key={i}>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />

                      {to ? (
                        <Link
                          to={to}
                          className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                          {title}
                        </Link>
                      ) : (
                        <span className="ml-4 text-sm font-medium text-gray-500">{title}</span>
                      )}
                    </div>
                  </li>
                </Fragment>
              ))}
            </ol>
          </nav>
        </div>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {title}
            </h2>
          </div>
          <div className="relative mt-4 flex-shrink-0 flex md:mt-0 md:ml-4 gap-3">
            <DataCheck isLoading={isLoading} error={error}>
              {buttons}
            </DataCheck>
          </div>
        </div>
      </div>
    </header>
  );
}
