import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createBanner, { CreateBannerRes } from "../createBanner";

const useCreateBanner = (title?: string, content?: string, cigID?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CreateBannerRes | null>(
    null,
  );

  const send = useCallback(
    async (imageURL?: string, imageURLXS?: string, priority?: number) => {
      if (
        title == null ||
        content == null ||
        imageURL == null ||
        imageURLXS == null ||
        priority == null
      )
        return reqState;
      setLoading();
      try {
        const createRes = await createBanner(title, content, imageURL, imageURLXS, cigID, priority);
        return setSuccess(createRes);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [title, content, cigID, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useCreateBanner;
