import { SortDirection } from "@lib/enums/generic";
import useQuery from "./useQuery";

const useSortFromQuery = () => {
  const sortKey = useQuery("sortKey");
  const sortDirection = useQuery<SortDirection>("sortDirection");

  return { sortKey, sortDirection };
};

export default useSortFromQuery;
