import { ReactNode } from "react";

export enum FormStage {
  Idle = "IDLE",
  PreHandle = "PRE_HANDLE",
  PostHandle = "POST_HANDLE",
  Finished = "FINISHED",
}

export interface FormStep {
  children?: ReactNode;
  handle?: FormHandle;
}

export interface FormValidation {
  key: string;
  failed?: boolean;
  list: {
    text: string;
    type: "success" | "error";
  }[];
}

export type FormHandle = () => Promise<boolean>;
