import { CompatibilityConfig } from "@api/private/update/updateGameCompatibility";
import Button from "@components/common/Button";
import DataCheck from "@components/common/DataCheck";
import Divider from "@components/common/Divider";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import FormText from "@components/common/form/FormText";
import FormTitle from "@components/common/form/FormTitle";
import { OnFormBlur, OnFormChange } from "@components/common/form/hooks/useFormManager";
import Select from "@components/common/form/Select";
import { XCircleIcon } from "@heroicons/react/outline";
import { FormValidation } from "@lib/types/form";
import React, { Fragment } from "react";
import usePlatformMatrix from "../../platforms/platform/hooks/usePlatformMatrix";

interface Props {
  name: string;
  value: CompatibilityConfig[];
  validation?: FormValidation;
  onChange: OnFormChange;
  onBlur: OnFormBlur;
}

export default function PlatformCompatibilityConfigurator(props: Props) {
  const { name, value: matrix, validation, onChange, onBlur } = props;

  const {
    isLoading: platformsIsLoading,
    error: platformsError,

    platformsAsObject,
    platformOptions,
    addedPlatformOptions,
    unaddedPlatformOptions,
    colors,

    addPlatform,
    removePlatform,

    addSubPlatform,
    removeSubPlatform,

    removeAllPlatforms,
    addAllPlatforms,

    addAllSubPlatforms,
    removeAllSubPlatforms,
  } = usePlatformMatrix(name, matrix, onChange);

  return (
    <FormRows>
      <FormTitle>Platforms</FormTitle>

      <DataCheck isLoading={platformsIsLoading} error={platformsError} loadingIndicator="spinner">
        <FormRow className="items-stretch md:items-end flex-col md:flex-row">
          <FormEntry label="Add platforms" validation={validation}>
            <Select
              name={name}
              placeholder="Choose the platforms that this game can be played on"
              multi
              value={matrix.map(({ hostPlatformID }) => hostPlatformID)}
              options={platformOptions}
              onSelect={({ value }) => {
                if (matrix.find(({ hostPlatformID }) => value === hostPlatformID))
                  removePlatform(value);
                else addPlatform(value);
              }}
              onBlur={() => onBlur({ name, value: matrix })}
            />
          </FormEntry>
          <div className="flex gap-4">
            <FormEntry>
              <Button
                className="md:w-fit"
                onClick={addAllPlatforms}
                disabled={unaddedPlatformOptions.length === 0}>
                Add All
              </Button>
            </FormEntry>
            <FormEntry>
              <Button
                className="md:w-fit"
                bgColor="bg-red-500"
                bgHoverColor="hover:bg-red-600"
                onClick={removeAllPlatforms}
                disabled={addedPlatformOptions.length === 0}>
                Remove All
              </Button>
            </FormEntry>
          </div>
        </FormRow>

        <Divider />

        <FormTitle className="mt-6">Compatibility</FormTitle>

        {matrix.length > 0 ? (
          matrix
            .sort((a: CompatibilityConfig, b: CompatibilityConfig) => {
              const platformA = platformsAsObject[a.hostPlatformID];
              const platformB = platformsAsObject[b.hostPlatformID];
              return platformA?.name.localeCompare(platformB?.name);
            })
            .map(({ hostPlatformID, compatiblePlatforms }) => {
              const platform = platformsAsObject[hostPlatformID];
              const color =
                colors[platformOptions.findIndex(option => option.value === hostPlatformID)];

              if (!platform) return null;

              return (
                <Fragment key={hostPlatformID}>
                  <Divider />
                  <FormRow className="items-stretch md:items-end flex-col md:flex-row">
                    <FormEntry label={<span style={{ color }}>{platform.name}</span>}>
                      <div className="flex gap-3 items-center">
                        <div
                          className="w-5 h-5"
                          style={{
                            maskSize: "cover",
                            WebkitMaskSize: "cover",
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskImage: `url(${platform.icon})`,
                            WebkitMaskImage: `url(${platform.icon})`,
                            backgroundColor: color,
                          }}
                        />
                        <Select
                          value={compatiblePlatforms}
                          options={addedPlatformOptions.filter(
                            option => hostPlatformID !== option.value,
                          )}
                          multi
                          className="flex-grow"
                          onSelect={({ value }) => {
                            if (compatiblePlatforms.includes(value))
                              removeSubPlatform(hostPlatformID, value);
                            else addSubPlatform(hostPlatformID, value);
                          }}
                        />
                      </div>
                    </FormEntry>
                    <div className="flex gap-4">
                      <FormEntry>
                        <Button
                          className="md:w-fit"
                          onClick={() => addAllSubPlatforms(hostPlatformID)}
                          disabled={compatiblePlatforms.length >= addedPlatformOptions.length}>
                          Add All
                        </Button>
                      </FormEntry>
                      <FormEntry>
                        <Button
                          className="md:w-fit"
                          bgColor="bg-red-500"
                          bgHoverColor="hover:bg-red-600"
                          onClick={() => removeAllSubPlatforms(hostPlatformID)}
                          disabled={compatiblePlatforms.length === 1}>
                          Remove all
                        </Button>
                      </FormEntry>
                    </div>
                    <FormEntry noGrow>
                      <Button
                        className="md:w-fit"
                        bgColor="bg-gray-700"
                        bgHoverColor="hover:bg-gray-800"
                        onClick={() => removePlatform(hostPlatformID)}>
                        Remove
                      </Button>
                    </FormEntry>
                  </FormRow>
                </Fragment>
              );
            })
        ) : (
          <>
            <Divider />
            <FormRow>
              <FormText className="flex items-center gap-2">
                <XCircleIcon className="w-6 h-6 text-gray-700" />
                No platforms have been added
              </FormText>
            </FormRow>
          </>
        )}
      </DataCheck>
    </FormRows>
  );
}
