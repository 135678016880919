import useImagesLoaded from "@hooks/useImagesLoaded";
import { GlobalContext } from "@src/GlobalContextProvider";
import React, { CSSProperties, PropsWithChildren, ReactNode, useContext, useEffect } from "react";
import Progress, { ProgressProps } from "./Progress";

interface Props extends ProgressProps {
  isLoading?: boolean;
  error?: string | null;
  errorContent?: ReactNode;
  emptyMessage?: string;
  emptyContent?: ReactNode;
  isEmpty?: boolean;
  loadingIndicator?: "spinner" | "global" | "none";
  imgs?: Array<string | undefined>;
  dataCheckStyle?: CSSProperties;
}

export type DataCheckProps = Props;

export default function DataCheck(props: PropsWithChildren<Props>) {
  const {
    error,
    errorContent,
    isEmpty,
    emptyMessage = "No data",
    emptyContent,
    loadingIndicator = "none",
    imgs = [],
    dataCheckStyle,
    children,
    ...progressProps
  } = props;

  const { loaded } = useImagesLoaded(imgs.filter(img => img != null) as string[]);

  const isLoading = props.isLoading || !loaded;

  const { setLoading: setGlobalLoading } = useContext(GlobalContext);

  const className = "absolute inset-0 w-full h-full flex items-center justify-center";

  useEffect(() => {
    if (loadingIndicator === "global") setGlobalLoading(isLoading ?? false);
  }, [isLoading, loadingIndicator, setGlobalLoading]);

  if (isLoading) {
    if (loadingIndicator === "spinner") {
      return (
        <div className={className} style={dataCheckStyle}>
          <Progress {...progressProps} />
        </div>
      );
    } else {
      return <div className={className} style={dataCheckStyle} />;
    }
  }

  if (error)
    return (
      <div className={className} style={dataCheckStyle}>
        <p className="text-red-600 font-semibold">{error}</p>
        {errorContent}
      </div>
    );

  if (isEmpty)
    return (
      <div className={className} style={dataCheckStyle}>
        <p className="text-gray-500 font-semibold">{emptyMessage}</p>
        {emptyContent}
      </div>
    );

  return <>{children}</>;
}
