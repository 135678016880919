import { api } from "@api/api";
import { Game } from "@api/public/get/getGames";

export type UpdateGame = Partial<Omit<Game, "platforms">> & {
  supportedPlatforms: string[];
};

const updateGame = async (game: UpdateGame) => {
  const { data } = await api.post<Game>("/updateGame", game);
  return data;
};

export default updateGame;
