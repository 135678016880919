import useDeleteGame from "@api/private/delete/hooks/useDeleteGame";
import DeleteModal from "@components/common/modals/DeleteModal";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React from "react";

interface Props {
  gameId?: string;
  onClose?: () => void;
  onDelete?: () => void;
}

export default function DeleteGameModal(props: Props) {
  const { gameId, onClose = () => {}, onDelete = () => {} } = props;

  const { pushSnackbar } = useGlobalContext();

  const { send: deleteGame, isLoading, error } = useDeleteGame(gameId);

  const handleDelete = async () => {
    const { error } = await deleteGame();
    if (error) return;
    onDelete();
    onClose();
    pushSnackbar(createSuccessSnackbar("Platform Deleted", undefined, 3000));
  };

  return (
    <DeleteModal
      active={!!gameId}
      isLoading={isLoading}
      error={error}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleDelete}
    />
  );
}
