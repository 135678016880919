import { log } from "@lib/utils/generic";
import { useState } from "react";
import useGlobalContext from "./useGlobalContext";

export type CurrentReqState<D> = {
  isLoading: boolean;
  data: D;
  error?: string;
};

const useRequestState = <D>(defaultValue: D) => {
  const { setMaintenanceMode } = useGlobalContext();

  const createReqState = (isLoading: boolean, data: D, error?: string): CurrentReqState<D> => ({
    isLoading,
    data,
    error,
  });

  const [reqState, setReqState] = useState<CurrentReqState<D>>({
    isLoading: false,
    data: defaultValue,
  });

  const setIdle = () => {
    const newReqState = createReqState(false, defaultValue);
    setReqState(newReqState);
    return newReqState;
  };
  const setLoading = () => {
    const newReqState = createReqState(true, defaultValue);
    setReqState(newReqState);
    return newReqState;
  };
  const setSuccess = (data: D) => {
    const newReqState = createReqState(false, data);
    setReqState(newReqState);
    return newReqState;
  };
  const setError = (error?: string | null) => {
    if (error === "VERSION_MISMATCH") setMaintenanceMode(true);
    log(error);
    const newReqState = createReqState(false, defaultValue, error ?? "Something went wrong");
    setReqState(newReqState);
    return newReqState;
  };

  return { reqState, setIdle, setLoading, setSuccess, setError };
};

export default useRequestState;
