import { Auth, Hub } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types/Auth";
import useGetUserExtension from "@api/private/get/hooks/useGetUserExtension";
import { log } from "@lib/utils/generic";
import { UserExtension } from "@api/private/get/getUserExtension";
import { useLocation, useNavigate } from "react-router-dom";
import { Snackbar } from "@lib/types/generic";
import { createErrorSnackbar } from "@lib/constants/generic";

const useAuth = (pushSnackbar: (snackbar: Snackbar) => void) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [initialLoading, setInitialLoading] = useState(true);
  const [initialError, setInitialError] = useState<string | null>(null);

  const [user, setUser] = useState<any>(null);
  const [userExtension, setUserExtension] = useState<UserExtension | null>(null);

  const [notAdminPromptSent, setNotAdminPromptSent] = useState(false);

  const { send: getUserExtension } = useGetUserExtension();

  const getUser = useCallback(
    async (disableLoading?: boolean) => {
      try {
        if (!disableLoading) setInitialLoading(true);
        setInitialError(null);

        const user = await Auth.currentAuthenticatedUser();
        setUser(user);

        const { data: _userExtension, error: userExtensionError } = await getUserExtension();
        if (_userExtension) setUserExtension(_userExtension);

        if (userExtensionError) throw userExtensionError;

        log("User auth successful", user);
        if (_userExtension) log("User extension", _userExtension);
      } catch (err: any) {
        log("User auth failed", err);
        setInitialError(err);
      }
      if (!disableLoading) setInitialLoading(false);
    },
    [setInitialLoading, getUserExtension, setUserExtension],
  );

  const signIn = useCallback(
    async ({ provider }: { provider: CognitoHostedUIIdentityProvider }) => {
      Auth.federatedSignIn({ provider: provider });
    },
    [],
  );

  const signOut = useCallback(async () => {
    Auth.signOut();
  }, []);

  const handleAuth = useCallback(
    ({ payload: { event } }: any) => {
      switch (event) {
        case "signIn":
          window.location.replace("/");
          break;
        case "cognitoHostedUI":
          getUser();
          break;
        case "signOut":
          setUser(null);
          navigate("/");
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          log("Couldn't login - panic");
          break;
      }
    },
    [getUser, setUser, navigate],
  );

  useEffect(() => {
    Hub.listen("auth", handleAuth);
    return () => Hub.remove("auth", handleAuth);
  }, [handleAuth]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);
  // must omit getUser to prevent loop

  useEffect(() => {
    if (initialLoading) return;
    if (!user && location.pathname !== "/login") navigate("/login");
    if (user && location.pathname === "/login") navigate("/");
  }, [user, initialError, initialLoading, navigate, location]);

  const promptAndLogout = useCallback(async () => {
    setNotAdminPromptSent(true);
    await signOut();
    pushSnackbar(
      createErrorSnackbar("Not an Admin", "You must be an admin to login to the admin panel", 5000),
    );
  }, [signOut, pushSnackbar, setNotAdminPromptSent]);

  useEffect(() => {
    const groups = user?.signInUserSession.idToken.payload["cognito:groups"] ?? [];
    const isAdmin = groups.includes("ADMIN");
    if (user && !isAdmin && !notAdminPromptSent) promptAndLogout();
  }, [user, notAdminPromptSent, promptAndLogout]);

  return {
    user,
    userExtension,
    initialLoading,
    initialError,
    getUser,
    signIn,
    signOut,
    setUser,
    setUserExtension,
  };
};

export default useAuth;
