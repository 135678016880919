import { api, APIResponseArray } from "@api/api";

export interface Platform {
  id: string;
  name: string;
  shortName: string;
  icon: string;
}

const getPlatforms = async () => {
  const { data } = await api.get<APIResponseArray<Platform>>("/platforms");
  return data.items;
};

export default getPlatforms;
