import { FormHandle, FormValidation } from "@lib/types/form";
import { CSSProps } from "@lib/types/generic";
import { PropsWithChildren, useRef } from "react";
import FormErrors from "./FormErrors";
import useFormState from "./hooks/useFormState";

export type FormElement<V = any, N = string | number> = { value: V; name: N; type?: string };

interface Props extends CSSProps {
  resetOnCompletion?: boolean;
  validation?: FormValidation;
  triggerGlobalLoading?: boolean;

  handle: FormHandle;
  resetValidations?: () => void;
  onCompletion?: () => void;
}

export default function Form(props: PropsWithChildren<Props>) {
  const {
    handle,
    onCompletion = () => {},
    resetValidations = () => {},
    resetOnCompletion,
    validation,
    triggerGlobalLoading,
    className = "",
    id,
    style,
    children,
  } = props;

  const { parseAndSubmit } = useFormState(
    handle,
    onCompletion,
    resetValidations,
    resetOnCompletion,
    triggerGlobalLoading,
  );

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <div>
      <form ref={formRef} onSubmit={parseAndSubmit} className={className} id={id} style={style}>
        {children}
      </form>
      <FormErrors validation={validation} />
    </div>
  );
}
