import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getStreamerCorpus, { GetStreamerCorpusRes } from "../getStreamerCorpus";

const useGetStreamerCorpus = (username?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<GetStreamerCorpusRes | null>(null);

  const send = useCallback(async () => {
    if (username == null) return reqState;
    setLoading();

    const { data, error } = await getStreamerCorpus(username);
    if (data) return setSuccess(data);
    if (error) setError(error);
  }, [username, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetStreamerCorpus;
