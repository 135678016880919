import { getMsgFromApiErr } from "@api/api";
import { Platform } from "@api/public/get/getPlatforms";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import deletePlatform from "../deletePlatform";

const useDeletePlatform = (platformId?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Platform | null>(null);

  const send = useCallback(async () => {
    if (platformId == null) return reqState;
    setLoading();
    try {
      const deleteRes = await deletePlatform(platformId);
      return setSuccess(deleteRes);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [platformId, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useDeletePlatform;
