import useUpdateClaimState from "@api/private/update/hooks/useUpdateClaimState";
import Modal from "@components/common/modals/Modal";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import { UpdateClaimState } from "@lib/enums/generic";
import React from "react";

interface Props {
  claimId?: string;
  onClose?: () => void;
  onAccept?: () => void;
}

export default function AcceptClaimModal(props: Props) {
  const { claimId, onClose = () => {}, onAccept = () => {} } = props;

  const { pushSnackbar } = useGlobalContext();

  const {
    send: updateClaimState,
    isLoading,
    error,
  } = useUpdateClaimState(claimId, UpdateClaimState.Approved);

  const handleAccept = async () => {
    const { error } = await updateClaimState();
    if (error) return;
    onAccept();
    onClose();
    pushSnackbar(createSuccessSnackbar("Claim Accepted", undefined, 3000));
  };

  return (
    <Modal
      active={!!claimId}
      isLoading={isLoading}
      error={error}
      title="Accept Claim"
      subTitle="This will accept the claim and issue a refund to the customer."
      confirmButtonProps={{
        bgColor: "bg-green-600",
        bgHoverColor: "hover:bg-green-700",
      }}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleAccept}
    />
  );
}
