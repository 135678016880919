import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const createBannerValidators = {
  title: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").length.lessThanOrEqualTo(50);
  },
  content: (validation: FormValidation, value: any) => {
    new Validator(validation, value).is("string").length.lessThanOrEqualTo(200);
  },
  imageURL: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
  imageURLXS: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string");
  },
};

export default createBannerValidators;
