import { sanity, sanityCall } from "@api/sanity";
import { SanityStreamer } from "@lib/types/generic";

export type GetSanityStreamerRes = SanityStreamer;

const getSanityStreamer = async (username: string) =>
  sanityCall(
    sanity.fetch<SanityStreamer[]>(
      `*[_type == "streamer" && slug == $username]{
      slug,
      twitchHandle,
      twitterHandle,
      games,
      profileImage,
      about
    }`,
      {
        username,
      },
    ),
  );
export default getSanityStreamer;
