import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getPendingClaims, { PendingClaim } from "../getPendingClaims";

const useGetPendingClaims = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<PendingClaim[]>([]);

  const send = useCallback(async () => {
    setLoading();
    try {
      const claims = await getPendingClaims();
      setSuccess(claims);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetPendingClaims;
