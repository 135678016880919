import { Config } from "./config";
import awsExports from "./integration/production.aws-exports";

const config: Config = {
  apiURL: "https://prod.yakkr.co.uk/",
  sanityProjectId: "pfv7thq6",
  sanityDataset: "production",
  s3Buckets: {},
  awsExports,
};

export default config;
