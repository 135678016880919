import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import useGetSubCategories from "@api/public/get/hooks/useGetSubCategories";
import Badge from "@components/common/Badge";
import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FilePicker from "@components/common/form/FilePicker";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import FormText from "@components/common/form/FormText";
import FormTitle from "@components/common/form/FormTitle";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import Select from "@components/common/form/Select";
import Page from "@components/layout/Page";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import useGlobalContext from "@hooks/useGlobalContext";
import {
  cigNames,
  countryNames,
  createSuccessSnackbar,
  mainSiteUrl,
  platformNames,
} from "@lib/constants/generic";
import { SortDirection } from "@lib/enums/generic";
import { getCurrencySymbol, sortMap } from "@lib/utils/generic";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AcceptCIGModal from "./AcceptCIGModal";
import useEditCigProfileHandle from "./hooks/useEditCigProfileHandle";
import RejectCIGModal from "./RejectCIGModal";
import VerifyCIGModal from "./VerifyCIGModal";

const initialValues = {
  cigID: "",

  bannerPictureURL: "",
  profilePictureURL: "",
  subCategory: "",
  tagLine: "" as string | null,

  instagramHandle: "" as string | null,
  tiktokHandle: "" as string | null,
  twitchHandle: "" as string | null,
  twitterHandle: "" as string | null,
  youtubeHandle: "" as string | null,
};

export type EditCIGValues = typeof initialValues;

export default function CIG() {
  const { cigId } = useParams();

  const { userExtension, pushSnackbar } = useGlobalContext();

  const [profileImageFile, setProfileImageFile] = useState<File>();
  const [bannerImageFile, setBannerImageFile] = useState<File>();
  const [selectedVerifyCigId, setSelectedVerifyCigId] = useState<string>();
  const [selectedAcceptCigId, setSelectedAcceptCigId] = useState<string>();
  const [selectedRejectCigId, setSelectedRejectCigId] = useState<string>();

  if (cigId) initialValues.cigID = cigId;

  const formManager = useFormManager(initialValues, "updateCigProfile");

  const { values, validations, resetValidations, onBlur, onChange, setValues } = formManager;

  const [handle, editIsLoading] = useEditCigProfileHandle(
    formManager,
    profileImageFile,
    bannerImageFile,
  );

  const {
    data: subCategories,
    isLoading: subCategoriesIsLoading,
    error: subCategoriesError,
  } = useGetSubCategories(true);

  const subCategoryOptions = Object.entries(subCategories ?? {})
    .map(([key, value]) => ({
      name: value,
      value: key,
    }))
    .sort(sortMap("name", SortDirection.Ascending));

  const {
    send: getCigProfile,
    data: cig,
    isLoading: cigIsLoading,
    error: cigError,
  } = useGetCigProfile(cigId, userExtension?.id, true);

  useEffect(() => {
    if (cig == null) return;

    const {
      bannerPictureURL,
      profilePictureURL,
      subCategory,
      tagLine,

      instagramHandle,
      tiktokHandle,
      twitchHandle,
      twitterHandle,
      youtubeHandle,
    } = cig;

    setValues({
      ...initialValues,
      bannerPictureURL,
      profilePictureURL,
      subCategory,
      tagLine,

      instagramHandle,
      tiktokHandle,
      twitchHandle,
      twitterHandle,
      youtubeHandle,
    });
  }, [cig, setValues]);

  const {
    category,
    country,
    defaultCurrency,
    displayName,

    accepted,
    verified,

    verifiedHandle,
    verifiedPlatformName,
  } = cig ?? {};

  return (
    <Page
      title={cig?.displayName ?? "CIG"}
      isLoading={cigIsLoading}
      error={cigError || subCategoriesError}
      breadcrumbs={[
        {
          title: "CIGs",
          to: "/cigs",
        },
      ]}
      buttons={
        <>
          {!verified && <Button onClick={() => setSelectedVerifyCigId(cigId)}>Verifiy</Button>}
          {verified &&
            (accepted ? (
              <Button
                bgColor="bg-red-600"
                bgHoverColor="hover:bg-red-700"
                onClick={() => setSelectedRejectCigId(cigId)}>
                Reject
              </Button>
            ) : (
              <Button
                bgColor="bg-green-600"
                bgHoverColor="hover:bg-green-700"
                onClick={() => setSelectedAcceptCigId(cigId)}>
                Accept
              </Button>
            ))}
          <Button type="submit" form="edit-cig-profile-form" isLoading={editIsLoading}>
            Save
          </Button>
        </>
      }>
      <Form
        handle={handle}
        validation={validations.generic}
        id="edit-cig-profile-form"
        resetValidations={resetValidations}
        onCompletion={() => pushSnackbar(createSuccessSnackbar("CIG Updated", undefined, 3000))}>
        <Containers>
          <Card basis="basis-1">
            <FormRows gap="gap-12">
              <FormRow>
                <FormEntry label="Verification State" basis="basis-1/2">
                  <Badge className="w-min mt-1" bgColor={verified ? "bg-green-100" : "bg-red-100"}>
                    {verified ? "Verified" : "Unverified"}
                  </Badge>
                </FormEntry>
                <FormEntry label="Acceptance State" basis="basis-1/2">
                  <Badge className="w-min mt-1" bgColor={accepted ? "bg-green-100" : "bg-red-100"}>
                    {accepted ? "Accepted" : "Not Accepted"}
                  </Badge>
                </FormEntry>

                <FormEntry label="Verification Platform" basis="basis-1/2">
                  <FormText>
                    {verifiedPlatformName ? platformNames[verifiedPlatformName] : "N/A"}
                  </FormText>
                </FormEntry>
                <FormEntry label="Verification Handle" basis="basis-1/2">
                  <FormText>{verifiedHandle}</FormText>
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
        </Containers>

        <Containers className="mt-4">
          <Card basis="basis-1/2">
            <FormTitle>Images</FormTitle>
            <FormRows>
              <FormRow>
                <FormEntry
                  label="Banner Image"
                  basis="basis-1/2"
                  validation={validations.bannerPictureURL}>
                  <FilePicker
                    name="bannerPictureURL"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.bannerPictureURL}
                    value={bannerImageFile}
                    onChange={onChange}
                    onFileChange={setBannerImageFile}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry
                  label="Profile Image"
                  basis="basis-1/2"
                  validation={validations.profilePictureURL}>
                  <FilePicker
                    name="profilePictureURL"
                    maxBytes={10485760}
                    whitelistedFileTypes={["jpeg"]}
                    initialUrl={values.profilePictureURL}
                    value={profileImageFile}
                    onChange={onChange}
                    onFileChange={setProfileImageFile}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>

          <Card basis="basis-1/2">
            <FormTitle>Details</FormTitle>
            <FormRows gap="gap-12">
              <FormRow>
                <FormEntry label="Display Name" basis="basis-1/2">
                  <FormText>{displayName}</FormText>
                </FormEntry>
                <FormEntry label="Type" basis="basis-1/2">
                  <FormText>{category ? cigNames[category] : "N/A"}</FormText>
                </FormEntry>
              </FormRow>

              <FormRow>
                <FormEntry label="Country" basis="basis-1/2">
                  <FormText>{country ? countryNames[country] : "N/A"}</FormText>
                </FormEntry>
                <FormEntry label="Currency" basis="basis-1/2">
                  <FormText>{getCurrencySymbol(defaultCurrency)}</FormText>
                </FormEntry>
              </FormRow>

              <FormRow>
                <FormEntry basis="basis-1/2" label="Tag Line" validation={validations.tagLine}>
                  <Input
                    name="tagLine"
                    value={values.tagLine ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry basis="basis-1/2" label="Category" validation={validations.subCategory}>
                  <Select
                    name="subCategory"
                    value={values.subCategory}
                    options={subCategoryOptions}
                    isLoading={subCategoriesIsLoading}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>

              <FormRow>
                <FormEntry
                  label="Instagram Handle"
                  basis="basis-1/3"
                  validation={validations.instagramHandle}>
                  <Input
                    name="instagramHandle"
                    value={values.instagramHandle ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry
                  label="TikTok Handle"
                  basis="basis-1/3"
                  validation={validations.tiktokHandle}>
                  <Input
                    name="tiktokHandle"
                    value={values.tiktokHandle ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry
                  label="Twitch Handle"
                  basis="basis-1/3"
                  validation={validations.twitchHandle}>
                  <Input
                    name="twitchHandle"
                    value={values.twitchHandle ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>

              <FormRow>
                <FormEntry
                  label="Twitter Handle"
                  basis="basis-1/3"
                  validation={validations.twitterHandle}>
                  <Input
                    name="twitterHandle"
                    value={values.twitterHandle ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry
                  label="YouTube Handle"
                  basis="basis-1/3"
                  validation={validations.youtubeHandle}>
                  <Input
                    name="youtubeHandle"
                    value={values.youtubeHandle ?? ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
                <FormEntry basis="basis-1/3"></FormEntry>
              </FormRow>

              <FormRow>
                <FormEntry>
                  <Button href={`${mainSiteUrl}/cig-profile/${cigId}`} openInNewTab>
                    View Profile <ExternalLinkIcon className="w-4 h-4 ml-1" />
                  </Button>
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
        </Containers>
      </Form>

      <VerifyCIGModal
        cigId={selectedVerifyCigId}
        onClose={() => setSelectedVerifyCigId(undefined)}
        onVerify={getCigProfile}
      />

      <AcceptCIGModal
        cigId={selectedAcceptCigId}
        onClose={() => setSelectedAcceptCigId(undefined)}
        onAccept={getCigProfile}
      />

      <RejectCIGModal
        cigId={selectedRejectCigId}
        onClose={() => setSelectedRejectCigId(undefined)}
        onReject={getCigProfile}
      />
    </Page>
  );
}
