import useUpdateClaimState from "@api/private/update/hooks/useUpdateClaimState";
import Modal from "@components/common/modals/Modal";
import { XIcon } from "@heroicons/react/outline";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import { UpdateClaimState } from "@lib/enums/generic";
import React from "react";

interface Props {
  claimId?: string;
  onClose?: () => void;
  onReject?: () => void;
}

export default function RejectClaimModal(props: Props) {
  const { claimId, onClose = () => {}, onReject = () => {} } = props;

  const { pushSnackbar } = useGlobalContext();

  const {
    send: updateClaimState,
    isLoading,
    error,
  } = useUpdateClaimState(claimId, UpdateClaimState.Rejected);

  const handleReject = async () => {
    const { error } = await updateClaimState();
    if (error) return;
    onReject();
    onClose();
    pushSnackbar(createSuccessSnackbar("Claim Rejected", undefined, 3000));
  };

  return (
    <Modal
      active={!!claimId}
      isLoading={isLoading}
      error={error}
      title="Reject Claim"
      subTitle="This will close the claim and not issue a refund to the customer."
      confirmButtonText="Reject"
      confirmButtonProps={{
        bgColor: "bg-red-600",
        bgHoverColor: "hover:bg-red-700",
      }}
      icon={<XIcon />}
      iconBgColor="bg-red-100"
      iconColor="text-red-600"
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleReject}
    />
  );
}
