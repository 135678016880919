import config from "@config/config";
import { Amplify, Auth } from "aws-amplify";

export const configureAmplify = () => {
  Amplify.configure(config.awsExports);
};

export const configureAuth = () => {
  let storage = null;

  if (Object.keys(sessionStorage).find(key => key.includes("Cognito"))) {
    storage = sessionStorage;
  } else if (Object.keys(localStorage).find(key => key.includes("Cognito"))) {
    storage = localStorage;
  }

  if (storage) {
    Auth.configure({
      storage,
    });
  }
};
