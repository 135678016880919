import {
  HomeSectionCigs,
  HomeSectionData,
  HomeSectionCigsCig,
  HomeSectionType,
} from "@api/public/get/getCigListings";
import useGetHome from "@api/public/get/hooks/useGetHome";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import React, { Dispatch, SetStateAction, useState } from "react";
import DemoteFeaturedModal from "./DemoteFeaturedModal";
import PromoteFeaturedModal from "./PromoteFeaturedModal";

const isCigs = (set: HomeSectionData): set is HomeSectionCigs => set.type === HomeSectionType.Cigs;

export default function Homepage() {
  const [promoteCigId, setPromoteCigId] = useState<string>();
  const [demoteCigId, setDemoteCigId] = useState<string>();

  const {
    send: getHome,
    data: homeData,
    isLoading: homeIsLoading,
    error: homeError,
  } = useGetHome(undefined, true);

  const cigs = homeData.reduce((acc, set) => {
    if (!isCigs(set)) return acc;
    for (const cig of set.cigs ?? []) {
      if (!acc.find(({ id }) => id === cig.id)) acc.push(cig);
    }
    return acc;
  }, [] as HomeSectionCigsCig[]);

  const featuredCigs = cigs.filter(({ featured }) => featured);
  const otherCigs = cigs.filter(({ featured }) => !featured);

  const sharedProps = { setPromoteCigId, setDemoteCigId };

  return (
    <Page title="Homepage" isLoading={homeIsLoading} error={homeError}>
      {featuredCigs && <ListingTable cigs={featuredCigs} isFeatured {...sharedProps} />}
      <ListingTable cigs={otherCigs} {...sharedProps} />
      <PromoteFeaturedModal
        cigId={promoteCigId}
        onClose={() => setPromoteCigId(undefined)}
        onPromote={getHome}
      />

      <DemoteFeaturedModal
        cigId={demoteCigId}
        onClose={() => setDemoteCigId(undefined)}
        onDemote={getHome}
      />
    </Page>
  );
}

interface ListingTablesProps {
  cigs: HomeSectionCigsCig[];
  isFeatured?: boolean;

  setPromoteCigId: Dispatch<SetStateAction<string | undefined>>;
  setDemoteCigId: Dispatch<SetStateAction<string | undefined>>;
}

const ListingTable = (props: ListingTablesProps) => {
  const { cigs, isFeatured, setPromoteCigId, setDemoteCigId } = props;

  return (
    <div className="mb-8">
      <h2 className="mt-2 ml-2 text-gray-700 font-semibold">
        {isFeatured ? "Featured" : "Not Featured"}
      </h2>
      <Table
        headers={[
          { key: "displayName", name: "Display Name", sortable: true },
          { key: "category", name: "Type", sortable: true },
          { name: "" },
        ]}>
        {cigs?.map(listing => {
          const { id, displayName, category } = listing;
          return (
            <TableRow key={id}>
              <TableCell>{displayName}</TableCell>
              <TableCell>{category}</TableCell>
              <TableCell>
                <ButtonRow className="justify-end">
                  {isFeatured ? (
                    <Button
                      bgColor="bg-red-600"
                      bgHoverColor="hover:bg-red-700"
                      onClick={() => setDemoteCigId(id)}>
                      Demote from Featured
                    </Button>
                  ) : (
                    <Button
                      bgColor="bg-green-600"
                      bgHoverColor="hover:bg-green-700"
                      onClick={() => setPromoteCigId(id)}>
                      Promote to Featured
                    </Button>
                  )}
                </ButtonRow>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </div>
  );
};
