import { getMsgFromApiErr } from "@api/api";
import { CIGProfile } from "@api/public/get/getCigProfile";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updateCIGProfile, { UpdateCIGProfile } from "../updateCIGProfile";

const useUpdateCigProfile = (cigProfile?: UpdateCIGProfile) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CIGProfile | null>(null);

  const send = useCallback(
    async (overrideCigProfile?: UpdateCIGProfile) => {
      const _cigProfile = overrideCigProfile ?? cigProfile;

      if (_cigProfile == null) return reqState;
      setLoading();
      try {
        const newCigProfile = await updateCIGProfile(_cigProfile);
        return setSuccess(newCigProfile);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [cigProfile, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateCigProfile;
