import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useUpdateCigProfile from "@api/private/update/hooks/useUpdateCigProfile";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { EditCIGValues } from "../CIG";

const useEditCigProfileHandle: UseFormHandle<EditCIGValues> = (
  { validateFields, validateCustom, setValues, values },
  profileImageFile: File,
  bannerImageFile: File,
) => {
  const { send: updateCigProfile, isLoading: updateIsLoading } = useUpdateCigProfile(values);
  const { send: uploadToS3, isLoading: uploadToS3IsLoading } = useUploadToS3();

  const handle = async () => {
    let failed = validateFields([
      "cigID",
      "bannerPictureURL",
      "profilePictureURL",
      "subCategory",
      "tagLine",
      "tiktokHandle",
      "twitchHandle",
      "twitterHandle",
      "youtubeHandle",
      "instagramHandle",
    ]);

    const newValues = { ...values };

    if (!failed) {
      const { data: profileUrl, error: profileError } = await uploadToS3(profileImageFile);
      const { data: bannerUrl, error: bannerError } = await uploadToS3(bannerImageFile);

      const error = profileError ?? bannerError;

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (profileUrl) newValues.profilePictureURL = profileUrl;
      if (bannerUrl) newValues.bannerPictureURL = bannerUrl;

      setValues(newValues);
    }

    if (!failed) {
      const { error } = await updateCigProfile(newValues);

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, updateIsLoading || uploadToS3IsLoading];
};

export default useEditCigProfileHandle;
