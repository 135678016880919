import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getSubCategories, { SubCategories } from "../getSubCategories";

const useGetSubCategories = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<SubCategories>({});

  const send = useCallback(async () => {
    setLoading();
    try {
      const subCategories = await getSubCategories();
      setSuccess(subCategories);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetSubCategories;
