import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useCreateGame from "@api/private/create/hooks/useCreateGame";
import useUpdateGameCompatibility from "@api/private/update/hooks/useUpdateGameCompatibility";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { useNavigate } from "react-router-dom";
import { CreateGameValues } from "../CreateGame";

const useCreateGameHandle: UseFormHandle<CreateGameValues> = (
  { validateFields, validateCustom, setValues, values },
  primaryImageFile: File,
  secondaryImageFile: File,
  tertiaryImageFile: File,
) => {
  const navigate = useNavigate();

  const { send: createGame, isLoading: updateIsLoading } = useCreateGame(
    values.shortName,
    values.longName,
    values.description,
    values.category,
    values.platformCompatibility.map(({ hostPlatformID }) => hostPlatformID),
    values.statisticName,
    values.statisticValue,
    values.estimatedMaxLengthMins,
  );
  const { send: updateGameCompatibility, isLoading: compatibilityIsLoading } =
    useUpdateGameCompatibility();
  const { send: uploadToS3, isLoading: uploadToS3IsLoading } = useUploadToS3();

  const handle = async () => {
    let failed = validateFields([
      "longName",
      "category",
      "description",
      "primaryImageURL",
      "secondaryImageURL",
      "tertiaryImageURL",
      "shortName",
      "platformCompatibility",
      "statisticName",
      "statisticValue",
    ]);

    let newValues = { ...values };

    if (!failed) {
      const { data: primaryUrl, error: primaryError } = await uploadToS3(primaryImageFile);
      const { data: secondaryUrl, error: secondaryError } = await uploadToS3(secondaryImageFile);
      const { data: tertiaryUrl, error: tertiaryError } = await uploadToS3(tertiaryImageFile);

      const error = primaryError ?? secondaryError ?? tertiaryError;

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (primaryUrl) newValues.primaryImageURL = primaryUrl;
      if (secondaryUrl) newValues.secondaryImageURL = secondaryUrl;
      if (tertiaryUrl) newValues.tertiaryImageURL = tertiaryUrl;

      setValues(newValues);
    }

    if (!failed) {
      const { error, data } = await createGame(
        newValues.primaryImageURL,
        newValues.secondaryImageURL,
        newValues.tertiaryImageURL,
      );

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      const { id } = data ?? {};

      if (!error && id) {
        const { error } = await updateGameCompatibility(values.platformCompatibility, id);

        if (error) {
          failed = validateCustom("platformCompatibility", validation => {
            validation.list.push({
              type: "error",
              text: error,
            });
          });
        }
      }

      if (!error) if (!error) navigate(`/games/${data?.id}`);
    }

    return failed;
  };

  return [handle, updateIsLoading || uploadToS3IsLoading || compatibilityIsLoading];
};

export default useCreateGameHandle;
