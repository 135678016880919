import { api } from "@api/api";
import { Platform } from "@api/public/get/getPlatforms";

export type UpdatePlatform = Partial<Platform>;

const updatePlatform = async (game: UpdatePlatform) => {
  const { data } = await api.post<Platform>("/updatePlatform", game);
  return data;
};

export default updatePlatform;
