import useUpdateBanner from "@api/private/update/hooks/useUpdateBanner";
import { BannerDetails } from "@api/public/get/getBanners";
import { bannerTypeNames } from "@lib/constants/generic";
import { SortDirection } from "@lib/enums/generic";
import { discernBannerType, sortMap } from "@lib/utils/generic";
import { useCallback, useEffect, useState } from "react";

const useBannersOrderConfigurator = (banners: BannerDetails[]) => {
  const [ordering, setOrdering] = useState(false);
  const [orderedBanners, setOrderedBanners] = useState<Array<BannerDetails & { type: string }>>([]);

  const { send: updateBanner, isLoading, error } = useUpdateBanner();

  const processAndSetBanners = useCallback(
    (banners: BannerDetails[]) => {
      const processedBanners = banners
        .map(banner => ({
          ...banner,
          type: bannerTypeNames[discernBannerType(banner)],
        }))
        .sort(sortMap("priority", SortDirection.Ascending));

      setOrderedBanners(processedBanners);
    },
    [setOrderedBanners],
  );

  const updateOrder = useCallback(async () => {
    for (const [i, banner] of orderedBanners.entries()) {
      const { id } = banner ?? {};
      await updateBanner({ bannerID: id, priority: i });
    }
    setOrdering(false);
  }, [orderedBanners, setOrdering, updateBanner]);

  const switchPlaces = useCallback(
    (index: number, targetIndex: number) => {
      const newBanners = [...orderedBanners];

      if (newBanners[targetIndex] == null) return;

      newBanners[index].priority = targetIndex;
      newBanners[targetIndex].priority = index;

      processAndSetBanners(newBanners);
    },
    [orderedBanners, processAndSetBanners],
  );

  const moveUp = useCallback(
    (index: number) => {
      switchPlaces(index, index - 1);
    },
    [switchPlaces],
  );

  const moveDown = useCallback(
    (index: number) => {
      switchPlaces(index, index + 1);
    },
    [switchPlaces],
  );

  const moveTop = useCallback(
    (index: number) => {
      switchPlaces(index, 0);
    },
    [switchPlaces],
  );

  const moveBottom = useCallback(
    (index: number) => {
      switchPlaces(index, orderedBanners.length - 1);
    },
    [switchPlaces, orderedBanners.length],
  );

  useEffect(
    () => {
      processAndSetBanners(banners);
    },
    // eslint-disable-next-line
    [banners.length, processAndSetBanners],
  );
  // must omit banners to prevent loop

  return {
    ordering,
    orderedBanners,
    isLoading,
    error,
    setOrdering,
    moveUp,
    moveDown,
    moveTop,
    moveBottom,
    updateOrder,
  };
};

export default useBannersOrderConfigurator;
