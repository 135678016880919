import useGetPendingClaims from "@api/private/get/hooks/useGetPendingClaims";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import dayjs from "dayjs";
import React, { useState } from "react";
import AcceptClaimModal from "./AcceptClaimModal";
import RejectClaimModal from "./RejectClaimModal";

export default function Claims() {
  const {
    send: getPendingClaims,
    data: pendingClaims,
    isLoading,
    error,
  } = useGetPendingClaims(true);

  const [selectedAcceptClaimId, setSelectedAcceptClaimId] = useState<string>();
  const [selectedRejectClaimId, setSelectedRejectClaimId] = useState<string>();

  const { sortedData: sortedPendingClaims, ...tableSort } = useTableSort(pendingClaims);

  return (
    <Page title="Claims">
      <Table
        headers={[
          { key: "sessionInfo.cig.displayName", name: "Session", sortable: true },
          { key: "createdAt", name: "Created at", sortable: true },
          { name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        {...tableSort}>
        {sortedPendingClaims.map(({ id, createdAt, sessionInfo }) => (
          <TableRow key={id}>
            <TableCell>
              {sessionInfo?.cig?.displayName ?? "Cancelled Session"} -{" "}
              {dayjs(sessionInfo?.from).format("DD MMM YY | HH:mm")}
            </TableCell>
            <TableCell>{dayjs(createdAt).format("DD MMM YY HH:mm")}</TableCell>
            <TableCell>
              <ButtonRow className="justify-end">
                <Button
                  bgColor="bg-green-600"
                  bgHoverColor="hover:bg-green-700"
                  onClick={() => setSelectedAcceptClaimId(id)}>
                  Accept
                </Button>
                <Button
                  bgColor="bg-red-600"
                  bgHoverColor="hover:bg-red-700"
                  onClick={() => setSelectedRejectClaimId(id)}>
                  Reject
                </Button>
                <Button
                  bgColor="bg-violet-600"
                  bgHoverColor="hover:bg-violet-700"
                  to={`/claims/${id}`}>
                  View
                </Button>
              </ButtonRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      <AcceptClaimModal
        claimId={selectedAcceptClaimId}
        onAccept={getPendingClaims}
        onClose={() => setSelectedAcceptClaimId(undefined)}
      />
      <RejectClaimModal
        claimId={selectedRejectClaimId}
        onReject={getPendingClaims}
        onClose={() => setSelectedRejectClaimId(undefined)}
      />
    </Page>
  );
}
