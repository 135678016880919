import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";

interface Props extends CSSProps {
  src?: string;
  alt?: string;
  size?: string;
}

const Avatar = (props: Props) => {
  const { src, alt, size = "w-10 h-10", ...cssProps } = props;

  if (!src && alt) {
    const firstWord = alt.split(" ")[0];
    const secondWord = alt.split(" ")[1];

    return (
      <span
        {...cssProps}
        className={classNames(
          "inline-block items-center justify-center rounded-full bg-gray-500",
          size,
          cssProps.className,
        )}>
        <span className="text-xs font-medium leading-none text-white">
          {firstWord && firstWord[0].toUpperCase()}
          {secondWord && secondWord[0].toUpperCase()}
        </span>
      </span>
    );
  }

  return (
    <span
      {...cssProps}
      className={classNames(
        "relative inline-block rounded-full bg-cover bg-no-repeat bg-center",
        size,
        props.className,
      )}
      style={{
        backgroundImage: `url(${src})`,
        ...cssProps.style,
      }}
    />
  );
};

export default Avatar;
