import { api } from "@api/api";

export interface AcceptCIGRes {}

const acceptCig = async (cigID: string, accept: boolean) => {
  const { data } = await api.post<AcceptCIGRes>("/adminAcceptsCIG", {
    cigID,
    accept,
  });
  return data;
};

export default acceptCig;
