import useGetStreamerCorpuses from "@api/private/get/hooks/useGetStreamerCorpuses";
import { urlFor } from "@api/sanity";
import useGetSanityStreamers from "@api/sanity/get/hooks/useGetSanityStreamers";
import Avatar from "@components/common/Avatar";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import { SortDirection } from "@lib/enums/generic";
import { mergeStreamers } from "@lib/utils/generic";

const SeoStreamers = () => {
  const {
    data: sanityStreamers,
    isLoading: sanityStreamersIsLoading,
    error: sanityStreamersError,
  } = useGetSanityStreamers(true);
  const {
    data: streamerCorpuses,
    isLoading: streamerCorpusesIsLoading,
    error: streamerCorpusesError,
  } = useGetStreamerCorpuses(true);

  const mergedStreamers = mergeStreamers(sanityStreamers, streamerCorpuses);

  const { sortedData: sortedMergedStreamers, ...tableSort } = useTableSort(
    mergedStreamers.map(streamer => ({
      ...streamer,
      corpusState: streamer.talentDisplayName ? "Complete" : "Incomplete",
    })),
    "corpusState",
    SortDirection.Descending,
  );

  const isLoading = streamerCorpusesIsLoading || sanityStreamersIsLoading;
  const error = streamerCorpusesError ?? sanityStreamersError;

  return (
    <Page title="SEO Streamers">
      <Table
        headers={[
          { key: "profileImage", name: "Profile Image", sortable: false },
          { key: "slug", name: "Slug", sortable: true },
          { key: "twitchHandle", name: "Twitch Handle", sortable: true },
          { key: "twitterHandle", name: "Twitter Handle", sortable: true },
          { key: "corpusState", name: "Corpus State", sortable: true },
          { name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        {...tableSort}>
        {sortedMergedStreamers.map(
          ({ slug, twitchHandle, twitterHandle, profileImage, talentDisplayName, corpusState }) => (
            <TableRow key={slug}>
              <TableCell>
                <Avatar src={urlFor(profileImage).url()} alt={twitchHandle} />
              </TableCell>
              <TableCell>{slug}</TableCell>
              <TableCell>{twitchHandle}</TableCell>
              <TableCell>{twitterHandle}</TableCell>
              <TableCell>
                <span className={talentDisplayName ? "text-green-600" : "text-red-600"}>
                  {corpusState}
                </span>
              </TableCell>
              <TableCell>
                <ButtonRow className="justify-end">
                  <Button
                    bgColor="bg-violet-600"
                    bgHoverColor="hover:bg-violet-700"
                    to={`/seo/streamers/${slug}`}>
                    View
                  </Button>
                </ButtonRow>
              </TableCell>
            </TableRow>
          ),
        )}
      </Table>
    </Page>
  );
};

export default SeoStreamers;
