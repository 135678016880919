import { CIG } from "@lib/enums/generic";
import { api } from "@api/api";

export interface CIGProfilePartial {
  id: string;
  displayName: string;
  category: CIG;
  profilePictureURL: string;
  verified: boolean;
  accepted: boolean;
}

const getUnverifiedCigs = async () => {
  const { data } = await api.post<Array<CIGProfilePartial>>("/adminGetUnverifiedCIGS");
  return data;
};

export default getUnverifiedCigs;
