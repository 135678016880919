import { api } from "@api/api";

export interface UserExtension {
  id: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  displayName: string;
  cigID: string | null;
  stripeRequiresInformationUpdate: boolean | null;
  stripeOnboardingPending: boolean | null;
}

const getUserExtension = async () => {
  const { data } = await api.post<UserExtension>("/getUserExtension");
  return data;
};

export default getUserExtension;
