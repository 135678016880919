import useAcceptCig from "@api/private/update/hooks/useAcceptCig";
import useGetCigProfile from "@api/public/get/hooks/useGetCigProfile";
import Modal from "@components/common/modals/Modal";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React from "react";

interface Props {
  cigId?: string;
  onClose?: () => void;
  onAccept?: () => void;
}

export default function AcceptCIGModal(props: Props) {
  const { cigId, onClose = () => {}, onAccept = () => {} } = props;

  const { userExtension, pushSnackbar } = useGlobalContext();

  const {
    data: cigProfile,
    isLoading: cigProfileIsLoading,
    error: cigProfileError,
  } = useGetCigProfile(cigId, userExtension?.id, true);

  const { displayName } = cigProfile ?? {};

  const {
    send: deleteGame,
    isLoading: acceptIsLoading,
    error: acceptError,
  } = useAcceptCig(cigId, true);

  const handleAccept = async () => {
    const { error } = await deleteGame();
    if (error) return;
    onAccept();
    onClose();
    pushSnackbar(createSuccessSnackbar(`${cigProfile?.displayName} Accepted`, undefined, 3000));
  };

  return (
    <Modal
      active={!!cigId}
      isLoading={acceptIsLoading}
      error={acceptError}
      title={`Accept ${displayName}?`}
      subTitle="This will accept them onto the platform and allow them to begin creating sessions."
      confirmButtonText="Accept"
      confirmButtonProps={{
        bgColor: "bg-green-600",
        bgHoverColor: "hover:bg-green-700",
      }}
      dataCheckProps={{
        isLoading: cigProfileIsLoading,
        error: cigProfileError,
      }}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleAccept}
    />
  );
}
