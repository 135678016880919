import { api, APIResponseData } from "@api/api";
import { Session } from "@lib/types/session";
import { Game } from "./getGames";

export enum HomeSectionType {
  Cigs = "CIGS",
  Games = "GAMES",
  BuildAndStartingSoon = "BUILD_AND_STARTING_SOON",
  FeaturedSession = "FEATURED_SESSION",
  FeaturedCig = "FEATURED_CIG",
  Cta = "CTA",
}

export interface HomeSectionCigs {
  type: HomeSectionType.Cigs;
  title?: string;
  subTitle?: string;
  cigs?: HomeSectionCigsCig[];
}

export interface HomeSectionCigsCig {
  id: string;

  displayName: string;
  tagLine: string;
  category: string;
  subCategory: string;
  country: string;

  profilePictureURL: string | null;

  featured: boolean;

  availability: { items: Session[] };
}

export interface HomeSectionGames {
  type: HomeSectionType.Games;
  title?: string;
  subTitle?: string;
  games?: { items: Game[] };
}

export interface HomeSectionBuildAndStartingSoon {
  type: HomeSectionType.BuildAndStartingSoon;
  sessions?: Session[];
}

export interface HomeSectionFeaturedSession {
  type: HomeSectionType.FeaturedSession;
  session?: Session;
}

export interface HomeSectionFeaturedCig {
  type: HomeSectionType.FeaturedCig;
  cig?: {
    id: string;

    displayName: string;
    tagLine: string;
    category: string;
    subCategory: string;
    country: string;

    profilePictureURL: string | null;

    featured: boolean;

    availability: { items: Session[] };
  };
  games?: Game[];
}

export interface HomeSectionCta {
  type: HomeSectionType.Cta;
  // The text could by dynamic, but probs not necesarry
}

export type HomeSectionData =
  | HomeSectionCigs
  | HomeSectionGames
  | HomeSectionBuildAndStartingSoon
  | HomeSectionFeaturedSession
  | HomeSectionFeaturedCig
  | HomeSectionCta;

const getHome = async (userID?: string) => {
  const { data } = await api.get<APIResponseData<HomeSectionData>>("/home", { params: { userID } });
  return data.data;
};

export default getHome;
