import { Config } from "./config";
import awsExports from "@config/integration/development.aws-exports";

const config: Config = {
  apiURL: "https://api.yakkr.co.uk/",
  sanityProjectId: "pfv7thq6",
  sanityDataset: "staging",
  s3Buckets: {},
  awsExports,
};

export default config;
