import { getMsgFromApiErr } from "@api/api";
import { Platform } from "@api/public/get/getPlatforms";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updatePlatform, { UpdatePlatform } from "../updatePlatform";

const useUpdatePlatform = (platform?: UpdatePlatform) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Platform | null>(null);

  const send = useCallback(
    async (platformOverride?: UpdatePlatform) => {
      const _platform = platformOverride ?? platform;

      if (_platform == null) return reqState;
      setLoading();
      try {
        const newPlatform = await updatePlatform(_platform);
        return setSuccess(newPlatform);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [platform, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUpdatePlatform;
