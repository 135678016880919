import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import getUserExtension, { UserExtension } from "../getUserExtension";

const useGetUserExtension = () => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<UserExtension | null>(
    null,
  );

  const send = useCallback(async () => {
    setLoading();
    try {
      const userExtension = await getUserExtension();
      return setSuccess(userExtension);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetUserExtension;
