import useGlobalContext from "@hooks/useGlobalContext";
import { FormStage } from "@lib/types/form";
import { FormEventHandler, useCallback, useEffect, useState } from "react";

const useFormState = (
  handle: () => Promise<boolean>,
  onCompletion: () => void = () => {},
  resetValidations: () => void = () => {},
  resetOnCompletion?: boolean,
  triggerGlobalLoading?: boolean,
) => {
  const { setLoading } = useGlobalContext();

  const [stage, setStage] = useState(FormStage.Idle);

  const reset = () => setStage(FormStage.Idle);

  const parseAndSubmit = useCallback<FormEventHandler>(
    async event => {
      event.preventDefault();
      event.stopPropagation();

      resetValidations();

      if (stage === FormStage.PreHandle) return;

      setStage(FormStage.PreHandle);

      const failed = await handle();

      setStage(FormStage.PostHandle);

      if (failed) return;

      setStage(FormStage.Finished);

      onCompletion();
      if (resetOnCompletion) reset();
    },
    [handle, setStage, onCompletion, resetValidations, resetOnCompletion, stage],
  );

  useEffect(() => {
    if (triggerGlobalLoading) setLoading(stage === FormStage.PreHandle);
  }, [setLoading, triggerGlobalLoading, stage]);

  return {
    stage,
    parseAndSubmit,
    reset,
  };
};

export default useFormState;
