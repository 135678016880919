import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon, XIcon } from "@heroicons/react/outline";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, {
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  MouseEvent,
  ReactNode,
} from "react";
import Progress from "../Progress";
import { FormElement } from "./Form";

type Option = { value: string | number; name: ReactNode };

const emptyOption: Option = { name: "None", value: "none" };

interface SharedProps extends CSSProps {
  name?: string;
  options?: Option[];
  useEmptyOption?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  error?: boolean;

  borderColor?: string;
  borderFocusColor?: string;
  ringFocusColor?: string;

  onChange?: (target: FormElement) => void;
  onSelect?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
}

interface SingleProps extends SharedProps {
  multi?: false;
  value?: string | number;
}

interface MultiProps extends SharedProps {
  multi: true;
  value?: Array<string | number>;
}

type Props = SingleProps | MultiProps;

export type SelectProps = Props;

const isMulti = (props: any): props is MultiProps => props.multi;
const isSingle = (props: any): props is SingleProps => !props.multi;

export default function Select(props: Props) {
  const {
    name = "",
    options: inputOptions = [],
    value,
    useEmptyOption = false,
    disabled: inputDisabled,
    isLoading,
    error,
    multi,

    borderColor = "border-gray-300",
    borderFocusColor = "focus:border-indigo-500",
    ringFocusColor = "focus:ring-indigo-500",

    onChange = () => {},
    onSelect = () => {},
    onBlur = () => {},

    className = "",
    id,
    style,
  } = props;

  let options = useEmptyOption ? [...inputOptions, emptyOption] : inputOptions;

  const disabled = inputDisabled || options.length === 0;

  return (
    <Listbox value={value} onChange={value => onChange({ name, value })} multiple={multi}>
      {({ open }) => (
        <>
          <div className="flex-grow mt-1 relative">
            <Listbox.Button
              className={classNames(
                "bg-white relative w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm",
                borderColor,
                borderFocusColor,
                ringFocusColor,
                disabled ? "pointer-events-none" : "",
                className,
                error
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                  : "",
              )}
              id={id}
              style={style}
              onBlur={() => onBlur({ name, value })}>
              <Selected {...props} />
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {isLoading ? (
                  <Progress size="w-5 h-5" />
                ) : (
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                )}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options
                onBlur={() => onBlur({ name, value })}
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map(({ name, value }) => (
                  <Listbox.Option
                    key={value}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9",
                      )
                    }
                    value={value}
                    onClick={() => onSelect({ name: props.name ?? "", value })}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate",
                          )}>
                          {Children.map(name, child =>
                            isValidElement(child)
                              ? cloneElement(child as any, {
                                  style: {
                                    ...child.props.style,
                                    color: active ? "white" : child.props.style?.color,
                                  },
                                })
                              : child,
                          )}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

const Selected = (props: Props) => {
  const {
    options = [],
    name = "",
    placeholder = "None",
    onChange = () => {},
    onSelect = () => {},
  } = props;

  if (isSingle(props)) {
    const { value } = props;

    const selected = options.find(option => option.value === value);

    const handleClick = (event: MouseEvent) => {
      event.preventDefault();
      onChange({ name, value: undefined });
    };

    return (
      <div className="flex flex-row items-center gap-1">
        <span className="block truncate">{selected?.name ?? placeholder}</span>
        {value && <XIcon className="w-4 h-4 cursor-pointer text-gray-500" onClick={handleClick} />}
      </div>
    );
  } else if (isMulti(props)) {
    const { value = [] } = props;

    const selected = options.filter(option => value?.includes(option.value));

    const handleChange = (selectedValue: string | number) => {
      const newValue = [...value];
      const index = value.indexOf(selectedValue);
      if (index >= 0) newValue.splice(index, 1);
      else newValue.push(selectedValue);
      onChange({ name, value: newValue });
      onSelect({ name: props.name ?? "", value: selectedValue });
    };

    const handleClick = (value: string | number) => (event: MouseEvent) => {
      event.preventDefault();
      handleChange(value);
    };

    return (
      <span className="flex flex-row gap-4 flex-wrap">
        {selected.length > 0 ? (
          selected.map(({ name, value }, i) => (
            <span key={i} className="flex flex-row items-center gap-1">
              <span className="truncate block">{name}</span>
              <XIcon
                className="w-4 h-4 cursor-pointer text-gray-500"
                onClick={handleClick(value)}
              />
            </span>
          ))
        ) : (
          <span className="block truncate">{placeholder}</span>
        )}
      </span>
    );
  }

  return null;
};
