import useGetGames from "@api/public/get/hooks/useGetGames";
import Badge from "@components/common/Badge";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import Page from "@components/layout/Page";
import React, { useState } from "react";
import DeleteGameModal from "./game/DeleteGameModal";

export default function Games() {
  const { send: getGames, data: games, isLoading, error } = useGetGames(true);

  const [selectedGameId, setSelectedGameId] = useState<string>();

  const { sortedData: sortedGames, ...tableSort } = useTableSort(games);

  return (
    <Page title="Games" buttons={<Button to="/games/create">Create</Button>}>
      <Table
        headers={[
          { key: "longName", name: "Name", sortable: true },
          { key: "category", name: "Category", sortable: true },
          { key: "platforms", name: "Platforms" },
          { name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        {...tableSort}>
        {sortedGames.map(({ id, longName, category, platforms }) => (
          <TableRow key={id}>
            <TableCell>{longName}</TableCell>
            <TableCell>{category}</TableCell>
            <TableCell>
              <div className="flex flex-row gap-2">
                {platforms.items.map(({ platform: { shortName } }, i) => (
                  <Badge key={i}>{shortName}</Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <ButtonRow className="justify-end">
                <Button
                  bgColor="bg-violet-600"
                  bgHoverColor="hover:bg-violet-700"
                  to={`/games/${id}`}>
                  View
                </Button>
                <Button
                  bgColor="bg-red-600"
                  bgHoverColor="hover:bg-red-700"
                  onClick={() => setSelectedGameId(id)}>
                  Delete
                </Button>
              </ButtonRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <DeleteGameModal
        gameId={selectedGameId}
        onDelete={getGames}
        onClose={() => setSelectedGameId(undefined)}
      />
    </Page>
  );
}
