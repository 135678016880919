import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useCreatePlatform from "@api/private/create/hooks/useCreatePlatform";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { useNavigate } from "react-router-dom";
import { CreatePlatformValues } from "../CreatePlatform";

const useCreatePlatformHandle: UseFormHandle<CreatePlatformValues> = (
  { validateFields, validateCustom, setValues, values },
  iconImageFile: File,
) => {
  const navigate = useNavigate();

  const { send: createPlatform, isLoading: updateIsLoading } = useCreatePlatform(
    values.name,
    values.shortName,
  );
  const { send: uploadToS3, isLoading: uploadToS3IsLoading } = useUploadToS3();

  const handle = async () => {
    let failed = validateFields(["name", "shortName", "icon"]);

    let newValues = { ...values };

    if (!failed) {
      const { data: iconUrl, error } = await uploadToS3(iconImageFile);

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (iconUrl) newValues.icon = iconUrl;

      setValues(newValues);
    }

    if (!failed) {
      const { error, data } = await createPlatform(newValues.icon);

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (!error) navigate(`/platforms/${data?.id}`);
    }

    return failed;
  };

  return [handle, updateIsLoading || uploadToS3IsLoading];
};

export default useCreatePlatformHandle;
