import { api } from "@api/api";

export interface VerifyCIGRes {}

const verifyCig = async (cigID: string, verificationCode: string) => {
  const { data } = await api.post<VerifyCIGRes>("/adminVerifiesCIG", {
    cigID,
    verificationCode,
  });
  return data;
};

export default verifyCig;
