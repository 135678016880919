import { api } from "@api/api";

export interface CreateGameRes {
  id: string;
}

const createGame = async (
  shortName: string,
  longName: string,
  primaryImageURL: string,
  secondaryImageURL: string,
  tertiaryImageURL: string,
  description: string,
  category: string,
  supportedPlatforms: string[],
  statisticName: string,
  statisticValue: string,
  estimatedMaxLengthMins: number,
) => {
  const { data } = await api.post<CreateGameRes>("/createGame", {
    shortName,
    longName,
    primaryImageURL,
    secondaryImageURL,
    tertiaryImageURL,
    description,
    category,
    supportedPlatforms,
    statisticName,
    statisticValue,
    estimatedMaxLengthMins,
  });
  return data;
};

export default createGame;
