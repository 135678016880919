import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getPendingCigs, { CIGProfilePartial } from "../getPendingCigs";

const useGetPendingCigs = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CIGProfilePartial[]>([]);

  const send = useCallback(async () => {
    setLoading();
    try {
      const cigs = await getPendingCigs();
      setSuccess(cigs);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetPendingCigs;
