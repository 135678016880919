import { BrowserRouter } from "react-router-dom";

import GlobalContextProvider from "./GlobalContextProvider";
import RootRoutes from "./RootRoutes";

import "./index.css";
import { configureAmplify, configureAuth } from "@lib/utils/aws";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

configureAuth();
configureAmplify();

export default function Root() {
  return (
    <>
      <BrowserRouter basename="/">
        <GlobalContextProvider>
          <RootRoutes />
        </GlobalContextProvider>
      </BrowserRouter>
    </>
  );
}
