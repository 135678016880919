import { api, APIResponseArray } from "@api/api";
import { CIG } from "@lib/enums/generic";

export enum BannerType {
  Standard = "STANDARD",
  CIGLink = "CIG_LINK",
}

export interface BannerDetailsStandard {
  id: string;
  bannerType: BannerType.Standard;
  content: string;
  imageURL: string;
  imageURLXS: string;
  title: string;
  priority?: number;
  cig: null;
}

export interface BannerDetailsCIGLink {
  id: string;
  bannerType: BannerType.CIGLink;
  content: string;
  imageURL: string;
  imageURLXS: string;
  title: string;
  priority?: number;
  cig: {
    displayName: string;
    category: CIG;
    id: string;
    shortDescription: string;
    profilePictureURL: string | null;
  };
}

export type BannerDetails = BannerDetailsStandard | BannerDetailsCIGLink;

const getBanners = async () => {
  const { data } = await api.get<APIResponseArray<BannerDetails>>("/banners");
  return data.items;
};

export default getBanners;
