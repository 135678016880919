import { api } from "@api/api";
import { Game } from "@api/public/get/getGames";

export interface CompatibilityConfig {
  hostPlatformID: string;
  compatiblePlatforms: string[];
}

const updateGameCompatibility = async (compatibility: CompatibilityConfig[], gameID: string) => {
  const { data } = await api.post<Game>("/createOrUpdateGameCompatibility", {
    gameID,
    compatibility,
  });
  return data;
};

export default updateGameCompatibility;
