import { CIG } from "@lib/enums/generic";
import { api, APIResponseArray } from "@api/api";

export interface CIGProfilePartial {
  id: string;
  displayName: string;
  category: CIG;
  bannerPictureURL: string;
}

const getCigs = async () => {
  const { data } = await api.get<APIResponseArray<CIGProfilePartial>>("/cigs");
  return data.items;
};

export default getCigs;
