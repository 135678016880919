import DataCheck from "@components/common/DataCheck";
import React, { PropsWithChildren } from "react";

interface Props {
  isLoading?: boolean;
  error?: string | null;
}

export default function PageFull(props: PropsWithChildren<Props>) {
  const { children, isLoading, error } = props;

  return (
    <main className="relative flex-grow min-h-0 flex flex-col items-center content-center">
      <DataCheck isLoading={isLoading} error={error} loadingIndicator="spinner">
        {children}
      </DataCheck>
    </main>
  );
}
