import { getMsgFromApiErr } from "@api/api";
import getImageUploadUrl from "@api/private/get/getProfilePictureUploadUrl";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import uploadToS3 from "../uploadToS3";

export interface UploadImageRes {
  key: string;
  url: string;
}

const useUploadToS3 = (
  file?: File,
  getPreSignedUrl: () => Promise<UploadImageRes> = getImageUploadUrl,
) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<string | null>(null);

  const send = useCallback(
    async (fileOverride?: File, getPreSignedUrlOverride?: () => Promise<UploadImageRes>) => {
      const _file = fileOverride ?? file;
      const _getPreSignedUrl = getPreSignedUrlOverride ?? getPreSignedUrl;

      if (_file == null || _getPreSignedUrl == null) return reqState;
      try {
        setLoading();

        const { url } = await _getPreSignedUrl();
        await uploadToS3(url, _file);

        return setSuccess(url.split("?")[0]);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [file, getPreSignedUrl, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUploadToS3;
