import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const loginValidators = {
  password: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("string"),
  staySignedIn: (validation: FormValidation, value: any) =>
    new Validator(validation, value).exists().is("boolean"),
};

export default loginValidators;
