import { getMsgFromApiErr } from "@api/api";
import { Game } from "@api/public/get/getGames";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import deleteGame from "../deleteGame";

const useDeleteGame = (gameId?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Game | null>(null);

  const send = useCallback(async () => {
    if (gameId == null) return reqState;
    setLoading();
    try {
      const deleteRes = await deleteGame(gameId);
      return setSuccess(deleteRes);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [gameId, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useDeleteGame;
