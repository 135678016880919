import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {
  wrap?: string;
}

const Containers = ({
  wrap = "lg:flex-row",
  children,
  className = "",
  style,
  id,
}: PropsWithChildren<Props>) => (
  <div
    className={classNames(
      "flex flex-col max-w-7xl mx-auto px-6 lg:px-8 gap-4 lg:gap-6",
      wrap,
      className,
    )}
    style={style}
    id={id}>
    {children}
  </div>
);
export default Containers;
