import axios from "axios";

const uploadToS3 = async (url: string, file: File) => {
  const { data } = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
  return data;
};

export default uploadToS3;
