import developmentConfig from "./development.config";
import productionConfig from "./production.config";
import versionMatrix from "./versionMatrix";

export interface Config {
  apiURL: string;
  sanityProjectId: string;
  sanityDataset: string;
  s3Buckets: {};
  awsExports: any;
}

const vercelEnv = process.env.REACT_APP_VERCEL_ENV;
const nodeEnv = process.env.NODE_ENV ?? "development";

export const env = vercelEnv ?? nodeEnv;
export const isDev = env !== "production";
export const isProd = env === "production";

export const appVersion = process.env.REACT_APP_VERSION;
export const requiredApiVersion = versionMatrix[appVersion ?? ""] as string | undefined;

const config: Record<string, Config> = {
  test: developmentConfig,
  development: developmentConfig,
  preview: developmentConfig,
  production: productionConfig,
};

export default config[env];
