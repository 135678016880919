import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { classNames } from "@lib/utils/generic";
import React, {
  cloneElement,
  Fragment,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useRef,
} from "react";
import Button, { ButtonProps } from "../Button";
import DataCheck, { DataCheckProps } from "../DataCheck";

interface Props {
  active?: boolean;

  title?: ReactNode;
  subTitle?: ReactNode;

  iconBgColor?: string;
  iconColor?: string;
  icon?: ReactElement;

  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  isLoading?: boolean;
  error?: string | null;

  buttons?: ReactNode;

  dataCheckProps?: DataCheckProps;

  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export type ModalProps = PropsWithChildren<Props>;

export default function Modal(props: ModalProps) {
  const {
    active,

    title = "Are you sure?",
    subTitle = "This action cannot be undone.",

    iconBgColor = "bg-green-100",
    iconColor = "text-green-600",
    icon = <CheckIcon />,

    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel",
    confirmButtonProps,
    cancelButtonProps,
    isLoading,
    error,

    buttons,

    dataCheckProps,

    children,

    onClose = () => {},
    onCancel = () => {},
    onConfirm = () => {},
  } = props;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative bg-white rounded-lg min-h-[8rem] px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <DataCheck loadingIndicator="spinner" {...dataCheckProps}>
                  <div>
                    <div
                      className={classNames(
                        "mx-auto flex items-center justify-center h-12 w-12 rounded-full",
                        iconBgColor,
                      )}>
                      {cloneElement(icon, {
                        className: classNames("h-6 w-6", iconColor, icon.props.className),
                        "aria-hidden": true,
                      })}
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{subTitle}</p>
                      </div>
                    </div>
                  </div>
                  {children}
                  <div className="text-center mt-4">
                    <p className="text-red-400 text-sm">{error}</p>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    {buttons ? (
                      buttons
                    ) : (
                      <>
                        <Button
                          bgColor="bg-white"
                          bgHoverColor="hover:bg-gray-100"
                          borderColor="border-gray-300"
                          textColor="text-gray-700"
                          onClick={onCancel}
                          {...cancelButtonProps}>
                          {cancelButtonText}
                        </Button>
                        <Button onClick={onConfirm} isLoading={isLoading} {...confirmButtonProps}>
                          {confirmButtonText}
                        </Button>
                      </>
                    )}
                  </div>
                </DataCheck>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
