import { CSSProps } from "@lib/types/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {
  bgColor?: string;
  textColor?: string;
}

export default function Badge(props: PropsWithChildren<Props>) {
  const {
    bgColor = "bg-gray-100",
    textColor = "text-gray-800",
    className,
    id,
    style,
    children,
  } = props;

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium whitespace-nowrap ${bgColor} ${textColor} ${className}`}
      style={style}
      id={id}>
      {children}
    </span>
  );
}
