import useGetPlatforms from "@api/public/get/hooks/useGetPlatforms";
import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FilePicker from "@components/common/form/FilePicker";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import Page from "@components/layout/Page";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeletePlatformModal from "./DeletePlatformModal";
import useEditPlatformHandle from "./hooks/useEditPlatformHandle";

const initialValues = {
  id: "",
  name: "",
  shortName: "",
  icon: "",
};

export type EditPlatformValues = typeof initialValues;

export default function Platform() {
  const { platformId } = useParams();

  const { pushSnackbar } = useGlobalContext();

  const navigate = useNavigate();

  const [iconImageFile, setIconImageFile] = useState<File>();
  const [selectedPlatformId, setSelectedPlatformId] = useState<string>();

  const {
    data: platforms,
    isLoading: platformsIsLoading,
    error: platformsError,
  } = useGetPlatforms(true);

  const platform = platforms.find(({ id }) => platformId === id);

  const formManager = useFormManager(initialValues, "editPlatform");

  const { values, validations, resetValidations, onBlur, onChange, setValues } = formManager;

  const [handle, editIsLoading] = useEditPlatformHandle(formManager, iconImageFile);

  useEffect(() => {
    if (platform == null) return;
    const { id, name, shortName, icon } = platform;

    setValues({
      ...initialValues,
      id,
      name,
      shortName,
      icon,
    });
  }, [platform, setValues]);

  return (
    <Page
      title={platform?.name ?? "Platform"}
      isLoading={platformsIsLoading}
      error={platformsError}
      breadcrumbs={[
        {
          title: "Platforms",
          to: "/platforms",
        },
      ]}
      buttons={
        <>
          <Button type="submit" form="edit-platform-form" isLoading={editIsLoading}>
            Save
          </Button>
          <Button
            type="submit"
            bgColor="bg-red-600"
            bgHoverColor="hover:bg-red-700"
            onClick={() => platformId && setSelectedPlatformId(platformId)}>
            Delete
          </Button>
        </>
      }>
      <Form
        handle={handle}
        validation={validations.generic}
        id="edit-platform-form"
        resetValidations={resetValidations}
        onCompletion={() =>
          pushSnackbar(createSuccessSnackbar("Platform Created", undefined, 3000))
        }>
        <Containers>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Icon Image" validation={validations.icon}>
                  <FilePicker
                    name="icon"
                    maxBytes={10485760}
                    whitelistedFileTypes={["svg"]}
                    initialUrl={values.icon}
                    value={iconImageFile}
                    onChange={onChange}
                    onFileChange={setIconImageFile}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Name" validation={validations.name}>
                  <Input
                    name="name"
                    value={values.name}
                    placeholder="Name"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Short Name" validation={validations.shortName}>
                  <Input
                    name="shortName"
                    value={values.shortName}
                    placeholder="Short Name"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
        </Containers>
      </Form>

      <DeletePlatformModal
        platformId={selectedPlatformId}
        onDelete={() => navigate("/platforms")}
        onClose={() => setSelectedPlatformId(undefined)}
      />
    </Page>
  );
}
