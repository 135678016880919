import { CSSProps } from "@lib/types/generic";
import React, { PropsWithChildren } from "react";

const TableCell = ({ children, className = "", ...cssProps }: PropsWithChildren<CSSProps>) => (
  <td
    className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ${className}`}
    {...cssProps}>
    {children}
  </td>
);

export default TableCell;
