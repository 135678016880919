import { FormValidation } from "@lib/types/form";
import { customValidate, ParentValidators, validate } from "@lib/utils/form";
import { getValueFromElement } from "@lib/utils/generic";
import { Dispatch, useEffect, useRef, useState } from "react";
import { FormElement } from "../Form";

type FormValidations<V> = Partial<Record<keyof V | "generic", FormValidation>>;

type ClearValidation<K> = (key: K) => void;
type ClearValidations<K> = (keys: Array<K>) => void;
type ValidateField<K> = (key: K, value?: any) => boolean;
type ValidateFields<K> = (keys: Array<K>) => boolean;
type ValidateCustom<K> = (key: K, validator: (validation: FormValidation) => void) => boolean;

type SetValidation<K> = (key: K, validation: FormValidation) => void;
type MergeValidation = (newValidation: FormValidation) => void;
type ResetValidations = () => void;
type SetValues<V> = Dispatch<React.SetStateAction<V>>;

export type OnFormChange = (target: FormElement<any, any>) => void;
export type OnFormChangeMultiple = (target: FormElement<any, any>[]) => void;
export type OnFormBlur = (target: FormElement<any, any>) => void;

export type FormManager<V, K = keyof V, VK = K | "generic"> = {
  values: V;
  validations: FormValidations<V>;
  onChange: OnFormChange;
  onChangeMultiple: OnFormChangeMultiple;
  onBlur: OnFormBlur;
  clearValidation: ClearValidation<VK>;
  clearValidations: ClearValidations<VK>;
  validateField: ValidateField<K>;
  validateFields: ValidateFields<K>;
  validateCustom: ValidateCustom<VK>;
  setValidation: SetValidation<VK>;
  mergeValidation: MergeValidation;
  resetValidations: ResetValidations;
  setValues: SetValues<V>;
};
export type UseFormHandle<V> = (formManager: FormManager<V>, ...args: any[]) => Array<any>;

const useFormManager = <V>(
  initialValues: V,
  formName: ParentValidators = "global",
): FormManager<V> => {
  type K = keyof V;
  type VK = K | "generic";

  const [values, setValues] = useState<V>(initialValues);
  const [validations, setValidations] = useState<FormValidations<V>>({});

  const validationsRef = useRef(validations);

  useEffect(() => {
    validationsRef.current = validations;
  }, [validations]);

  const onChange: OnFormChange = target => {
    const key = target.name;
    const value = getValueFromElement(target);

    setValues({ ...values, [key]: value });
  };

  const onChangeMultiple: OnFormChangeMultiple = targets => {
    const changes = targets.reduce((acc, target) => {
      const key = target.name;
      const value = getValueFromElement(target);
      return { ...acc, [key]: value };
    }, {});
    setValues({ ...values, ...changes });
  };

  const onBlur: OnFormBlur = target => {
    onChange(target);
    const key = target.name;
    const value = getValueFromElement(target);

    validateField(key, value);
  };

  const clearValidation: ClearValidation<VK> = key => {
    setValidation(key, { key: String(key), failed: false, list: [] });
  };

  const clearValidations: ClearValidations<VK> = keys => {
    keys.map(key => clearValidation(key));
  };

  const validateFields: ValidateFields<K> = keys => {
    return keys.filter(key => validateField(key) === true).length > 0;
  };

  const validateField: ValidateField<K> = (key, value) => {
    const validation = validate(formName, String(key), value ?? values[key], values as any);
    setValidation(key, validation);
    return validation?.failed == null ? false : validation.failed;
  };

  const validateCustom: ValidateCustom<VK> = (key, validator) => {
    const validation = customValidate(String(key), validator);

    if (validationsRef.current[key] == null) {
      setValidation(key, validation);
    } else {
      mergeValidation(validation);
    }

    return validation?.failed == null ? false : validation.failed;
  };

  const setValidation: SetValidation<VK> = (key, validation) => {
    setValidations(validations => ({ ...validations, [key]: validation }));
  };

  const mergeValidation: MergeValidation = newValidation => {
    setValidations(validations => {
      const key = newValidation.key as VK;
      const currentValidation = validations[key];
      if (currentValidation == null) return validations;
      const mergedValidation: FormValidation = {
        ...currentValidation,
        failed: newValidation.failed || currentValidation.failed,
        list: [...currentValidation.list, ...newValidation.list],
      };

      return { ...validations, [key]: mergedValidation };
    });
  };

  const resetValidations = () => setValidations({});

  return {
    values,
    validations,
    onChange,
    onChangeMultiple,
    onBlur,
    clearValidation,
    clearValidations,
    validateField,
    validateFields,
    validateCustom,
    setValidation,
    mergeValidation,
    resetValidations,
    setValues,
  };
};

export default useFormManager;

export const mergeValidations = (validation?: FormValidation, newValidation?: FormValidation) => {
  if (validation == null) return newValidation;
  if (newValidation == null) return validation;
  return {
    key: validation.key,
    failed: newValidation.failed || validation.failed,
    list: [...validation.list, ...newValidation.list],
  } as FormValidation;
};
