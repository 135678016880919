import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FilePicker from "@components/common/form/FilePicker";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import Page from "@components/layout/Page";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React, { useState } from "react";
import useCreatePlatformHandle from "./hooks/useCreatePlatformHandle";

const initialValues = {
  name: "",
  shortName: "",
  icon: "",
};

export type CreatePlatformValues = typeof initialValues;

export default function CreatePlatform() {
  const { pushSnackbar } = useGlobalContext();

  const [iconImageFile, setIconFileimage] = useState<File>();

  const formManager = useFormManager(initialValues, "createPlatform");

  const { values, validations, resetValidations, onBlur, onChange } = formManager;

  const [handle, createIsLoading] = useCreatePlatformHandle(formManager, iconImageFile);

  return (
    <Page
      title="Platform"
      breadcrumbs={[
        {
          title: "Platforms",
          to: "/platforms",
        },
      ]}
      buttons={
        <>
          <Button type="submit" form="create-platform-form" isLoading={createIsLoading}>
            Save
          </Button>
        </>
      }>
      <Form
        handle={handle}
        validation={validations.generic}
        id="create-platform-form"
        resetValidations={resetValidations}
        onCompletion={() =>
          pushSnackbar(createSuccessSnackbar("Platform Created", undefined, 3000))
        }>
        <Containers>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Icon Image" validation={validations.icon}>
                  <FilePicker
                    name="icon"
                    maxBytes={10485760}
                    whitelistedFileTypes={["svg"]}
                    initialUrl={values.icon}
                    value={iconImageFile}
                    onChange={onChange}
                    onFileChange={setIconFileimage}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
          <Card basis="basis-1/2">
            <FormRows>
              <FormRow>
                <FormEntry label="Name" validation={validations.name}>
                  <Input
                    name="name"
                    value={values.name}
                    placeholder="Name"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry label="Short Name" validation={validations.shortName}>
                  <Input
                    name="shortName"
                    value={values.shortName}
                    placeholder="Short Name"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Card>
        </Containers>
      </Form>
    </Page>
  );
}
