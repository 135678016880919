import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { sortDirectionInvert } from "@lib/constants/generic";
import { SortDirection } from "@lib/enums/generic";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import { Children, PropsWithChildren, useCallback } from "react";
import DataCheck from "../DataCheck";

interface TableHeader {
  key?: string;
  name?: string;
  sortable?: boolean;
}

interface Props extends CSSProps {
  headers?: TableHeader[];
  sortKey?: string | null;
  sortDirection?: SortDirection | null;
  isLoading?: boolean;
  error?: string | null;
  setSortKey?: (sortKey: string) => void;
  setSortDirection?: (sortDirection: SortDirection) => void;
}

export default function Table(props: PropsWithChildren<Props>) {
  const {
    headers = [],
    sortKey,
    sortDirection = SortDirection.Ascending,
    isLoading,
    error,

    children,
    className = "",
    id,
    style,

    setSortKey = () => {},
    setSortDirection = () => {},
  } = props;

  const handleHeaderClick = useCallback(
    (key: string) => {
      const alreadySorting = key === sortKey;
      if (alreadySorting && sortDirection) setSortDirection(sortDirectionInvert[sortDirection]);
      else setSortKey(key);
    },
    [sortKey, sortDirection, setSortDirection, setSortKey],
  );

  const isEmpty = Children.count(children) === 0;

  return (
    <Disclosure>
      <div className={classNames("mt-8 flex flex-col", className)} id={id} style={style}>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                className="relative min-w-full divide-y divide-gray-300"
                style={isLoading || error || isEmpty ? { minHeight: 200 } : {}}>
                <thead className="bg-gray-50">
                  <tr>
                    {headers.map(({ key, name, sortable }, i) => {
                      const isSorted = sortable && sortKey === key;

                      return (
                        <th
                          key={i}
                          scope="col"
                          className="py-3 pl-4 pr-3 text-left text-xs tracking-wide text-gray-500 sm:pl-6">
                          <Disclosure.Button
                            className="group inline-flex uppercase font-medium"
                            disabled={!sortable}
                            onClick={() => key && handleHeaderClick(key)}>
                            {name}
                            <span
                              className={`ml-2 flex-none rounded ${
                                sortable ? "group-hover:visible group-focus:visible" : ""
                              } ${
                                isSorted
                                  ? "visible bg-gray-200 text-gray-600"
                                  : "invisible text-gray-400"
                              }`}>
                              {isSorted ? (
                                sortDirection === SortDirection.Ascending ? (
                                  <ChevronDownIcon className="h-4 w-4 text-xs" aria-hidden="true" />
                                ) : (
                                  <ChevronUpIcon className="h-4 w-4 text-xs" aria-hidden="true" />
                                )
                              ) : (
                                <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {!isLoading && !error && !isEmpty && children}
                </tbody>
              </table>

              <DataCheck
                isLoading={isLoading}
                loadingIndicator="spinner"
                isEmpty={isEmpty}
                error={error}
                dataCheckStyle={{ height: 157, top: 43 }}
              />
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
  );
}
