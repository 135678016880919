import useDeleteBanner from "@api/private/delete/hooks/useDeleteBanner";
import DeleteModal from "@components/common/modals/DeleteModal";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React from "react";

interface Props {
  bannerId?: string;
  onClose?: () => void;
  onDelete?: () => void;
}

export default function DeleteBannerModal(props: Props) {
  const { bannerId, onClose = () => {}, onDelete = () => {} } = props;

  const { pushSnackbar } = useGlobalContext();

  const { send: deleteBanner, isLoading, error } = useDeleteBanner(bannerId);

  const handleDelete = async () => {
    const { error } = await deleteBanner();
    if (error) return;
    onDelete();
    onClose();
    pushSnackbar(createSuccessSnackbar("Banner Deleted", undefined, 3000));
  };

  return (
    <DeleteModal
      active={!!bannerId}
      isLoading={isLoading}
      error={error}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleDelete}
    />
  );
}
