import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import verifyCig, { VerifyCIGRes } from "../verifyCig";

const useVerifyCig = (cigId?: string, verificationCode?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<VerifyCIGRes | null>(null);

  const send = useCallback(async () => {
    if (cigId == null || verificationCode == null) return reqState;
    setLoading();
    try {
      const verifyRes = await verifyCig(cigId, verificationCode);
      return setSuccess(verifyRes);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [cigId, verificationCode, reqState, setError, setLoading, setSuccess]);

  return { ...reqState, data: reqState.data, send };
};

export default useVerifyCig;
