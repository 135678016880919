import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { PropsWithChildren } from "react";

interface Props extends CSSProps {
  basis?: string;
  noPadding?: boolean;
  noOverflow?: boolean;
}

const Card = ({
  basis,
  noPadding,
  noOverflow,
  children,
  className = "",
  id,
  style,
}: PropsWithChildren<Props>) => (
  <div
    className={classNames(
      "relative flex-grow bg-white shadow rounded-lg",
      noOverflow ? "overflow-hidden" : "overflow-visible",
      basis ?? "",
    )}>
    <div
      className={classNames(noPadding ? "" : "px-4 py-5 sm:p-6", className)}
      style={style}
      id={id}>
      {children}
    </div>
  </div>
);
export default Card;
