import { classNames } from "@lib/utils/generic";
import React, { HTMLProps } from "react";
import { Link } from "react-router-dom";

interface Props {
  size?: "extra-small" | "small" | "medium" | "large";
  svgProps?: HTMLProps<SVGSVGElement>;
  to?: string;
  disabled?: boolean;
  exclusive?: boolean;
  color?: string;
  onClick?: () => void;
}

export default function Banner(props: Props) {
  const {
    size = "medium",
    svgProps = {},
    to,
    disabled,
    exclusive,
    color = "fill-white",
    onClick,
  } = props;

  const width = {
    "extra-small": 80,
    small: 100,
    medium: 150,
    large: 200,
  }[size];

  const nodes = (
    <svg
      {...(svgProps as any)}
      className={classNames(
        "yakkr-banner",
        svgProps.className ?? "",
        onClick ? "cursor-pointer" : "cursor-default",
        color,
      )}
      width={width}
      viewBox={exclusive ? "0 0 104 27" : "0 0 89 27"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      {exclusive && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.6782 6.94136C96.5736 6.94136 95.6782 7.83679 95.6782 8.94136V10.9773H93.6416C92.537 10.9773 91.6416 11.8727 91.6416 12.9773L91.6416 13.1172C91.6416 14.2217 92.537 15.1172 93.6416 15.1172H95.6782V17.1533C95.6782 18.2579 96.5736 19.1533 97.6782 19.1533H97.8181C98.9226 19.1533 99.8181 18.2579 99.8181 17.1533V15.1172H101.854C102.958 15.1172 103.854 14.2217 103.854 13.1172V12.9773C103.854 11.8727 102.958 10.9773 101.854 10.9773H99.8181V8.94136C99.8181 7.83679 98.9226 6.94136 97.818 6.94136H97.6782Z"
          fill="url(#paint0_linear_3464_25601)"
        />
      )}
      <path d="M88.9495 6.28438L88.3977 9.23771C88.3847 9.3092 88.3559 9.37759 88.3131 9.43881C88.2703 9.50004 88.2144 9.55285 88.1486 9.5941C88.0828 9.63535 88.0085 9.6642 87.9302 9.67894C87.8518 9.69368 87.771 9.694 87.6925 9.67988C86.8612 9.53032 86.0159 9.4545 85.1684 9.45352C81.6674 9.45352 80.6237 11.9254 80.6237 14.0593V20.5817C80.6237 20.7258 80.5605 20.864 80.4479 20.9659C80.3354 21.0678 80.1827 21.125 80.0236 21.125H76.5025C76.4237 21.125 76.3457 21.111 76.2728 21.0837C76.2 21.0564 76.1339 21.0163 76.0781 20.9659C76.0224 20.9154 75.9782 20.8555 75.948 20.7896C75.9179 20.7237 75.9023 20.6531 75.9023 20.5817V8.47863C75.9023 7.65731 76.2624 6.86957 76.9035 6.28839C77.5446 5.70721 78.4144 5.38011 79.3217 5.37891H80.0202C80.099 5.37891 80.1771 5.39296 80.2499 5.42026C80.3227 5.44756 80.3889 5.48758 80.4446 5.53803C80.5003 5.58848 80.5445 5.64837 80.5747 5.71428C80.6049 5.7802 80.6204 5.85084 80.6204 5.92219V7.69389H80.9155C81.8841 6.18477 82.9577 5.37891 85.9486 5.37891C86.8053 5.38741 87.6582 5.4836 88.491 5.66564C88.6404 5.69613 88.7711 5.77726 88.8564 5.89235C88.9417 6.00744 88.975 6.14773 88.9495 6.28438V6.28438Z" />
      <path d="M68.0905 11.9602L74.7258 20.2678C74.7906 20.3489 74.8293 20.4445 74.8379 20.5442C74.8464 20.6439 74.8244 20.7438 74.7743 20.8329C74.7241 20.9221 74.6477 20.9969 74.5536 21.0492C74.4594 21.1016 74.3511 21.1294 74.2407 21.1295H69.9461C69.8237 21.1296 69.7031 21.1033 69.5941 21.0529C69.4852 21.0026 69.3909 20.9295 69.3192 20.8398L64.6628 15.0086L61.2752 18.0268V20.5923C61.2752 20.6636 61.2597 20.7343 61.2295 20.8002C61.1993 20.8661 61.1551 20.926 61.0994 20.9765C61.0437 21.0269 60.9775 21.0669 60.9047 21.0942C60.8319 21.1215 60.7538 21.1356 60.675 21.1356H57.3524C57.1471 21.1356 56.9501 21.0619 56.8048 20.9306C56.6595 20.7993 56.5776 20.6212 56.5771 20.4353V3.94978C56.5776 3.12794 56.9386 2.3399 57.5807 1.75892C58.2228 1.17793 59.0936 0.851562 60.0015 0.851563L60.5016 0.851563C60.7068 0.851563 60.9035 0.925336 61.0486 1.05665C61.1937 1.18797 61.2752 1.36608 61.2752 1.55179V12.2303L67.9438 6.27381C68.5839 5.70081 69.4476 5.37927 70.3478 5.37891L74.8108 5.37891C74.8651 5.37888 74.9182 5.39349 74.9633 5.42088C75.0084 5.44826 75.0434 5.48717 75.064 5.53266C75.0846 5.57815 75.0897 5.62815 75.0788 5.67629C75.0678 5.72443 75.0413 5.76853 75.0025 5.80297L68.0905 11.9602Z" />
      <path d="M50.5936 11.9602L57.9174 21.1295H52.4158C52.3018 21.1299 52.1893 21.1055 52.0878 21.0585C51.9863 21.0115 51.8987 20.9433 51.8323 20.8594L47.1642 15.0086L43.7766 18.0268V20.5425C43.7766 20.6866 43.7133 20.8248 43.6008 20.9267C43.4882 21.0285 43.3356 21.0858 43.1764 21.0858H39.6754C39.5162 21.0858 39.3635 21.0285 39.251 20.9267C39.1384 20.8248 39.0752 20.6866 39.0752 20.5425V3.94978C39.0754 3.54246 39.1643 3.13918 39.3368 2.76298C39.5093 2.38679 39.762 2.04505 40.0805 1.75732C40.399 1.46958 40.777 1.24149 41.1929 1.08607C41.6089 0.930658 42.0546 0.850971 42.5045 0.851566L43.1714 0.851566C43.2502 0.851566 43.3283 0.865618 43.4011 0.892921C43.4739 0.920223 43.5401 0.960241 43.5958 1.01069C43.6515 1.06114 43.6957 1.12103 43.7259 1.18694C43.756 1.25286 43.7716 1.3235 43.7716 1.39485V12.2303L50.4402 6.27382C51.0819 5.69934 51.9484 5.37769 52.8509 5.37891L57.9824 5.37891L50.5936 11.9602Z" />
      <path d="M32.6409 7.6835C32.6432 7.07202 32.9127 6.4862 33.3907 6.0541C33.8687 5.622 34.5162 5.37877 35.1917 5.37757H36.5254C36.7465 5.37757 36.9585 5.45707 37.1149 5.59858C37.2712 5.74009 37.359 5.93201 37.359 6.13213V20.5925C37.359 20.7366 37.2958 20.8747 37.1832 20.9766C37.0707 21.0785 36.918 21.1358 36.7588 21.1358L33.2361 21.1358C33.0769 21.1358 32.9243 21.0785 32.8117 20.9766C32.6992 20.8747 32.6359 20.7366 32.6359 20.5925V18.9324H32.4876C31.3106 20.3027 29.32 21.1312 26.0557 21.1312C18.7535 21.1312 17.9316 15.374 17.9316 13.2521C17.9316 10.9553 18.7085 5.37305 26.069 5.37305C29.2866 5.37305 31.2572 6.29059 32.4376 7.68652L32.6409 7.6835ZM32.3409 13.2491C32.3409 11.1167 31.5073 9.04321 27.4328 9.04321C22.9131 9.04321 22.4313 12.0237 22.4313 13.2491C22.4313 14.381 22.9314 17.455 27.4194 17.455C31.5073 17.4505 32.3409 15.6396 32.3409 13.2491Z" />
      <path d="M18.459 6.13187L9.71979 26.7871C9.6761 26.8896 9.59924 26.9777 9.49928 27.0399C9.39932 27.1021 9.28093 27.1355 9.15962 27.1357H5.45687C5.35952 27.1357 5.26363 27.1143 5.17748 27.0732C5.09133 27.0322 5.0175 26.9728 4.96237 26.9001C4.90724 26.8275 4.87245 26.7438 4.86101 26.6563C4.84957 26.5688 4.86182 26.4801 4.89671 26.3978L7.11736 21.1476C6.62427 21.1473 6.14283 21.0119 5.73635 20.7593C5.32988 20.5066 5.0174 20.1485 4.84002 19.732L0.530427 9.61189C0.330756 9.14244 0.260305 8.63605 0.325154 8.13645C0.390003 7.63684 0.588204 7.15903 0.902647 6.74426C1.21709 6.32949 1.63833 5.99022 2.12999 5.75575C2.62166 5.52128 3.16897 5.39865 3.7247 5.39844V5.39844L8.76951 17.2465L12.9374 7.38896C13.1854 6.80306 13.6241 6.29908 14.1953 5.94367C14.7666 5.58825 15.4435 5.39813 16.1367 5.39844L17.8972 5.39844C17.9942 5.39814 18.0899 5.4192 18.1759 5.4598C18.2619 5.5004 18.3358 5.55932 18.391 5.63149C18.4463 5.70367 18.4814 5.78692 18.4932 5.87409C18.5051 5.96126 18.4933 6.04973 18.459 6.13187Z" />
      <defs>
        <linearGradient
          id="paint0_linear_3464_25601"
          x1="97.7476"
          y1="6.94141"
          x2="97.7476"
          y2="19.1534"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E0D0FF" />
          <stop offset="1" stopColor="#8242FD" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (to == null || onClick) return nodes;
  return (
    <Link to={to} className={disabled ? "disabled" : ""} data-testid="nav-yakkr-banner">
      {nodes}
    </Link>
  );
}
