import useGetPendingClaims from "@api/private/get/hooks/useGetPendingClaims";
import Badge from "@components/common/Badge";
import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import FormText from "@components/common/form/FormText";
import FormTitle from "@components/common/form/FormTitle";
import Page from "@components/layout/Page";
import { claimReasonNames, claimStateColors, claimStateNames } from "@lib/constants/generic";
import { numberToCurrency } from "@lib/utils/generic";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AcceptClaimModal from "../AcceptClaimModal";
import RejectClaimModal from "../RejectClaimModal";

export default function Claim() {
  const { claimId } = useParams();

  const navigate = useNavigate();

  const [selectedAcceptClaimId, setSelectedAcceptClaimId] = useState<string>();
  const [selectedRejectClaimId, setSelectedRejectClaimId] = useState<string>();

  const {
    send: getPendingClaims,
    data: pendingClaims,
    isLoading,
    error,
  } = useGetPendingClaims(true);

  const pendingClaim = pendingClaims.find(({ id }) => claimId === id);

  const { reason, state, reasonClass, createdAt, sessionInfo } = pendingClaim ?? {};
  const {
    extraInfo,
    from,
    maxSlots = 0,
    slotsAvailable = 0,
    charity,
    charityName,
    cig,
    game,
    currency,
    platform,
    price,
    to,
  } = sessionInfo ?? {};

  return (
    <Page
      title={
        `${cig?.displayName ?? "Unknown"} - ${dayjs(from).format("DD MMM YY | HH:mm")}` ?? "Claim"
      }
      isLoading={isLoading}
      error={error}
      breadcrumbs={[
        {
          title: "Claims",
          to: "/claims",
        },
      ]}
      buttons={
        <>
          <Button
            bgColor="bg-green-600"
            bgHoverColor="hover:bg-green-700"
            onClick={() => setSelectedAcceptClaimId(claimId)}>
            Accept
          </Button>
          <Button
            bgColor="bg-red-600"
            bgHoverColor="hover:bg-red-700"
            onClick={() => setSelectedRejectClaimId(claimId)}>
            Reject
          </Button>
        </>
      }>
      <Containers>
        <Card basis="basis-5/3">
          <FormTitle>Session Details</FormTitle>
          <FormRows gap="gap-12">
            <FormRow>
              <FormEntry label="Start Date" basis="basis-1/3">
                <FormText>{dayjs(from).format("DD MMM YY HH:mm")}</FormText>
              </FormEntry>
              <FormEntry label="End Date" basis="basis-1/3">
                <FormText>{dayjs(to).format("DD MMM YY HH:mm")}</FormText>
              </FormEntry>
              <FormEntry label="Created at" basis="basis-1/3">
                <FormText>{dayjs(sessionInfo?.createdAt).format("DD MMM YY HH:mm")}</FormText>
              </FormEntry>
            </FormRow>

            <FormRow>
              <FormEntry label="Price" basis="basis-1/3">
                <FormText>{price && currency ? numberToCurrency(price, currency) : "N/A"}</FormText>
              </FormEntry>
              <FormEntry label="Tickets Sold" basis="basis-1/3">
                <FormText>
                  {maxSlots - slotsAvailable} / {maxSlots}
                </FormText>
              </FormEntry>
              <FormEntry label="Charity Name" basis="basis-1/3">
                <FormText>{charity ? charityName || "No charity specified" : "N/A"}</FormText>
              </FormEntry>
            </FormRow>

            <FormRow>
              <FormEntry label="Game" basis="basis-1/3">
                <FormText>{game?.longName}</FormText>
              </FormEntry>
              <FormEntry label="Platform" basis="basis-1/3">
                <FormText>{platform?.name}</FormText>
              </FormEntry>
              <FormEntry label="Extra Info" basis="basis-1/3">
                <FormText>{extraInfo || "N/A"}</FormText>
              </FormEntry>
            </FormRow>
          </FormRows>
        </Card>

        <Card basis="basis-5/2">
          <FormTitle>Claim Details</FormTitle>
          <FormRows gap="gap-12">
            <FormRow>
              <FormEntry label="Claim State" basis="basis-1/2">
                <Badge className="w-min mt-1" bgColor={state ? claimStateColors[state] : undefined}>
                  {state ? claimStateNames[state] : "N/A"}
                </Badge>
              </FormEntry>
              <FormEntry label="Created at" basis="basis-1/2">
                <FormText>{dayjs(createdAt).format("DD MMM YY HH:mm")}</FormText>
              </FormEntry>
            </FormRow>

            <FormRow>
              <FormEntry label="CIG Display Name" basis="basis-1/2">
                <FormText>{cig?.displayName}</FormText>
              </FormEntry>
              <FormEntry label="Reason" basis="basis-1/2">
                <FormText>{reasonClass ? claimReasonNames[reasonClass] : "N/A"}</FormText>
              </FormEntry>
            </FormRow>

            <FormRow>
              <FormEntry label="Claim Description" basis="basis-1">
                <FormText>{reason ?? "N/A"}</FormText>
              </FormEntry>
            </FormRow>
          </FormRows>
        </Card>
      </Containers>

      <AcceptClaimModal
        claimId={selectedAcceptClaimId}
        onAccept={getPendingClaims}
        onClose={() => navigate("/claims")}
      />
      <RejectClaimModal
        claimId={selectedRejectClaimId}
        onReject={getPendingClaims}
        onClose={() => navigate("/claims")}
      />
    </Page>
  );
}
