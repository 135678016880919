import { sanity, sanityCall } from "@api/sanity";
import { SanityStreamer } from "@lib/types/generic";

export type GetSanityStreamersRes = SanityStreamer[];

const getSanityStreamers = async () =>
  sanityCall(
    sanity.fetch<GetSanityStreamersRes>(`*[_type == "streamer"]{
      slug,
      twitchHandle,
      twitterHandle,
      games,
      profileImage,
      about
    }`),
  );
export default getSanityStreamers;
