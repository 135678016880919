import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React, { forwardRef } from "react";
import Progress from "../Progress";
import { FormElement } from "./Form";

interface Props extends CSSProps {
  type?: "text" | "password" | "email" | "number" | "url";
  name?: string;
  placeholder?: string;
  multiline?: boolean;
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
  autocomplete?: boolean;
  isLoading?: boolean;
  error?: boolean;

  borderColor?: string;
  borderFocusColor?: string;
  ringFocusColor?: string;

  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
  onFocus?: (target: FormElement) => void;
}

export type InputProps = Props;

const Input = forwardRef<HTMLInputElement & HTMLTextAreaElement, Props>((props, ref) => {
  const {
    type = "text",
    name,
    placeholder,
    multiline,
    value,
    disabled,
    readOnly,
    autocomplete,
    isLoading,
    error,

    borderColor = "border-gray-300",
    borderFocusColor = "focus:border-indigo-500",
    ringFocusColor = "focus:ring-indigo-500",

    className,
    id,
    style,

    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
  } = props;

  const fullClassName = classNames(
    "shadow-sm block w-full sm:text-sm rounded-md",
    borderColor,
    borderFocusColor,
    ringFocusColor,
    className ?? "",
    error
      ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
      : "",
  );

  const errorIcon = (
    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      {isLoading ? (
        <Progress size="w-5 h-5" />
      ) : (
        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
      )}
    </div>
  );

  if (multiline) {
    return (
      <div className="flex-grow mt-1 relative rounded-md shadow-sm">
        <textarea
          ref={ref}
          value={value}
          name={name}
          className={fullClassName}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autocomplete ? "on" : "off"}
          onChange={({ target }) => onChange(target)}
          onBlur={({ target }) => onBlur(target)}
          onFocus={({ target }) => onFocus(target)}
        />
        {error && errorIcon}
      </div>
    );
  } else {
    return (
      <div className="flex-grow mt-1 relative rounded-md shadow-sm">
        <input
          ref={ref}
          value={value}
          type={type}
          name={name}
          className={fullClassName}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete={autocomplete ? "on" : "off"}
          onChange={({ target }) => onChange(target)}
          onBlur={({ target }) => onBlur(target)}
          onFocus={({ target }) => onFocus(target)}
        />
        {error && errorIcon}
      </div>
    );
  }
});

export default Input;
