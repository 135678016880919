import useLogin, { LoginError } from "@api/auth/hooks/useLogin";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import useGlobalContext from "@hooks/useGlobalContext";
import { createWarnSnackbar } from "@lib/constants/generic";
import { LoginValues } from "../Login";

const useLoginHandles: UseFormHandle<LoginValues> = ({
  values,
  validateFields,
  validateCustom,
}) => {
  const { pushSnackbar, setLoggingIn } = useGlobalContext();

  const { send: login, isLoading } = useLogin(values.email, values.password, values.staySignedIn);

  const handle = async () => {
    setLoggingIn(true);

    let failed = false;

    failed = validateFields(["email", "password", "staySignedIn"]);

    if (failed) return failed;

    const { error } = await login();

    if (error) {
      failed = true;

      switch (error.toString()) {
        case LoginError.EmailNotConfirmed.toString():
          pushSnackbar(
            createWarnSnackbar("You must confirm your email to continue", undefined, 3000),
          );
          break;
        default:
          validateCustom("generic", validator => {
            validator.list.push({
              text: error,
              type: "error",
            });
          });
          break;
      }
    }

    return failed;
  };

  return [handle, isLoading];
};

export default useLoginHandles;
