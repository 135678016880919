import useGetStreamerCorpus from "@api/private/get/hooks/useGetStreamerCorpus";
import { urlFor } from "@api/sanity";
import useGetSanityStreamer from "@api/sanity/get/hooks/useGetSanityStreamer";
import Button from "@components/common/Button";
import Card from "@components/common/containers/Card";
import Containers from "@components/common/containers/Containers";
import FilePicker from "@components/common/form/FilePicker";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import FormText from "@components/common/form/FormText";
import FormTitle from "@components/common/form/FormTitle";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import Page from "@components/layout/Page";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar, talentSiteUrl } from "@lib/constants/generic";
import { mergeStreamer } from "@lib/utils/generic";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useEditSeoStreamerHandle from "./hooks/useEditSeoStreamerHandle";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { isProd } from "@config/config";

const initialValues = {
  talentDisplayName: "",
  bulkText: "",
};

export type EditSeoStreamerValues = typeof initialValues;

export default function SeoStreamer() {
  const { slug } = useParams();

  const { pushSnackbar } = useGlobalContext();

  const {
    data: sanityStreamer,
    isLoading: sanityStreamerIsLoading,
    error: sanityStreamerError,
  } = useGetSanityStreamer(slug, true);

  const {
    data: streamerCorpus,
    isLoading: streamerCorpusIsLoading,
    error: streamerCorpusError,
  } = useGetStreamerCorpus(slug, true);

  const mergedStreamer = sanityStreamer ? mergeStreamer(sanityStreamer, streamerCorpus) : undefined;

  const formManager = useFormManager(initialValues, "editSeoStreamer");

  const { values, validations, resetValidations, onBlur, onChange, setValues } = formManager;

  const [handle, editIsLoading] = useEditSeoStreamerHandle(formManager);

  const isLoading = sanityStreamerIsLoading || streamerCorpusIsLoading;
  const error = sanityStreamerError ?? streamerCorpusError;

  useEffect(() => {
    const { slug } = sanityStreamer ?? {};
    const { bulkText } = streamerCorpus ?? {};

    setValues({
      ...initialValues,
      talentDisplayName: slug ?? "",
      bulkText: bulkText ?? "",
    });
  }, [sanityStreamer, streamerCorpus, setValues]);

  return (
    <Page
      title={mergedStreamer?.slug}
      isLoading={isLoading}
      error={error}
      breadcrumbs={[
        {
          title: "SEO Streamers",
          to: "/seo/streamers",
        },
      ]}
      buttons={
        <>
          <Button type="submit" form="edit-platform-form" isLoading={editIsLoading}>
            Save
          </Button>
        </>
      }>
      <Form
        handle={handle}
        validation={validations.generic}
        id="edit-platform-form"
        resetValidations={resetValidations}
        onCompletion={() => pushSnackbar(createSuccessSnackbar("Corpus Updated", undefined, 3000))}>
        <Containers>
          <Card basis="basis-1/2">
            <FormRows>
              <FormTitle>Sanity Data</FormTitle>

              <FormRows gap="gap-12">
                <FormRow>
                  <FormEntry label="Slug" basis="basis-1/2">
                    <FormText>{mergedStreamer?.slug}</FormText>
                  </FormEntry>
                </FormRow>
                <FormRow>
                  <FormEntry label="Twitch Handle" basis="basis-1/2">
                    <FormText>{mergedStreamer?.twitchHandle}</FormText>
                  </FormEntry>
                  <FormEntry label="Twitter Handle" basis="basis-1/2">
                    <FormText>{mergedStreamer?.twitterHandle}</FormText>
                  </FormEntry>
                </FormRow>
                <FormRow>
                  <FormEntry label="Profile Image">
                    <FormText>
                      <FilePicker
                        initialUrl={
                          mergedStreamer?.profileImage && urlFor(mergedStreamer?.profileImage).url()
                        }
                        disabled
                      />
                    </FormText>
                  </FormEntry>
                </FormRow>
              </FormRows>
            </FormRows>
          </Card>

          <Card basis="basis-1/2">
            <FormTitle>Corpus</FormTitle>
            <FormRows gap="gap-12">
              <FormRow>
                <FormEntry label="Bulk Text" validation={validations.bulkText}>
                  <Input
                    name="bulkText"
                    value={values.bulkText}
                    placeholder="Bulk Text"
                    onChange={onChange}
                    onBlur={onBlur}
                    multiline
                    className="min-h-[200px]"
                  />
                </FormEntry>
              </FormRow>

              <div className="flex flex-col gap-4">
                {isProd && (
                  <FormRow>
                    <FormEntry>
                      <Button
                        href={`${talentSiteUrl}streamers/${mergedStreamer?.slug}`}
                        openInNewTab>
                        View on live site <ExternalLinkIcon className="w-4 h-4 ml-1" />
                      </Button>
                    </FormEntry>
                  </FormRow>
                )}
                <FormRow>
                  <FormEntry>
                    <Button
                      bgColor="bg-purple-600"
                      bgHoverColor="hover:bg-purple-700"
                      href={`http://localhost:3000/streamers/${mergedStreamer?.slug}`}
                      openInNewTab>
                      View on local <ExternalLinkIcon className="w-4 h-4 ml-1" />
                    </Button>
                  </FormEntry>
                </FormRow>
              </div>
            </FormRows>
          </Card>
        </Containers>
      </Form>
    </Page>
  );
}
