import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import React from "react";
import { FormElement } from "./Form";

interface Props extends CSSProps {
  name?: string;
  value: boolean;
  disabled?: boolean;
  error?: boolean;
  label?: string;
  description?: string;

  borderColor?: string;
  borderFocusColor?: string;
  ringFocusColor?: string;

  onChange?: (target: FormElement) => void;
  onBlur?: (target: FormElement) => void;
  onFocus?: (target: FormElement) => void;
}

export default function Checkbox(props: Props) {
  const {
    name,
    value,
    disabled,
    error,
    label,
    description,

    borderColor = "border-gray-300",
    borderFocusColor = "focus:border-indigo-500",
    ringFocusColor = "focus:ring-indigo-500",

    className = "",
    id,
    style,

    onChange = () => {},
    onBlur = () => {},
  } = props;

  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          aria-describedby="comments-description"
          name={name}
          type="checkbox"
          value={value ? "on" : "off"}
          disabled={disabled}
          className={classNames(
            "h-4 w-4 rounded",
            borderColor,
            borderFocusColor,
            ringFocusColor,
            className,
            error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
              : "",
          )}
          onChange={({ target }) => onChange(target)}
          onBlur={({ target }) => onBlur(target)}
          id={id}
          style={style}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor="comments" className="font-medium text-gray-700">
          {label}
        </label>
        <p id="comments-description" className="text-gray-500">
          {description}
        </p>
      </div>
    </div>
  );
}
