import { SortDirection } from "@lib/enums/generic";
import { sortMap } from "@lib/utils/generic";
import { useState } from "react";

const useTableSort = <T>(
  data: T[],
  initialSortKey?: string | null,
  initialSortDirection?: SortDirection | null,
) => {
  const [sortKey, setSortKey] = useState(initialSortKey);
  const [sortDirection, setSortDirection] = useState(
    initialSortDirection ?? SortDirection.Ascending,
  );

  const sortedData = sortKey ? data.sort(sortMap(sortKey, sortDirection)) : data;

  return {
    sortedData,
    sortKey,
    sortDirection,
    setSortKey,
    setSortDirection,
  };
};

export default useTableSort;
