import { getMsgFromApiErr } from "@api/api";
import { Game } from "@api/public/get/getGames";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import updateGameCompatibility, { CompatibilityConfig } from "../updateGameCompatibility";

const useUpdateGameCompatibility = (compatibility?: CompatibilityConfig[], gameID?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Game | null>(null);

  const send = useCallback(
    async (compatibilityOverride?: CompatibilityConfig[], gameIDOverride?: string) => {
      const _compatibility = compatibilityOverride ?? compatibility;
      const _gameID = gameIDOverride ?? gameID;
      if (_compatibility == null || _gameID == null) return reqState;
      setLoading();
      try {
        const newGame = await updateGameCompatibility(_compatibility, _gameID);
        return setSuccess(newGame);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [compatibility, gameID, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useUpdateGameCompatibility;
