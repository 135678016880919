import { ExclamationIcon } from "@heroicons/react/outline";
import React from "react";
import Modal, { ModalProps } from "./Modal";

interface Props extends ModalProps {
  form?: string;
}

export default function FormModal(props: Props) {
  const { form, children, ...modalProps } = props;

  return (
    <Modal
      icon={<ExclamationIcon />}
      iconBgColor="bg-purple-100"
      iconColor="text-purple-600"
      cancelButtonText="Close"
      confirmButtonText="Submit"
      confirmButtonProps={{
        bgColor: "bg-purple-600",
        bgHoverColor: "hover:bg-purple-700",
        borderColor: "border-transparent",
        textColor: "text-white",
        type: "submit",
        form,
      }}
      title="Form Modal"
      subTitle="Click Submit to continue"
      {...modalProps}>
      <div className="w-4/5 py-4 mx-auto">{children}</div>
    </Modal>
  );
}
