import { api } from "@api/api";
import { ClaimReason, ClaimState, Currency } from "@lib/enums/generic";

export interface PendingClaim {
  id: string;
  bookingID: string;
  reason: string;
  state: ClaimState;
  reasonClass: ClaimReason;
  createdAt: string;
  availabilityID: string;
  sessionInfo: SessionInfo | null;
}

export interface SessionInfo {
  extraInfo: string;
  from: string;
  maxSlots: number;
  slotsAvailable: number;
  createdAt: string;
  cigID: string;
  charity: boolean;
  charityName: null;
  cig: CIGPartial | null;
  game: GamePartial;
  currency: Currency;
  exclusive: boolean;
  platform: PlatformPartial;
  price: number;
  to: string;
  id: string;
  title: string | null;
}

export interface CIGPartial {
  id: string;
  displayName: string;
  category: string;
  profilePictureURL: string;
  shortDescription: null;
  subCategory: string;
  tagLine: string;
  bannerPictureURL: string;
  country: string;
}

export interface GamePartial {
  longName: string;
  shortName: string;
  secondaryImageURL: string;
  primaryImageURL: string;
  id: string;
  description: string;
  category: string;
}

export interface PlatformPartial {
  name: string;
  shortName: string;
  icon: string;
  id: string;
}

const getPendingClaims = async () => {
  const { data } = await api.post<PendingClaim[]>("/adminFetchPendingClaims");
  return data;
};

export default getPendingClaims;
