import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useUpdateBanner from "@api/private/update/hooks/useUpdateBanner";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { EditBannerValues } from "../Banner";

const useEditBannerHandle: UseFormHandle<EditBannerValues> = (
  { validateFields, validateCustom, setValues, values },
  imageFile: File,
  imageXSFile: File,
) => {
  const { send: updateBanner, isLoading: updateIsLoading } = useUpdateBanner(values);
  const { send: uploadToS3, isLoading: uploadToS3IsLoading } = useUploadToS3();

  const handle = async () => {
    let failed = validateFields(["bannerID", "content", "title", "imageURL", "imageURLXS"]);

    let newValues = { ...values };

    if (!failed) {
      const { data: imageUrl, error: primaryError } = await uploadToS3(imageFile);
      const { data: imageXSUrl, error: secondaryError } = await uploadToS3(imageXSFile);

      const error = primaryError ?? secondaryError;

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (imageUrl) newValues.imageURL = imageUrl;
      if (imageXSUrl) newValues.imageURLXS = imageXSUrl;

      setValues(newValues);
    }

    if (!failed) {
      const { error } = await updateBanner(newValues);

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, updateIsLoading || uploadToS3IsLoading];
};

export default useEditBannerHandle;
