import { FormValidation } from "@lib/types/form";
import { Validator } from "../Validator";

const globalValidators = {
  email: (validation: FormValidation, value: any) => {
    new Validator(validation, value).exists().is("string").regex.isEmail();
  },
};

export default globalValidators;
