import { api, APIResponseArray } from "@api/api";
import { Platform } from "./getPlatforms";

export interface Game {
  id: string;

  category: string;
  shortName: string;
  longName: string;
  description: string;

  primaryImageURL: string | null;
  secondaryImageURL: string | null;
  tertiaryImageURL: string | null;

  statisticName: string | null;
  statisticValue: string | null;

  estimatedMaxLengthMins: number | null;

  platforms: {
    items: {
      id: string;
      platform: Platform;
    }[];
  };
}

const getGames = async () => {
  const { data } = await api.get<APIResponseArray<Game>>("/games");
  return data.items;
};

export default getGames;
