import Banner from "@components/common/Banner";
import Button from "@components/common/Button";
import Checkbox from "@components/common/form/Checkbox";
import Form from "@components/common/form/Form";
import FormEntry from "@components/common/form/FormEntry";
import FormRow from "@components/common/form/FormRow";
import FormRows from "@components/common/form/FormRows";
import useFormManager from "@components/common/form/hooks/useFormManager";
import Input from "@components/common/form/Input";
import PageFull from "@components/layout/PageFull";
import useGlobalContext from "@hooks/useGlobalContext";
import React from "react";
import useLoginHandles from "./hooks/useLoginHandles";

const initialValues = {
  email: "",
  password: "",
  staySignedIn: false,
};

export type LoginValues = typeof initialValues;

export default function Login() {
  const formManager = useFormManager(initialValues, "login");

  const { breakpoints } = useGlobalContext();

  const { values, validations, onChange, onBlur, resetValidations } = formManager;

  const [handle, isLoading] = useLoginHandles(formManager);

  return (
    <PageFull>
      <div className="flex-grow justify-center items-center flex flex-col gap-4 content-center min-h-full my-8">
        <Banner color="fill-black" size={breakpoints.sm ? "medium" : "large"} />
        <h1 className="font-bold" style={{ fontSize: breakpoints.sm ? "1.75em" : "2.5rem" }}>
          Admin Panel
        </h1>

        <div className="flex flex-col gap-6 lg:w-96">
          <Form
            handle={handle}
            id="login-form"
            validation={validations.generic}
            resetValidations={resetValidations}>
            <FormRows>
              <FormRow>
                <FormEntry validation={validations.email}>
                  <Input
                    name="email"
                    type="email"
                    value={values.email}
                    placeholder="example@email.com"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry validation={validations.password}>
                  <Input
                    type="password"
                    name="password"
                    value={values.password}
                    placeholder="********"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
              <FormRow>
                <FormEntry validation={validations.staySignedIn}>
                  <Checkbox
                    name="staySignedIn"
                    value={values.staySignedIn}
                    label="Remember me"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </FormEntry>
              </FormRow>
            </FormRows>
          </Form>

          <Button form="login-form" type="submit" isLoading={isLoading}>
            Login
          </Button>
        </div>
      </div>
    </PageFull>
  );
}
