import { getMsgFromApiErr } from "@api/api";
import useRequestState from "@hooks/useRequestState";
import { useCallback } from "react";
import createPlatform, { CreatePlatformRes } from "../createPlatform";

const useCreatePlatform = (name?: string, shortName?: string) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<CreatePlatformRes | null>(
    null,
  );

  const send = useCallback(
    async (icon?: string) => {
      if (name == null || shortName == null || icon == null) return reqState;
      setLoading();
      try {
        const createRes = await createPlatform(name, shortName, icon);
        return setSuccess(createRes);
      } catch (err: any) {
        return setError(getMsgFromApiErr(err));
      }
    },
    [name, shortName, reqState, setError, setLoading, setSuccess],
  );

  return { ...reqState, data: reqState.data, send };
};

export default useCreatePlatform;
