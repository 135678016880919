import { PendingClaim } from "@api/private/get/getPendingClaims";
import Button from "@components/common/Button";
import ButtonRow from "@components/common/ButtonRow";
import useTableSort from "@components/common/table/hooks/useTableSort";
import Table from "@components/common/table/Table";
import TableCell from "@components/common/table/TableCell";
import TableRow from "@components/common/table/TableRow";
import dayjs from "dayjs";
import React from "react";
import DashboardHeading from "./DashboardHeading";

interface Props {
  pendingClaims: PendingClaim[];
  isLoading?: boolean;
  error?: string | null;
}

export default function DashboardClaims(props: Props) {
  const { pendingClaims, isLoading, error } = props;

  const { sortedData: sortedPendingClaims, ...tableSort } = useTableSort(pendingClaims);

  return (
    <div className="basis-1/2">
      <DashboardHeading>Pending Claims</DashboardHeading>
      <Table
        headers={[
          { key: "sessionInfo.cig.displayName", name: "Session", sortable: true },
          { key: "createdAt", name: "Created at", sortable: true },
          { key: "", name: "" },
        ]}
        isLoading={isLoading}
        error={error}
        className="mt-0 -mx-2 sm:-mx-6 md:mx-0"
        {...tableSort}>
        {sortedPendingClaims.map(({ id, createdAt, sessionInfo }) => {
          const { cig, from } = sessionInfo ?? {};
          return (
            <TableRow key={id}>
              <TableCell>
                {cig?.displayName} - {dayjs(from).format("DD MMM YY | HH:mm")}
              </TableCell>
              <TableCell>{dayjs(createdAt).format("DD MMM YY HH:mm")}</TableCell>

              <TableCell>
                <ButtonRow className="justify-end">
                  <Button
                    bgColor="bg-violet-600"
                    bgHoverColor="hover:bg-violet-700"
                    to={`/claims/${id}`}>
                    View
                  </Button>
                </ButtonRow>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </div>
  );
}
