import { FormValidation } from "@lib/types/form";
import { CSSProps } from "@lib/types/generic";
import { classNames } from "@lib/utils/generic";
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactNode,
} from "react";

import { useState } from "react";
import { useLayoutEffect } from "react";

interface Props extends CSSProps {
  name?: string;
  label?: ReactNode;
  validation?: FormValidation;
  required?: boolean;
  basis?: string;
  noGrow?: boolean;

  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const FormEntry = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    validation,
    name,
    label,
    required,
    basis = "",
    noGrow,

    className = "",
    style,
    id,

    ...events
  } = props;

  const [loaded, setLoaded] = useState(false);

  const validationFailed = validation && validation.failed && validation.list.length > 0;

  useLayoutEffect(() => setLoaded(true), [setLoaded]);

  return (
    <div
      className={classNames("flex flex-col", noGrow ? "" : "flex-grow", basis, className)}
      id={id}
      style={style}
      ref={ref}
      {...events}>
      {label && (
        <label className="block text-sm font-medium text-gray-700" htmlFor={name}>
          {typeof label === "string" && required ? `${label}*` : label}
        </label>
      )}
      {loaded &&
        Children.map(
          children,
          child =>
            isValidElement(child) &&
            cloneElement(child as any, {
              error: validationFailed,
            }),
        )}
      {validation?.failed && (
        <div className="mt-2">
          {validation.list.map(({ text }, i) => (
            <p className="text-sm text-red-600" key={i}>
              {text}
            </p>
          ))}
        </div>
      )}
    </div>
  );
});

export default FormEntry;
