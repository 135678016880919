import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useUpdatePlatform from "@api/private/update/hooks/useUpdatePlatform";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { EditPlatformValues } from "../Platform";

const useEditPlatformHandle: UseFormHandle<EditPlatformValues> = (
  { validateFields, validateCustom, setValues, values },
  iconImagefile: File,
) => {
  const { send: updatePlatform, isLoading: updateIsLoading } = useUpdatePlatform(values);
  const { send: uploadToS3, isLoading: uploadToS3IsLoading } = useUploadToS3();

  const handle = async () => {
    let failed = validateFields(["name", "shortName", "icon"]);

    let newValues = { ...values };

    if (!failed) {
      const { data: iconUrl, error } = await uploadToS3(iconImagefile);

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (iconUrl) newValues.icon = iconUrl;

      setValues(newValues);
    }

    if (!failed) {
      const { error } = await updatePlatform(newValues);

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }
    }

    return failed;
  };

  return [handle, updateIsLoading || uploadToS3IsLoading];
};

export default useEditPlatformHandle;
