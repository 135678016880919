import { api } from "@api/api";

export interface SubCategories {
  [key: string]: string;
}

const getSubCategories = async () => {
  const { data } = await api.get<SubCategories>("/subCategories");
  return data;
};

export default getSubCategories;
