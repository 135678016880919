import useUploadToS3 from "@api/other/put/hooks/useUploadToS3";
import useCreateBanner from "@api/private/create/hooks/useCreateBanner";
import useGetBanners from "@api/public/get/hooks/useGetBanners";
import { UseFormHandle } from "@components/common/form/hooks/useFormManager";
import { useNavigate } from "react-router-dom";
import { CreateBannerValues } from "../CreateBanner";

const useCreateBannerHandle: UseFormHandle<CreateBannerValues> = (
  { validateFields, validateCustom, setValues, values },
  imageFile: File,
  imageXSFile: File,
) => {
  const navigate = useNavigate();

  const { send: createBanner, isLoading: updateIsLoading } = useCreateBanner(
    values.title,
    values.content,
    values.cigID,
  );
  const { send: uploadToS3, isLoading: uploadToS3IsLoading } = useUploadToS3();

  const { send: getBanners, isLoading: bannersIsLoading } = useGetBanners();

  const handle = async () => {
    let failed = validateFields(["cigID", "title", "content", "imageURL", "imageURLXS"]);

    let newValues = { ...values };

    if (!failed) {
      const { data: imageUrl, error: primaryError } = await uploadToS3(imageFile);
      const { data: imageXSUrl, error: secondaryError } = await uploadToS3(imageXSFile);

      const error = primaryError ?? secondaryError;

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (imageUrl) newValues.imageURL = imageUrl;
      if (imageXSUrl) newValues.imageURLXS = imageXSUrl;

      setValues(newValues);
    }

    if (!failed) {
      const { data: banners, error } = await getBanners();

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (banners) newValues.priority = banners.length;
    }

    if (!failed) {
      const { error, data } = await createBanner(
        newValues.imageURL,
        newValues.imageURLXS,
        newValues.priority,
      );

      if (error) {
        failed = validateCustom("generic", validation => {
          validation.list.push({
            type: "error",
            text: error,
          });
        });
      }

      if (!error) navigate(`/banners/${data?.id}`);
    }

    return failed;
  };

  return [handle, updateIsLoading || uploadToS3IsLoading || bannersIsLoading];
};

export default useCreateBannerHandle;
