import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";
import getSanityStreamer, { GetSanityStreamerRes } from "../getSanityStreamer";

const useGetSanityStreamer = (username?: string, autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } =
    useRequestState<GetSanityStreamerRes | null>(null);

  const send = useCallback(async () => {
    if (username == null) return reqState;

    setLoading();
    const { data, error } = await getSanityStreamer(username);

    if (data && data[0]) setSuccess(data[0]);
    if (error) setError(error);
  }, [username, reqState, setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetSanityStreamer;
