import { FormValidation } from "../types/form";
import { Validator } from "./Validator";
import createBannerValidators from "./validators/createBanner";
import createGameValidators from "./validators/createGame";
import createPlatformValidators from "./validators/createPlatform";
import editBannerValidators from "./validators/editBanner";
import editGameValidators from "./validators/editGame";
import editPlatformValidators from "./validators/editPlatform";
import globalValidators from "./validators/global";
import loginValidators from "./validators/login";
import updateCigProfileValidators from "./validators/updateCigProfile";
import verifyCigValidators from "./validators/verifyCig";
import editSeoStreamerValidators from "./validators/editSeoStreamer";

export const createEmptyValidation = (name: string): FormValidation => ({
  key: name,
  failed: false,
  list: [],
});

export function customValidate(key: string, validator: (validation: FormValidation) => void) {
  const validation = {
    key,
    list: [],
  } as FormValidation;

  validator(validation);

  validation.failed = validation.list.find(item => item.type === "error") != null;

  return validation;
}

export type ParentValidator = { [key: string]: ChildValidator };
export type ParentValidators = keyof typeof validators;

export type ChildValidator = (
  validation: FormValidation,
  value: any,
  values: any,
) => void | Validator;

export function validate(
  parentValidatorName: ParentValidators,
  childValidatorName: string,
  value: any,
  values: Record<any, any>,
) {
  const validation = {
    key: childValidatorName,
    list: [],
  } as FormValidation;

  let parentValidator = validators[parentValidatorName] as ParentValidator;
  let childValidator = parentValidator[childValidatorName];
  if (childValidator == null) {
    parentValidator = validators.global as ParentValidator;
    childValidator = parentValidator[childValidatorName];
  }
  if (childValidator == null) return validation;

  childValidator(validation, value, values);

  validation.failed = validation.list.find(item => item.type === "error") != null;

  return validation;
}

const validators = {
  global: globalValidators,
  login: loginValidators,
  editGame: editGameValidators,
  createGame: createGameValidators,
  verifyCig: verifyCigValidators,
  createPlatform: createPlatformValidators,
  editPlatform: editPlatformValidators,
  createBanner: createBannerValidators,
  editBanner: editBannerValidators,
  updateCigProfile: updateCigProfileValidators,
  editSeoStreamer: editSeoStreamerValidators,
};
