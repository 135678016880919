import { getMsgFromApiErr } from "@api/api";
import getPlatforms, { Platform } from "@api/public/get/getPlatforms";
import useRequestState from "@hooks/useRequestState";
import { useCallback, useEffect } from "react";

const useGetPlatforms = (autoSend?: boolean) => {
  const { reqState, setLoading, setSuccess, setError } = useRequestState<Platform[]>([]);

  const send = useCallback(async () => {
    setLoading();
    try {
      const platforms = await getPlatforms();
      setSuccess(platforms);
    } catch (err: any) {
      return setError(getMsgFromApiErr(err));
    }
  }, [setError, setLoading, setSuccess]);

  useEffect(() => {
    if (autoSend) send();
    // prevents infinite loop
    // eslint-disable-next-line
  }, [autoSend]);

  return { ...reqState, data: reqState.data, send };
};

export default useGetPlatforms;
