import Containers from "@components/common/containers/Containers";
import Page from "@components/layout/Page";
import React, { useEffect, useState } from "react";
import DashboardOverview from "./DasboardOverview";
import DashboardClaims from "./DashboardClaims";
import DashboardData, { CIGPieChartData } from "./DashboardData";
import useGetUnverifiedCigs from "@api/public/get/hooks/useGetUnverifiedCigs";
import useGetCigs from "@api/public/get/hooks/useGetCigs";
import useGetPendingCigs from "@api/public/get/hooks/useGetPendingCigs";
import { UserGroupIcon } from "@heroicons/react/outline";
import { SortDirection } from "@lib/enums/generic";
import useGetPendingClaims from "@api/private/get/hooks/useGetPendingClaims";

export default function Dashboard() {
  const { data: cigs, isLoading: cigsIsLoading, error: cigsError } = useGetCigs(true);
  const {
    data: unverifiedCigs,
    isLoading: unverifiedIsLoading,
    error: unverifiedError,
  } = useGetUnverifiedCigs(true);
  const {
    data: pendingCigs,
    isLoading: pendingCigsIsLoading,
    error: pendingCigsError,
  } = useGetPendingCigs(true);

  const {
    data: pendingClaims,
    isLoading: pendingClaimsIsLoading,
    error: pendingClaimsError,
  } = useGetPendingClaims(true);

  const cards = [
    {
      name: "Accepted CIGs",
      amount: cigs.length,
      to: "/cigs",
      icon: <UserGroupIcon className="text-green-400" />,
      isLoading: cigsIsLoading,
      error: cigsError,
    },
    {
      name: "Unaccepted CIGs",
      amount: pendingCigs.length,
      to: `/cigs/pending?sortKey=verified&sortDirection=${SortDirection.Descending}`,
      icon: <UserGroupIcon className="text-orange-400" />,
      isLoading: pendingCigsIsLoading,
      error: pendingCigsError,
    },
    {
      name: "Unverified CIGs",
      amount: unverifiedCigs.length,
      to: `/cigs/pending?sortKey=verified&sortDirection=${SortDirection.Ascending}`,
      icon: <UserGroupIcon className="text-red-400" />,
      isLoading: unverifiedIsLoading,
      error: unverifiedError,
    },
  ];

  const [data, setData] = useState<CIGPieChartData[]>([]);

  useEffect(() => {
    if (cigsIsLoading || pendingCigsIsLoading || unverifiedIsLoading) return;
    setData([
      {
        name: "Accepted CIGs",
        value: cigs.length,
        color: "rgb(34 197 94)",
      },
      {
        name: "Unaccepted CIGs",
        value: pendingCigs.length,
        color: "rgb(249 115 22)",
      },
      {
        name: "Unverified CIGs",
        value: unverifiedCigs.length,
        color: "rgb(239 68 68)",
      },
    ]);
  }, [
    cigs.length,
    pendingCigs.length,
    unverifiedCigs.length,
    cigsIsLoading,
    pendingCigsIsLoading,
    unverifiedIsLoading,
  ]);

  return (
    <Page>
      <DashboardOverview cards={cards} />
      <Containers className="mt-6 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <DashboardClaims
          pendingClaims={pendingClaims}
          isLoading={pendingClaimsIsLoading}
          error={pendingClaimsError}
        />
        <DashboardData
          data={data}
          isLoading={cigsIsLoading || pendingCigsIsLoading || unverifiedIsLoading}
          error={cigsError || pendingCigsError || unverifiedError}
        />
      </Containers>
    </Page>
  );
}
