import config from "@config/config";
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { Image } from "@sanity/types";

export const sanity = sanityClient({
  projectId: config.sanityProjectId,
  dataset: config.sanityDataset,
  useCdn: true,
  apiVersion: "2022-12-14",
});

export const sanityCall = async <R>(promise: Promise<R>) => {
  let data: R | undefined = undefined;
  let error: string | undefined = undefined;

  try {
    data = await promise;
  } catch (err: any) {
    error = `${err}`;
  }

  return { data, error };
};

const builder = imageUrlBuilder(sanity);

export const urlFor = (src: Image) => builder.image(src);
