import { api } from "@api/api";
import { StreamerCorpus } from "@lib/types/generic";

export type UpdateStreanerCorpus = StreamerCorpus;

const updateStreamerCorpus = async (streamerCorpus: UpdateStreanerCorpus) => {
  const { data } = await api.post<StreamerCorpus>("/updateOrSaveCorpusForTalent", streamerCorpus);
  return data;
};

export default updateStreamerCorpus;
