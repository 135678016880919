import { CIG, CountryCode, Currency, VerificationPlatform } from "@lib/enums/generic";
import { api } from "@api/api";

export interface CIGSubscription {
  currency: Currency;
  discount: number;
  id: string;
  price: number;
}

export interface CIGProfile {
  id: string;

  bannerPictureURL: string;
  category: CIG;
  country: CountryCode;
  defaultCurrency: Currency;
  displayName: string;
  profilePictureURL: string;
  shortDescription: string;
  subCategory: string;
  subscription: CIGSubscription | null;
  tagLine: string | null;

  instagramHandle: string | null;
  tiktokHandle: string | null;
  twitchHandle: string | null;
  twitterHandle: string | null;
  youtubeHandle: string | null;

  subscriber: boolean;

  accepted: boolean;
  verified: boolean;

  verifiedHandle: string;
  verifiedPlatformName: VerificationPlatform;
}

const getCigProfile = async (cigID: string, userID?: string) => {
  const { data } = await api.get<CIGProfile>("/cig", { params: { cigID, userID } });
  return data;
};

export default getCigProfile;
