import useDeletePlatform from "@api/private/delete/hooks/useDeletePlatform";
import DeleteModal from "@components/common/modals/DeleteModal";
import useGlobalContext from "@hooks/useGlobalContext";
import { createSuccessSnackbar } from "@lib/constants/generic";
import React from "react";

interface Props {
  platformId?: string;
  onClose?: () => void;
  onDelete?: () => void;
}

export default function DeletePlatformModal(props: Props) {
  const { platformId, onClose = () => {}, onDelete = () => {} } = props;

  const { pushSnackbar } = useGlobalContext();

  const { send: deletePlatform, isLoading, error } = useDeletePlatform(platformId);

  const handleDelete = async () => {
    const { error } = await deletePlatform();
    if (error) return;
    onDelete();
    onClose();
    pushSnackbar(createSuccessSnackbar("Platform Deleted", undefined, 3000));
  };

  return (
    <DeleteModal
      active={!!platformId}
      isLoading={isLoading}
      error={error}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={handleDelete}
    />
  );
}
